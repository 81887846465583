#member {
    width: 580px;
    padding: 20px 70px;
}

#member .top {
    margin: 0 0 20px;
    background: #ffffff;
    padding: 0;
}

#member .top div {
    width: 29px;
    height: 29px;
    border-radius: 100%;
    border: 1px solid #cccccc;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

#member .top div:hover {
    background: #cccccc;
}

#member .logo {
    width: 310px;
    padding: 15px 0 40px;
}

#member .logo img {
    width: 100%;
}

#member .check {
    padding: 0 0 56px;
    border-bottom: solid 1px #dfdce3;
}

#member .check .checkbox p {
    margin: 0 0 -40px;
}

#member .check .checkbox .text {
    color: #87858b;
    font-size: 13px;
}

#member>div {
    margin: 0 0 16px;
}

#member>div p {
    font-size: 13px;
    color: #87858b;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

#member>div p:hover {
    color: #ec15ff;
}

#member>div.kakao {
    padding: 0 0 0 24px;
}

#member #purpleBtn {
    width: 100%;
    height: 58px;
    font-size: 18px;
    border-radius: 3px;
    margin: 200px 0 0;
}

@media (max-width : 700px) {
    #member {
        width: 100%;
        padding: 20px;
    }

}