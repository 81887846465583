/*================================
문의 게시글
================================*/
#inquiryDetail {
  width: 100%;
  padding: 15px 20px;
  border-radius: 16px;
  background-color: #fff;
}
/* 타이틀 */
#inquiryDetail .titleBox {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0;
}
#inquiryDetail .titleBox .cate {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.75px;
  color: #939ca7;
}
#inquiryDetail .titleBox .title {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -2px;
  color: #0e0e1f;
}
#inquiryDetail .titleBox .date {
  flex-grow: 0;
  font-size: 14px;
  color: #0e0e1f;
}

/* 프로필 */
#inquiryDetail .userBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 8px;
  padding: 20px 0;
}
#inquiryDetail .userBox .userIcon {
  align-self: flex-start;
  overflow: hidden;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
#inquiryDetail .userBox .userIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#inquiryDetail .userBox .userIcon p {
  font-size: 40px;
  color: #ffffff;
}
#inquiryDetail .userBox .userInfo {
  flex: 1;
}
#inquiryDetail .userBox .userInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#inquiryDetail .userBox .userInfo .userInfo-top {
  display: flex;
  align-items: center;
}
#inquiryDetail .userBox .userInfo .userInfo-top .badge {
  max-width: 50px;
}
#inquiryDetail .userBox .userInfo .userInfo-top .name {
  font-size: 24px;
  font-weight: 500;
  color: #0e0e1f;
}
#inquiryDetail .userBox .userInfo .userInfo-center {
  display: flex;
  justify-content: space-between;
}
#inquiryDetail .userBox .userInfo .userInfo-center .aiArea {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  color: #8790a3;
}
#inquiryDetail .userBox .userInfo .userInfo-center .aiArea .majorModel {
  padding: 0;
}
#inquiryDetail .userBox .userInfo .userInfo-bottom {
  position: relative;
  display: flex;
}
#inquiryDetail .userBox .userInfo .userInfo-bottom .ranking {
  display: flex;
}
#inquiryDetail .userBox .userInfo .userInfo-bottom .profileStar {
  display: flex;
}
#inquiryDetail .userBox .userInfo .userInfo-bottom .profileStar img {
  width: 16px;
  vertical-align: middle;
  object-fit: contain;
}
#inquiryDetail .userBox .userInfo .userInfo-bottom .profileStar .emptyStar {
  filter: grayscale(1);
}
#inquiryDetail .userBox .userInfo .userInfo-bottom .profileToggle {
  position: absolute;
  top: 100%;
  z-index: 10;
  width: 100px;
  padding: 2px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #c718e2;
  background: #ffffff;
}
#inquiryDetail .userBox .userInfo .userInfo-bottom .profileToggle li {
  gap: 5px;
  padding: 5px 8px;
}
#inquiryDetail .userBox .aiArea {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
#inquiryDetail .userBox .aiArea .majorModel {
  width: auto;
  padding: 0;
}

/* 첨부파일 */
#inquiryDetail .fileBox {
  padding: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
#inquiryDetail .fileBox p {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 12px;
}
#inquiryDetail .fileBox li {
  font-size: 14px;
}

/* 댓글 */
.answerBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 48px;
  border-radius: 70px;
  background-color: rgba(62, 61, 83, 0.1);
  color: #0e0e1f;
}
.answerBox .info .name {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
  text-decoration: underline;
}
.answerBox .info .date {
  padding: 12px 0;
  font-size: 12px;
  color: #6d6c75;
  text-decoration: none;
}
.answerBox .cont {
  font-size: 14px;
}

/* 버튼 */
#inquiryDetail .buttonBox {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.mypageSection .btnArea button {
  display: flex;
}
/* 수정 예정 영역 */
#inquiryDetail .answerCreate .create p {
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
  margin: 0 0 12px;
}
.answerCreate .create div #greyBtn {
  width: 73px;
  height: 120px;
  font-size: 14px;
  border-radius: 4px;
}
.answerCreate .create div .input::placeholder {
  color: #a3a1a7;
}
.answerCreate .create div .input {
  width: calc(100% - 73px - 12px);
  height: 120px;
  border-radius: 4px;
  background: #f4f5f7;
  padding: 16px 12px;
  background: #f4f5f7;
  resize: none;
  border: none;
  box-shadow: none;
}
.answerCreate .create div .input:focus {
  border: 1px solid #b041f3;
  background: #ffffff;
}

/* 반응형*/
@media (max-width: 700px) {
  #inquiryDetail .titleBox {
    gap: 10px;
  }
  #inquiryDetail .titleBox .cate {
    font-size: 14px;
  }
  #inquiryDetail .titleBox .title {
    font-size: 23px;
    line-height: 1.5;
  }
  #inquiryDetail .titleBox .date {
    font-size: 12px;
  }
  /* 프로필 */
  #inquiryDetail .userBox {
    padding-top: 10px;
    column-gap: 10px;
    row-gap: 4px;
  }
  #inquiryDetail .userBox .userIcon {
    width: 40px;
    height: 40px;
  }
  #inquiryDetail .userBox .userInfo {
    gap: 0;
  }
  #inquiryDetail .userBox .userInfo .userInfo-top .badge {
    margin-right: 5px;
    font-size: 10px;
  }
  #inquiryDetail .userBox .userInfo .userInfo-top .name {
    font-size: 14px;
  }
  #inquiryDetail .userBox .userInfo .userInfo-center p {
    color: #8790a3;
  }
  #inquiryDetail .userBox .userInfo .userInfo-bottom .ranking {
    font-size: 12px;
  }
  /* 댓글 */
  .answerBox {
    gap: 10px;
    padding: 20px;
  }
  .answerBox .info {
    display: flex;
    justify-content: space-between;
  }
  .answerBox .info .date {
    padding: 0;
  }
}

/*================================
문의작성 탭
================================*/
/* 레이아웃 */
.l-form {
  display: flex;
  gap: 24px;
}
.l-form__title {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 180px;
  font-size: 16px;
  font-weight: 700;
  color: #3e3d53;
  text-wrap-mode: nowrap;
}
.l-form__title img {
  vertical-align: text-top;
}
.l-form__list .ant-form-item .ant-form-item-label {
  line-height: 1;
}
.l-form__list {
  flex: 1;
  gap: 30px;
  display: flex;
  flex-direction: column;
}
.l-form__list li label {
  font-size: 12px !important;
  color: #464548 !important;
}
.l-form__list li .ant-select {
  max-width: 380px;
}
.l-form__list .ant-upload-drag-container {
  min-height: 100px;
}
.l-form__list .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
/* 개인정보 수집 관련 */
.privacy {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin: 30px 0;
}
.privacy h3,
.privacy a {
  font-size: 24px;
  font-weight: 500;
  color: #3e3d53;
}
.privacy a {
  padding-left: 5px;
}
.privacy a span {
  text-decoration: underline;
}
.privacy ~ div button:disabled {
  background: #dfdce4;
  color: #a29fab;
}
.mypageSection .l-form .plusBannerBox {
  display: flex;
  width: 100%;
  height: 120px;
}
.privacy h3,
.privacy a {
  font-size: 20px;
}
.mypageSection .ant-form-item {
  margin-bottom: 0;
  width: 100%;
}
.mypageSection .btnArea {
  margin-bottom: 20px;
}
.mypageSection .l-form .plusBannerBox {
  min-height: 0;
}
/* design.css 영향받는 부분 안보이게 처리 */
.mypageSection .ant-divider-horizontal.ant-divider-with-text::before {
  display: none;
}
/* 반응형 */
@media (max-width: 700px) {
  .l-form {
    flex-direction: column;
    width: 100%;
  }
  .l-form__title {
    gap: 8px;
    /* padding-bottom: 30px; */
  }
  .l-form__list li .ant-select {
    max-width: 100%;
  }
  .l-form__list .ant-upload-drag-container p {
    display: none;
  }
  .privacy {
    margin: 30px 0;
  }
  .privacy h3,
  .privacy a {
    font-size: 16px;
  }
  /* .ant-divider-horizontal {
    margin: 0;
  } */
  .btnArea button {
    display: flex;
    width: 100%;
    gap: 12px;
  }
}
/*================================
문의내역 탭
================================*/
.answer-none {
  display: inline-block;
  padding: 4px 9px;
  border: 1px solid #525162;
  border-radius: 50px;
  color: #525162;
  font-size: 11px;
  font-weight: 600;
}
.answer-done {
  display: inline-block;
  padding: 4px 9px;
  border: 1px solid #0fc9f3;
  border-radius: 50px;
  color: #0d88d4;
  font-size: 11px;
  font-weight: 600;
}
/* AuiGrid  */
.mypageSection .aui-grid .aui-grid-header-renderer {
  font-weight: 500;
  color: #323146;
}
.mypageSection .aui-grid-link-renderer {
  text-align: left;
}
.mypageSection .aui-grid-link-renderer > a {
  text-decoration: none;
  font-size: 16px;
  color: #464548;
}
.mypageSection .aui-grid-default-column .date {
  font-size: 13px;
}
.mypageSection .aui-grid-default-column:has(.title) {
  text-align: left;
}
.mypageSection .aui-grid-default-column:has(.title) span {
  font-size: 16px;
}
/* 반응형 */
@media (max-width: 700px) {
  .answer-none,
  .answer-done {
    height: 21px;
    font-size: 11px;
  }
  /* Ant Custom */
  .mypageSection .ant-layout-header {
    flex-wrap: wrap;
    gap: 8px;
  }
  .mypageSection .flexGap .ant-select {
    width: 80px !important;
    font-size: 12px;
  }
  .mypageSection .flexGap .ant-select .ant-select-selector {
    padding: 4px 4px 4px 7px;
  }
  .mypageSection .flexGap .ant-select .ant-select-arrow {
    margin-top: -12px;
    width: 20px;
    height: 20px;

  }
  .mypageSection .ant-layout-header > div:nth-of-type(1) {
    order: 2;
    width: 100%;
  }
  .mypageSection .ant-layout-header > div:nth-of-type(1) .ant-picker {
    flex: 1;
  }
  .mypageSection .ant-layout-header > div:nth-of-type(2) {
    order: 1;
    width: 100%;
    margin-left: 0 !important;
  }
  .mypageSection .ant-picker-input > input,
  .mypageSection .ant-select-selector,
  body:has(.mypageSection) :where(.css-dev-only-do-not-override-5wsri9).ant-select-dropdown .ant-select-item {
    font-size: 12px;
  }
  body:has(.mypageSection) :where(.css-dev-only-do-not-override-5wsri9).ant-picker-dropdown {
    font-size: 12px;
  }
  body:has(.mypageSection) :where(.css-dev-only-do-not-override-5wsri9).ant-picker-dropdown .ant-picker-cell {
    padding: 3px 0;
  }
  body:has(.mypageSection) :where(.css-dev-only-do-not-override-5wsri9).ant-picker-dropdown .ant-picker-content th {
    height: 20px;
  }
  body:has(.mypageSection) :where(.css-dev-only-do-not-override-5wsri9).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
  body:has(.mypageSection) :where(.css-dev-only-do-not-override-5wsri9).ant-picker-dropdown .ant-picker-date-panel {
    width: 250px;
  }
  body:has(.mypageSection) :where(.css-dev-only-do-not-override-5wsri9).ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
    padding: 10px;
  }

  /* AuiGrid */
  .mypageSection .aui-grid .aui-grid-header-renderer {
    font-size: 13px;
  }
  .mypageSection .aui-grid-link-renderer > a {
    font-size: 14px;
  }
  .mypageSection .aui-grid-default-column .date {
    font-size: 10px;
  }
  .mypageSection .aui-grid-default-column:has(.title) span {
    font-size: 14px;
  }
}
