/* 메시지 전체 레이아웃 */
#myMessage {
  padding: 0 0 50px;
}
#myMessage > div {
  border-bottom: solid 2px #000;
}
#myMessage > div > .title {
  padding: 40px 0 48px;
  overflow: auto;
}
/* #myMessage > div > .title .overflowBox {
  min-width: 800px;
}
#myMessage > div > .title .overflowBox > div {
  margin: 0 30px;
  width: auto;
  position: relative;
}
#myMessage > div > .title .overflowBox div h1 {
  font-size: 32px;
  font-weight: bold;
  color: #cccccc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#myMessage > div > .title .overflowBox div h1:hover,
#myMessage > div > .title .overflowBox div#active h1 {
  color: #0e0e1f;
}
#myMessage > div > .title .overflowBox div .round {
  display: none;
}
#myMessage > div > .title .overflowBox div#active .round {
  display: flex;
  position: absolute;
  top: -6px;
  left: -10px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #fe5139;
} */

/* 탭 하단 레이아웃 */
#message {
  height: 80vh;
  padding: 25px 0 40px;
}
#message .rsWrapper {
  height: 100%;
}

/* 메시지 리스트 영역 */
#message .rsWrapper .list {
  max-width: 400px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #f3f3f3;
  overflow: hidden;
  height: 100%;
  justify-content: flex-start;
  overflow-y: auto;
}
#message .rsWrapper .list .top {
  min-height: 52px;
  padding: 8px 12px 8px 16px;
  border-bottom: solid 1px #dfdce4;
  background-color: #fff;
  font-size: 14px;
}
#message .rsWrapper .list .top .logoutBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  border: 1px solid #e2dfe4;
  border-radius: 4px;
  transition: 0.3s;
}
#message .rsWrapper .list .top .logoutBtn img {
  width: 18px;
}
#message .rsWrapper .list .top .logoutBtn:hover {
  background-color: rgb(226, 223, 228, 0.3);
}
#message .rsWrapper .list .box#active {
  background: #ffffff;
}
#message .rsWrapper .list .box#new {
  background: #fdf2ff;
}
#message .rsWrapper .list .box {
  padding: 8px 12px 8px 16px;
  border-bottom: solid 1px #eae8ec;
  gap: 16px;
  height: 67px;
}
#message .rsWrapper .list .box > div {
  /* padding: 8px 12px 8px 16px; */
  border-bottom: solid 1px #eae8ec;
  gap: 16px;
  height: 67px;
}
#message .rsWrapper .list .box:has(.ant-checkbox-wrapper-checked) {
  background-color: #fdf2ff;
}

#message .rsWrapper .list .box:has(.noResult) {
  border-bottom: none;
  height: 100%;
}
#message .rsWrapper .list .box .noResult {
  border: none;
  background: none;
  width: 100% !important;
  height: 100%;
}
#message .rsWrapper .list .box > div:first-child {
  width: auto;
  position: relative;
  height: 50px;
}
#message .rsWrapper .list .box > div:first-child img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.3);
}
#message .rsWrapper .center .message .reciver > div > div > .round,
#message .rsWrapper .list .box > div:first-child .round {
  border: 2px solid #ffffff;
  background: #e94cf7;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}
#message .rsWrapper .list .box .more {
  width: 12px;
  position: relative;
  margin: 0;
  padding-bottom: 8px;
}
#message .rsWrapper .list .box .more svg {
  width: 24px;
  height: 24px;
}
#message .rsWrapper .list .box .check {
  gap: 16px;
  margin: 0;
  border: 0;
}
#message .rsWrapper .list .box .messageD {
  width: calc(100% - 180px);
  gap: 4px;
  cursor: pointer;
}
#message .rsWrapper .list .box .info {
  /* width: 168px; */
  gap: 8px;
  align-items: end;
  justify-content: end;
  padding-block-end: 12px;
}
#message .rsWrapper .list .box .info:has(.more) {
  flex-direction: row;
}
#message .rsWrapper .list .box > div:first-child {
  gap: 10px;
}
#message .rsWrapper .list .box > div > h1 {
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
  line-height: 1.5;
  text-wrap-mode: nowrap;
}
#message .rsWrapper .list .box > div > .count {
  width: 26px;
  height: auto;
  background: #f34144;
  border-radius: 100px;
  padding: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}
#message .rsWrapper .list .box > div > h2 {
  color: #656262;
  font-size: 12px;
  font-weight: 400;
  text-wrap-mode: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
#message .rsWrapper .list .box .info > p {
  font-size: 11px;
  color: #a3a1a7;
  font-weight: 500;
  width: 68px;
  text-align: right;
}
/* #message .rsWrapper .list .box .more p {
  font-size: 18px;
  color: #686275;
} */
#message .rsWrapper .list .box .more .absoluteBox {
  position: absolute;
  top: 20px;
  right: 0;
  border: 1px solid #c718e2;
  background: #ffffff;
  padding: 4px;
  width: 100px;
  border-radius: 4px;
  z-index: 10;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
#message .rsWrapper .list .box .more .absoluteBox > div:hover {
  background: #f7f7f8;
}
#message .rsWrapper .list .box .more .absoluteBox > div {
  font-size: 13px;
  border-radius: 4px;
  height: 32px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

/* 가운데 상세 메시지 영역 */
#message .rsWrapper .center {
  flex: 1;
  justify-content: flex-start;
  height: 100%;
  padding: 0 25px;
}
#message .rsWrapper .center .title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 76px;
  gap: 4px;
}
#message .rsWrapper .center .title .vgBox {
  display: flex;
  align-items: center;
  padding-top: 4px;
}
s #message .rsWrapper .btn-back-mes {
  display: none;
  /* margin-bottom: 8px; */
}
#message .rsWrapper .center .title img {
  width: 10px;
  margin: 0 20px 0 0;
}
#message .rsWrapper .center .title h1 {
  font-size: 24px;
  font-weight: 700;
}
#message .rsWrapper .center .message {
  height: calc(100% - 76px - 50px - 24px);
  justify-content: flex-start;
}
#message .rsWrapper .center .message .sender,
#message .rsWrapper .center .message .reciver {
  margin: 0 0 12px;
}
#message .rsWrapper .center .message .reciver > div > div {
  position: relative;
  width: auto;
  margin: 0 8px 0 0;
}
#message .rsWrapper .center .message .sender > div,
#message .rsWrapper .center .message .reciver > div {
  align-items: flex-end;
  margin: 0 0 8px;
}
#message .rsWrapper .center .message .reciver > div > div > img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
#message .rsWrapper .center .message .sender > div,
#message .rsWrapper .center .message .reciver > div > div:last-child {
  max-width: 280px;
  width: auto;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  padding: 8px 16px;
  background: #f7f7f8;
  border-radius: 24px 24px 24px 0;
  line-height: 1.4;
}
#message .rsWrapper .center .message .sender > div {
  border-radius: 24px 24px 0 24px;
  font-weight: 300;
}
#message .rsWrapper .center .message .sender > p,
#message .rsWrapper .center .message .reciver > p {
  font-size: 11px;
  color: #a3a1a7;
  font-weight: 600;
  letter-spacing: -0.33px;
}
#message .rsWrapper .center .message .sender > div {
  background: #403748;
  color: #ffffff;
}
#message .rsWrapper .center .message .sender > div > img {
  width: 58px;
  border-radius: 4px;
}
#message .rsWrapper .center .inputBox {
  margin: 24px 0 0;
  height: 50px;
  background: #f7f7f8;
  border-radius: 24px;
  padding: 8px 12px;
  margin-top: 12px;
}
#message .rsWrapper .center .inputBox #pinkBtn.upload {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
}
#message .rsWrapper .center .inputBox #pinkBtn.upload img {
  width: 18px;
}
#message .rsWrapper .center .inputBox .send {
  display: flex;
  height: 34px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: var(--i-default, #ccc);
}
#message .rsWrapper .center .inputBox .send:hover {
  background: #eb5ef8;
  color: #fff;
}
#message .rsWrapper .center .inputBox .ant-input-outlined:focus + .send {
  background: #eb5ef8;
  color: #fff;
}
#message .rsWrapper .center .inputBox input {
  width: calc(100% - 32px - 61px);
  padding: 0 10px;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  box-shadow: none;
  font-size: 14px;
  color: #000000;
}
#message .rsWrapper .center .notice {
  display: flex;
  flex-direction: column;
}
#message .rsWrapper .center .notice .date {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #a3a1a7;
}
#message .rsWrapper .center .notice .box {
  display: flex;
  gap: 8px;
  width: 100%;
}
#message .rsWrapper .center .notice .box .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #13d5df 0%, #f138fc 100%);
}
#message .rsWrapper .center .notice .box .icon img {
  width: 24px;
  height: 24px;
}
#message .rsWrapper .center .notice .box .content {
  flex: 1;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
}
.fiueiR {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
}
/* 메시지 프로필 영역 box & info */
#message .rsWrapper .profile {
  width: 320px;
  height: 100%;
  background: linear-gradient(to bottom, #d6ecf6, #e9e4f6);
  padding: 15px;
  border-radius: 0 8px 8px 0;
  position: relative;
  justify-content: flex-start;
  display: flex;
}
#message .rsWrapper .profile .title {
  display: none;
}
#message .rsWrapper .profile .box {
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10;
}
#message .rsWrapper .profile .box .profileBox {
  gap: 8px;
}
#message .rsWrapper .profile .box .profileBox > img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: solid 1px rgba(0, 0, 0, 0.05);
  margin: 0 0 8px;
}
#message .rsWrapper .profile .box .profileBox > h1 {
  font-size: 14px;
  color: #0e0e1f;
}
#message .rsWrapper .profile .box .profileBox > h2 {
  font-size: 11px;
  color: #6d6c75;
}
#message .rsWrapper .profile .box .profileBox > div:has(.badge_s) img {
  width: 13px;
  height: 13px;
  margin-left: 4px;
}
#message .rsWrapper .profile .box .followBox {
  position: relative;
  padding: 20px 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
#message .rsWrapper .profile .box .followBox .btn-follow {
  margin-left: 16px;
  height: 36px;
  text-wrap-mode: nowrap;
}
/* 팔로워확인모달 */
#message .followBox .modal-overlay {
  position: absolute;
  top: 100%;
  right: 50%;
  transform: translateX(50%);
  z-index: 999;
}
#message .followBox .modal-content {
  width: 280px;
  background: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: #000;
}
#message .followBox .modal-content .title {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}
#message .followBox .modal-content .title img {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: absolute;
  right: 0;
  margin: 0;
}
#message .followBox .modal-content .title h2 {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 0 0px;
}
#message .followBox .modal-content .data {
  max-height: 320px;
  overflow-y: auto;
}
#message .rsWrapper .profile .box .followBox > div {
  /* flex: 1; */
  padding: 0 12px;
}
#message .rsWrapper .profile .box .followBox .btn-follow {
  margin-left: 0;
}
#message .rsWrapper .profile .box .followBox > div h1 {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
  line-height: 1.4;
  cursor: pointer;
}
#message .rsWrapper .profile .box .followBox > div p {
  font-size: 12px;
  color: #0e0e1f;
  font-weight: 500;
  text-align: left;
}
#message .rsWrapper .profile .box .followBox .aui-grid-body-panel .aui-grid-default-column:nth-child(3) .aui-grid-renderer-base {
  text-align: left;
}
#message .rsWrapper .profile .box .infoBox {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding-top: 12px;
}
#message .rsWrapper .profile .box .infoBox .badgeArea {
  display: flex;
  gap: 4px;
}
#message .rsWrapper .profile .box .infoBox .snsArea button {
  width: 16px;
  height: 16px;
}
#message .rsWrapper .profile .box .infoBox .rankingArea {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}
#message .rsWrapper .profile .box .infoBox .rankingArea > img:first-child {
  width: 14px;
  height: 14px;
}
#message .rsWrapper .profile .box .infoBox .rankingArea .absoluteBox {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 10;
  min-width: 100px;
  padding: 5px;
  border-radius: 4px;
  border: solid 1px #c718e2;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  gap: 2px;
}
#message .rsWrapper .profile .box .infoBox .rankingArea .absoluteBox img {
  width: 18px;
}
#message .rsWrapper .profile .box .infoBox .rankingArea .absoluteBox p {
  width: 100%;
}
#message .rsWrapper .profile .box .infoBox .rankingArea .absoluteBox li {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 2px;
  color: #000;
  padding: 8px;
  gap: 8px;
}
#message .rsWrapper .profile .box .infoBox .aiArea {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#message .rsWrapper .profile .box .infoBox .aiArea p {
  font-size: 12px;
  color: #8790a3;
  font-weight: 600;
}
#message .rsWrapper .profile .box .infoBox .aiArea > div {
  gap: 5px;
  padding: 0;
}
#message .rsWrapper .profile .box .infoBox .major {
  flex-wrap: nowrap;
}
#message .rsWrapper .profile .box .infoBox .rateArea {
  display: flex;
  align-items: center;
  gap: 8px;
}
#message .rsWrapper .profile .box .infoBox .rateArea h2 {
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
}
/* ant 별점 컨트롤 */
#message .rsWrapper .profile .box .infoBox .rateArea ul {
  display: flex;
}
#message .rsWrapper .profile .box .infoBox .rateArea ul li {
  margin: 0;
}
#message .rsWrapper .profile .box .infoBox .rateArea ul li svg {
  width: 14px;
}
#message .rsWrapper .profile .box .infoBox .rateArea p {
  font-size: 14px;
  color: #87858b;
}
#message .rsWrapper .profile .info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 0 8px;
}
#message .rsWrapper .profile .info h3 {
  font-size: 14px;
  font-weight: 500;
  color: #0e0e1f;
}
#message .rsWrapper .profile .info h4 {
  font-size: 13px;
  color: #6d6c75;
  font-weight: 400;
}

@media (max-width: 700px) {
  /* 메시지 레이아웃 */

  #message {
    padding: 0;
  }
  #message .rsWrapper {
    padding: 0;
  }
  #message .rsWrapper .list .box > div > h1 {
    font-weight: 500;
  }
  /* 메시지 리스트 영역 */
  #message .rsWrapper .list .top {
    padding: 8px 24px;
  }
  #message .rsWrapper .list {
    max-width: none;
  }
  #message .rsWrapper .list .box {
    padding: 8px 24px 8px 24px;
  }
  #message .rsWrapper .list .box .info {
    width: 214px;
  }
  /* 가운데 상세 메시지 영역 */
  #message .rsWrapper .center {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100vw !important;
    padding: 0 15px;
    background-color: #fff;
  }
  #message .rsWrapper .btn-back-mes {
    display: block;
    width: 10px;
    height: 18px;
    margin-right: 12px;
    background: url(/src/assets/images/chat/black_arrow_left.png) no-repeat center;
    background-size: contain;
  }
  #message .rsWrapper .center .svgBox {
    display: none;
  }
  #message .rsWrapper .center .inputBox {
    margin-top: 12px;
  }

  /* 대화 클릭시 노출되는 프로필 */
  #message .rsWrapper .profile {
    display: none;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  #message .rsWrapper .profile.active {
    display: flex;
  }
  #message .rsWrapper .profile .title {
    display: flex;
    align-items: center;
    height: 44px;
    color: #0e0e1f;
  }
  #message .rsWrapper .profile .title h1 {
    font-size: 24px;
    font-weight: 700;
    color: #0e0e1f;
  }
  #message .rsWrapper .profile .box .followBox > div {
    /* flex: 1; */
    padding: 0 12px;
    width: auto;
    min-width: 92px;
  }
  /* 팔로워 확인 모달 */
  #message .followBox .modal-overlay {
    position: fixed;
    top: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
  }

  #message .followBox .modal-content {
    width: 90%;
  }
  #message .followBox .modal-content .data {
    max-height: 90%;
  }
  #message .rsWrapper .list {
    width: 100%;
    border: none;
    background: #ffffff;
    /* border-top: 1px solid #f3f3f3; */
  }

  .chatSection #myMessage .title {
    /* border: 0 !important; */
  }
}
