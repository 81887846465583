.profileBackArea {
  height: 320px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  padding: 0 150px;
  background-color: #fff;
  color: white;

  background-image: var(--background-image);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.profileArea {
  width: 400px;
  height: 260px;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  position: relative;
  font-family: 'Pretendard, sans-serif';
}

.profileImage {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 110px;
  height: 110px;
  border-radius: 20%;
  object-fit: cover;
  margin-bottom: 8px;
  background-color: white;
}

.proBadge {
  position: absolute;
  top: 15px;
  left: 145px;
  display: inline-block;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #ff69b4;
  border-radius: 12px;
}

.socialIcons {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  gap: 10px;
}

.socialIcons .icon {
  font-size: 18px;
}

.nickName {
  position: absolute;
  left: 145px;
  margin-top: 28px;
  font-size: 28px;
  font-weight: bold;
}

.joinDate {
  position: absolute;
  left: 145px;
  font-size: 12px;
  margin-top: 60px;
  margin-bottom: 15px;
}

.profileInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 120px;
}

.statsColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  width: 50%;
  font-size: 16px;
  box-sizing: border-box;
  color: black;
}

.statItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.statIcon {
  font-size: 22px;
}

.scoreSummary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  color: white;
}

.followerCount {
  margin-bottom: 10px;
}

.scoreContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.scoreText {
  font-size: 12px;
  color: gray;
}

.filterBox {
  height: 76px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 20px 190px;
  border-top: solid 2px #000;
  background-image: linear-gradient(to top, #f3f3f3, #eee), linear-gradient(to bottom, #f3f3f3, #f3f3f3);
}

/* span {
    width: 28px;
    height: 19px;
    flex-grow: 0;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    text-align: left;
    color: #0e0e1f;
} */
.filterVector {
  width: 9px;
  height: 10px;
}

.type {
  height: 36px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  padding: 0;
}

.filterBox .type ul {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 10px;
}

.filterBox .type ul li {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-right: 35px;
  height: 17px;
  flex-grow: 0;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #000;
}

.filterBox .type ul li input {
  width: 20px;
  height: 20px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: 0;
  border-radius: 50px;
  border-style: solid;
  border-width: 6px;
  border-image-source: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.thumBox_Best {
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  align-items: center;
  gap: 28px;
  padding: 0 48px;
}

.password-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.password-dots .dot {
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50%;
}

#myHome #pinkBtn:first-child {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

#myHome #pinkBtn:last-child {
  width: 81px;
  height: 28px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 700;
}
#myHome #pinkBtn:first-child img {
  width: 18px;
}


#myHome #whiteBtn {
  width: 85px;
  height: 28px;
  border-radius: 50px;
  border: 1px solid #3e3d53;
  color: #3e3d53;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#myHome #whiteBtn div {
  width: auto;
  position: relative;
}

#myHome #whiteBtn div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}

#whiteBtn:hover img.activeImg {
  opacity: 1;
}

#whiteBtn:hover {
  background: #3e3d53;
  color: #ffffff;
}
