#boardDetail {
  padding: 40px 0;
  margin: auto;
}
.content-container.view p {
  font-size: 16px;
}
#boardDetail .ant-form-item {
  margin: 0;
}

/* 타이틀 */
#boardDetail .titleBox {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0;
}
#boardDetail .titleBox .cate {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.75px;
  color: #939ca7;
}
#boardDetail .titleBox .titleArea {
  display: flex;
  justify-content: space-between;
}
#boardDetail .titleBox .titleArea h2 {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -2px;
  color: #0e0e1f;
}
#boardDetail .titleBox .titleArea .name {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  color: #0e0e1f;
}
#boardDetail .titleBox .titleArea .name .view b {
  font-weight: 500;
  padding-left: 4px;
}
#boardDetail .titleBox .titleArea .name .like-button-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding: 0;
}
#boardDetail .titleBox .titleArea .name .like-button-container button {
  width: auto;
}
#boardDetail .titleBox .infoArea {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  color: #0e0e1f;
}

/* 프로필 */
#boardDetail .userBox {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 12px;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
#boardDetail .userBox .userIcon .ant-avatar {
  margin: 0;
  width: 48px;
  height: 48px;
}
#boardDetail .userBox .userInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
#boardDetail .userBox .userInfo .name {
  display: flex;
  align-items: center;
  gap: 8px;
}
#boardDetail .userBox .userInfo .name p {
  font-size: 16px;
}
#boardDetail .userBox .userInfo .info-container {
  display: flex;
}
#boardDetail .userBox .userInfo .ranking_s {
  min-width: 0;
  margin-right: 10px;
}
#boardDetail .userBox .userInfo .dropdown-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: auto;
  align-items: center;
}
#boardDetail .userBox .userInfo .dropdown-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  height: 70%;
  gap: 4px;
}
#boardDetail .userBox .userInfo .dropdown-content {
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 2px;
  z-index: 10;
  border: 1px solid #c718e2;
}
#boardDetail .userBox .userInfo .dropdown-content > div {
  padding: 8px 12px;
}
#boardDetail .userBox .userInfo .dropdown-content > div:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
}
#boardDetail .userBox .userInfo .dropdown-content > div.ranking_s::after {
  display: none;
}
#boardDetail .userBox .userInfo .dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  padding: 0;
}
#boardDetail .userBox .userInfo .name .star-rating {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: auto;
}
#boardDetail .userBox .userInfo .name .star-rating .anticon-star {
  font-size: 16px;
  color: #ccc;
}
#boardDetail .userBox .aiArea {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  font-weight: 600;
  color: #8790a3;
}
#boardDetail .userBox .aiArea .majorModel {
  padding: 0;
  width: auto; 
}
#boardDetail .button-group {
  display: flex;
  gap: 16px;
  justify-content: center;
}
#boardDetail .document-container.view {
  height: auto;
  min-height: 450px;
  /* max-height: 800px; */
  /* overflow-y: auto; */
  padding: 40px 0;
  /* border-radius: 2px;*/
  font-size: 16px;
  color: #4a4b50;
  line-height: 1.6;
}
#boardDetail .file-attachment {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 12px;
  padding: 12px 0 16px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
#boardDetail .file-attachment .file-title {
  font-size: 12px;
  font-weight: bold;
  color: #464548;
}
#boardDetail .file-attachment .file-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  list-style: none;
  width: 100%;
  gap: 12px;
}
#boardDetail .file-attachment .file-list .file-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
#boardDetail .file-attachment .file-list .file-item {
  overflow: hidden;
  text-overflow: ellipsis;
}
#boardDetail .file-attachment .file-list .file-item button {
  width: 100%;
}
#boardDetail .file-attachment .file-list .file-item span {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
#boardDetail .file-attachment .file-item button {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0;
  border: 0;
  box-shadow: none;
  height: 24px;
  gap: 12px;
}
#boardDetail .file-attachment .file-item button::before {
  content: '';
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #fff url(/src/assets/images/common/btn-down.svg) no-repeat center;
  border-radius: 100%;
  border: 1px solid #a3a1a7;
}
#boardDetail .file-attachment .content-container {
  height: auto;
  min-height: 450px;
  max-height: 800px;
  overflow-y: auto;
  padding: 8px;
  border-radius: 2px;
}

#boardDetail .ant-form-item .ant-form-item-label > label {
  color: #464548;
  font-size: 12px;
}
#boardDetail .ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
  border-radius: 4px;
  border: 1px dashed #e21dfd;
  background: #fcf7fd;
}

/* 다시 체크할 영역 - 드롭박스 등 노출되지 않는 부분 끝  */

/* 게시글 */
#boardDetail .contentBox {
  padding: 20px 10px;
}
#boardDetail .contentBox .content {
  min-height: 250px;
}
#boardDetail .contentBox .content-btn {
  display: flex;
  justify-content: center;
}

/* 에디터 */
#boardDetail .l-form {
  padding: 30px 0;
}

/* 버튼 */
#boardDetail .buttonBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* 반응형 */
@media (max-width: 700px) {
  #boardDetail {
    padding: 20px 36px;
  }
  #boardDetail .titleBox {
    gap: 10px;
  }
  #boardDetail .titleBox .cate {
    font-size: 14px;
  }
  #boardDetail .titleBox .titleArea h2 {
    font-size: 23px;
    line-height: 1.5;
  }
  #boardDetail .userBox {
    gap: 10px;
    padding: 10px 0;
  }
  #boardDetail .userBox .userIcon .ant-avatar {
    width: 38px;
    height: 38px;
  }
  #boardDetail .userBox .userInfo .name {
    gap: 5px;
  }
  #boardDetail .userBox .userInfo .name p {
    font-size: 14px;
  }
  #boardDetail .ant-btn-default.btn-dark {
    min-width: 100px;
    height: 40px;
  }
}

/* 댓글 */
#boardDetail .comment-system {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 16px;
}
#boardDetail .comment-system .write {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 12px;
  padding: 16px 0 24px;
}
#boardDetail .comment-system .write .ant-comment .ant-comment-content {
  margin: 0;
}
#boardDetail .comment-system h2 {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: #0e0e1f;
  font-size: 32px;
  font-weight: 700;
  line-height: 100%; /* 32px */
  padding: 12px 0 20px;
}
#boardDetail .comment-system h3 {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: #0e0e1f;
  letter-spacing: 0.3px;
  font-size: 16px;
  font-weight: 500;
}
#boardDetail .comment-system .ant-comment .repleWriteArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}
#boardDetail .comment-system .ant-comment .repleWriteArea * {
  height: 48px;
}
#boardDetail .comment-system .ant-comment .repleWriteArea span {
  display: flex;
  justify-content: center;
  align-items: center;
}
#boardDetail .comment-system .ant-comment .repleWriteArea textarea {
  padding-top: 12px;
}
#boardDetail .comment-system .ant-comment-nested {
  margin-inline-start: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 12px;
}
#boardDetail .comment-system .ant-comment-nested .repleWrite {
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px 16px 16px 72px;
  border-radius: 70px 16px 16px 72px;
  background: #f9f9f9;
}
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 16px 0 16px 0;
  background-color: #ececee;
  border-radius: 70px 16px 16px 72px;
  margin: 0;
  gap: 12px;
}
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
}
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-nested .repleBox {
  border-top: 1px solid #ececee;
}
/*  #boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-nested:has(div) {
  border-top: 1px solid #ececee;
} */
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding-left: 72px;
}
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author,
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author-name {
  width: 100%;
}
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author .box,
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author-name .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author .box .btnArea,
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author-name .box .btnArea {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-right: 16px;
}
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author .box .btn-lineR,
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author-name .box .btn-lineR {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #3e3d53;
  color: #3e3d53;
  border-radius: 24px;
  background: inherit;
  height: 32px;
  padding: 8px 12px;
  min-width: 44px;
  cursor: pointer;
}
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author .box .btn-lineR:hover,
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author-name .box .btn-lineR:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
  border: 1px solid transparent;
}
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-detail {
  margin-right: 16px;
}
#boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content .rereWrite {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: stretch;
}
#boardDetail .comment-system .repleBox .ant-comment {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 16px;
  background-color: #ececee;
  border-radius: 72px 72px 16px 72px;
  margin: 0;
  gap: 12px;
}
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
}
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author,
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name {
  width: 100%;
}
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box,
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .info,
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .info {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 8px;
}
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .info .comment-name,
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .info .comment-name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: start;
  height: 32px;
  padding: 0;
  text-decoration: underline;
}
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .info .comment-date,
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .info .comment-date {
  margin-left: 0;
}
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btnArea,
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .btnArea {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-right: 16px;
}
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btn-lineR,
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .btn-lineR {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #3e3d53;
  color: #3e3d53;
  border-radius: 24px;
  background: inherit;
  height: 32px;
  padding: 8px 12px;
  min-width: 44px;
  cursor: pointer;
}
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btn-lineR:hover,
#boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .btn-lineR:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
#boardDetail .comment-system .repleBox .ant-list .ant-list * {
  outline: none;
  font-size: 12px;
}
#boardDetail .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btnArea .info,
#boardDetail #boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .info {
  gap: 0;
}
#boardDetail .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btnArea .btnArea,
#boardDetail #boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .btnArea {
  padding-right: 0 !important;
}
#boardDetail .ant-comment .ant-comment-content-author > a,
#boardDetail .ant-comment .ant-comment-content-author > span {
  padding-inline-end: 0;
}
#boardDetail .repleBox .ant-comment-inner {
  display: flex;
  padding-block: 0;
}
#boardDetail .repleBox .ant-comment-avatar .ant-avatar {
  margin-left: 0;
  width: 140px;
  height: 140px;
}
#boardDetail .repleBox .ant-comment-avatar .ant-avatar img {
  width: 140px;
  height: 140px;
}
#boardDetail .repleBox .ant-comment-avatar + .ant-comment-content {
  gap: 12px;
}
#boardDetail .ant-avatar {
  background: #e4e4e7;
}
#boardDetail .ant-comment .ant-comment-avatar {
  margin-inline-end: 0px;
}
#boardDetail .ant-comment .ant-comment-content {
  gap: 12px;
}
#boardDetail textarea.ant-input:focus {
  background: #fff;
}
#boardDetail textarea.ant-input:focus + button {
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}
#boardDetail .ant-form-item-control-input-content .write {
  justify-content: flex-start;
}
.mypageSection .ant-form {
  width: 100%;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-search,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-inline-end: 18px;
  color: #000;
}
.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  background: none !important;
  background-color: none !important;
}

/* 댓글 반응형 */
@media (max-width: 700px) {
  #boardDetail .view *,
  #boardDetail .ant-form-item.ant-form-item-has-success .ant-form-item-control-input-content {
    font-size: 14px;
  }
  .button-group {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
  }
  .button-group button {
    height: 40px;
  }
  #boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btnArea,
  #boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .btnArea {
    padding-right: 0;
  }
  #boardDetail .repleBox .ant-comment-nested div {
    gap: 0;
  }
  #boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner {
    position: relative;
    gap: 8px;
  }
  #boardDetail .repleBox .ant-comment-avatar .ant-avatar {
    width: 30px;
    height: 30px;
  }
  #boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content {
    width: 100%;
  }
  #boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .info {
    position: relative;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /*  */
  }
  #boardDetail .repleBox .ant-comment .comment-date {
    flex-direction: column;
    gap: 0;
    align-items: end;
  }
  #boardDetail .repleBox .ant-comment .comment-date .fromTo {
    order: 1;
  }
  #boardDetail .ant-comment .ant-comment-avatar {
    position: absolute;
  }
  #boardDetail .comment-name {
    margin-right: 0;
  }
  #boardDetail .comment-system .repleBox .ant-comment .ant-comment-inner:has(.ant-comment-avatar) .ant-comment-content .ant-comment-content-author-name .box {
    padding-left: 10px;
    margin-left: 30px;
  }
  #boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content {
    gap: 12px;
    padding-left: 0;
  }
  #boardDetail .answer.btn-lineR {
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 0;
    width: 30px;
    padding: 0;
    white-space: nowrap;
  }
  #boardDetail .ant-comment-content-detail {
    min-height: 30px;
  }
  #boardDetail .comment-system .ant-comment-nested .repleWrite {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 30px 0 0 0;
  }
  #boardDetail .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner + .ant-comment-nested .repleWrite {
    flex-direction: column;
    gap: 10px;
    padding: 0;
  }
  #boardDetail .ant-btn-default.btn-gray2 {
    margin-left: auto;
  }
  .more-select {
    right: 0;
  }
}
