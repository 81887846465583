#otherWrapper > .rsWrapper .content {
  gap: 16px;
}

#otherWrapper > .rsWrapper > div:first-child {
  position: relative;
  padding: 40px 0;
  align-items: flex-start;
}
#otherWrapper > .rsWrapper > div.top {
  padding: 40px 0 0;
}
#otherWrapper .filter {
  background: #f3f3f3;
  padding: 20px 0;
}

#otherWrapper .filter#filter .listBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
/* #otherWrapper */
.filter#filter .listBox .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px 20px;
  width: calc(100% - 156px);
  justify-content: flex-start;
}
.filter#filter .listBox .ant-radio-group label {
  display: flex;
  flex-direction: row;
  min-width: 100px;
}
/* .filter .listBox {
  display: none;
}   마이페이지 홈 필더 안보임*/

.filter .rsWrapper .title {
  width: 152px;
  margin-top: 3px;
}

.filter .rsWrapper .title h2 {
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
  margin: 0;
  text-wrap-mode: nowrap;
  gap: 16px;
}

.filter .rsWrapper .title img {
  width: 12px;
}

.filter .rsWrapper .reset #whiteBtn {
  width: 89px;
  height: 34px;
  border-radius: 24px;
  font-size: 14px;
  margin: 8px 0 0;
}

.filter .rsWrapper .reset #whiteBtn img {
  width: 18px;
}

.filter .rsWrapper .reset #greyBtn2 {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  font-size: 14px;
  color: #e94cf7;
}

.filter .rsWrapper .reset #greyBtn4 {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  font-size: 14px;
}
.filter .rsWrapper {
  gap: 8px;
}
.filter .rsWrapper .listBox {
  margin: 0;
  min-height: 36px;
  padding: 8px 0;
}
#otherWrapper .rsWrapper .staff .staffList,
#otherWrapper .rsWrapper .newContents .staffList {
  flex-wrap: wrap;
  min-height: 200px; /* 400 일 경우 최신 프롬프트가 없는경우 공백이 있으므로 최소값으로 1줄 200px로 노출*/
  align-items: start;
}
/* RADIO */
#otherWrapper .filter .rsWrapper .listBox .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;
}

.ant-radio-wrapper span.ant-radio + * {
  padding: 0 12px;
}

/* LIST */
#otherWrapper .filter .rsWrapper .listBox:nth-child(4) {
  border-bottom: none;
}

#otherWrapper .filter .rsWrapper .listBox .checkBox {
  margin: 0 0 16px;
}

#otherWrapper .filter .rsWrapper .listBox .checkBox .check {
  width: 20px;
  height: 20px;
  margin: 0 12px 0 0;
}

#otherWrapper .filter .rsWrapper .listBox .checkBox p {
  font-size: 14px;
  color: #000000;
}

#otherWrapper #listWrapper .rsWrapper {
  padding: 16px 0 40px;
}
#otherWrapper > .rsWrapper .content {
  gap: 16px;
}
#otherWrapper .rsWrapper > div .content > .titleBox {
  margin: 0 0 20px;
}

#otherWrapper .rsWrapper > div .content .titleBox > h1 {
  font-size: 32px;
  font-weight: bold;
  color: #0e0e1f;
}

#otherWrapper .rsWrapper > div .content .titleBox > h1 br {
  display: none;
}

#otherWrapper .rsWrapper > div .content > .titleBox > img {
  display: none;
}

#otherWrapper .rsWrapper > div .content .titleBox .search {
  width: 400px;
  height: 48px;
  background: #f4f5f7;
  padding: 8px 12px;
  gap: 12px;
}

#otherWrapper .rsWrapper > div .content .titleBox .search img {
  width: 18px;
  /* margin: 0 13px 0 0; */
  height: auto;
}

#otherWrapper .rsWrapper > div .content .titleBox .search input {
  border: none;
  background: none;
  width: calc(100% - 18px);
  box-shadow: none;
}
#otherWrapper .rsWrapper > div .content .titleBox .search input::before {
  display: none;
}
#otherWrapper .rsWrapper > div .content .titleBox .search input::placeholder {
  font-size: 13px;
  color: #a3a1a7;
}

#otherWrapper .rsWrapper > div .content .searchBox div {
  width: auto;
}

#otherWrapper .rsWrapper > div .content .searchBox div #pinkBtn2 {
  width: 80px;
  height: 33px;
  font-size: 14px;
  border-radius: 24px;
  margin: 0 4px 0 0;
}

#otherWrapper .rsWrapper > div .content .searchBox div #pinkBtn2.active {
  color: #ffffff;
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  border: none;
}

#otherWrapper .rsWrapper > div .content .searchBox div div {
  margin: 0 0 0 8px;
}

#otherWrapper .rsWrapper > div .content .searchBox div #pinkBtn {
  width: 120px;
  height: 40px;
  border-radius: 4px;
}

#otherWrapper .rsWrapper > div .content .searchBox div #pinkBtn div img {
  width: 18px;
  margin: 0 13px 0 0;
}

#otherWrapper .rsWrapper > div .content .searchBox div #pinkBtn p,
#otherWrapper .rsWrapper > div .content .searchBox div #whiteBtn p {
  font-size: 14px;
}

.content .searchBox div #whiteBtn {
  width: auto;
  height: 40px;
  padding: 0 12px;
  border-radius: 4px;
}

.content .searchBox div #whiteBtn img {
  width: 14px;
  margin: 0 13px 0 0;
}

#otherWrapper .rsWrapper > div .content .searchBox {
  padding: 12px 0;
  border-bottom: 2px solid #464548;
}

#otherWrapper .rsWrapper .staff .staffList .staffBox:nth-child(3n),
#otherWrapper .rsWrapper .newContents .staffList .staffBox:nth-child(3n),
#otherWrapper .rsWrapper .staff .staffList .staffBox,
#otherWrapper .rsWrapper .newContents .staffList .staffBox {
  width: calc(100% / 6 - 8px);
  margin: 0 9px 20px 0;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  border: 4px solid transparent;
  transition: 0.2s;
}

#otherWrapper .rsWrapper .staff .staffList .staffBox:nth-child(6n),
#otherWrapper .rsWrapper .newContents .staffList .staffBox:nth-child(6n) {
  margin: 0 0 20px 0;
}
#otherWrapper .rsWrapper > div .content .listBox {
  flex-wrap: wrap;
  min-height: 400px;
  align-content: flex-start;
}

#otherWrapper .rsWrapper > div .content .listBox .list:hover,
#otherWrapper .rsWrapper > div .content .listBox .list:hover .absoluteBox {
  padding: 4px;
  box-shadow: 0 2px 13px #b041f3;
}

#otherWrapper .rsWrapper > div .content .listBox .list {
  width: calc(100% / 6 - 8px);
  height: 168px;
  margin: 0 9px 20px 0;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
}

#otherWrapper .rsWrapper > div .content .listBox .list:nth-child(6n) {
  margin: 0 0 20px;
}

#otherWrapper .rsWrapper > div .content .listBox .list img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

#otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: all 0.2s ease-in-out;
}

#otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox .user {
  padding: 8px;
}

#otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox .user img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}

#otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox .user p {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
}

#otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox div .title {
  padding: 12px 8px;
  margin-top: 0;
  border-radius: 0 0 5px 5px;
  background: #e6e6e6;
  height: 40px;
}

#otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox div .title h1 {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* font-size: 16px;
  font-weight: 400; */
  text-shadow: none;
  color: #0e0e1f;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -0.26px;
}

#otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox div .title p {
  font-size: 13px;
  color: #0e0e1f;
  font-weight: 600;
}

#otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox div > img {
  margin: 10px;
  width: 32px;
}
#otherWrapper.rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox {
  background: #e2e2e2;
}
/* 모바일필터 */
#mobileFilter > div:first-child > p {
  font-size: 30px;
  margin: 0 0 38px;
}

#mobileFilter .content h1 {
  font-size: 26px;
  font-weight: 700;
  margin: 0 0 5px;
  color: #323146;
}

#mobileFilter .content h2 {
  font-size: 20px;
  color: #323146;
  margin: 0 0 30px;
}

#mobileFilter .content .searchBox {
  height: 41px;
  border-bottom: 1px solid #87858b;
  padding: 12px 10px;
  margin: 0 0 20px;
}

.ant-drawer-body .ant-input-outlined {
  background: transparent;
}
#mobileFilter .content .searchBox:has(input:focus) {
  border-bottom: 2px solid #c718e2;
}
#mobileFilter .content .searchBox input:focus {
  color: #000;
}

#mobileFilter .content .searchBox input {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

#mobileFilter .content .searchBox {
  padding: 12px 0;
}
#mobileFilter .content .searchBox input {
  width: calc(100% - 13px);
  border: none;
  padding: 0;
  box-shadow: none;
}
#mobileFilter .content .searchBox input.ant-input-outlined {
  background: inherit;
}
#mobileFilter .content .searchBox img {
  width: 16px;
}

#mobileFilter .content .listBox {
  border-bottom: 1px solid #dbdbe0;
}

#mobileFilter .content .listBox:last-child {
  border-bottom: none;
}

#mobileFilter .content .listBox h3 {
  font-size: 18px;
  color: #323146;
  margin: 20px 0 16px;
}

#mobileFilter .content .listBox div {
  margin: 0 0 20px;
  flex-wrap: wrap;
}

#mobileFilter .content .listBox div .list {
  width: auto;
  padding: 8px;
  border-radius: 16px;
  margin: 0 8px 6px 0;
  border: 1px solid #d6d6d6;
}

#mobileFilter .content .listBox div .list#active {
  border: solid 1px #e94cf7;
}

#mobileFilter .content .listBox div .list#active p {
  color: #e94cf7;
}

#mobileFilter .content .listBox div .list#active .round {
  background: #e94cf7;
}

#mobileFilter .content .listBox div .list#active .round img {
  width: 6px;
  display: flex;
}

#mobileFilter .content .listBox div .list .round img {
  display: none;
}

#mobileFilter .content .listBox div .list img {
  display: none;
}

#mobileFilter .content .listBox div .list .round {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #d6d6d6;
  margin: 0 6px 0 0;
}

#mobileFilter .content .listBox div .list p {
  font-size: 12px;
  color: #222;
}

#mobileFilter .btnBox {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 30px 20px;
  background: #ffffff;
  gap: 8px;
}

#mobileFilter .btnBox #logoutBtn,
#mobileFilter .btnBox #pinkBtn {
  width: 100%;
  height: 37px;
  border-radius: 3px;
}

#mobileFilter .btnBox #logoutBtn img {
  width: 20px;
  margin: 0 10px 0 0;
}

#mobileFilter .btnBox #pinkBtn p {
  font-size: 14px;
  color: #0e0e1f;
}

#mobileFixedBox {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  padding: 10px 15px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #f4f5f7;
  background-color: #ffffff;
  z-index: 100;
  border-radius: 30px;
  display: none;
}

#mobileFixedBox .select {
  width: auto;
  padding: 11px 14px;
  background-color: #f4f5f7;
  border-radius: 40px;
}

#mobileFixedBox .select p {
  font-size: 14px;
}

#mobileFixedBox .select span {
  color: #e94cf7;
  font-weight: 700;
}

#mobileFixedBox > div {
  width: auto;
}

#mobileFixedBox > div img {
  width: 20px;
  margin: 0 0 0 30px;
}

/** tab */
#otherWrapper > div .tab {
  padding: 0 16px 16px;
  border-bottom: solid 2px #000;
}

#otherWrapper > div .tab #pinkBtn2 {
  width: 150px;
  height: 41px;
  border-radius: 24px;
  font-size: 14px;
  margin: 0 2px;
}

/** 내 마켓플레이스 */
#otherWrapper.promptList > .rsWrapper > div.bottom {
  padding-top: 0px;
}

#otherWrapper.promptList > .rsWrapper > div.top {
  padding-bottom: 0px;
}

#otherWrapper.promptList > .rsWrapper > .bottom .content.sales .listBox .list {
  height: 224px;
}

#otherWrapper.promptList > .rsWrapper > .bottom .content.sales .listBox .list .absoluteBox div .title {
  height: 66px;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

#otherWrapper.promptList > .rsWrapper > .bottom .content.sales .listBox .list .absoluteBox div .title > div {
  padding: 4px 0;
}

#otherWrapper.promptList > .rsWrapper > .bottom .content.sales .listBox .list .absoluteBox div .title > div:first-child h1,
#otherWrapper.promptList > .rsWrapper > .bottom .content.sales .listBox .list .absoluteBox div .title > div:first-child p {
  font-size: 13px;
  font-weight: 600;
}

#otherWrapper.promptList > .rsWrapper > .bottom .content.sales .listBox .list .absoluteBox div .title .icon {
  width: 12px;
  height: 12px;
}

#otherWrapper.promptList > .rsWrapper > .bottom .content.sales .listBox .list .absoluteBox div .title .info img {
  width: 18px;
  margin: 0;
}

#otherWrapper.promptList > .rsWrapper > .bottom .content.sales .listBox .list .absoluteBox div .title .info p {
  padding-left: 4px;
  font-size: 12px;
}

#otherWrapper.promptList > .rsWrapper > .bottom .content.sales .listBox .list .absoluteBox div .title .info #pinkBtn2 {
  height: 22px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  border-radius: 50px;
}
/* #listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox {
  padding: 12px 0;
} */

.form .info-area .content .box.third .half {
  gap: 12px;
}

.form .info-area .content .box.third .half .ant-input-number {
  width: calc(100% - 40px);
  margin-right: 8px;
}

@media (max-width: 1280px) {
  #otherWrapper .rsWrapper > div .content .listBox .list {
    width: calc(100% / 4 - 10px);
    height: 220px;
  }

  #otherWrapper .rsWrapper > div .content .listBox .list:nth-child(6n) {
    margin: 0 9px 20px 0;
  }

  #otherWrapper .rsWrapper > div .content .listBox .list:nth-child(4n) {
    margin: 0 0 20px;
  }
  #otherWrapper #listWrapper .rsWrapper {
    padding: 16px;
  }
  .form .info-area .content .box.third {
    width: calc(100% / 2 - 27px);
    gap: 20px;
  }
}

@media (max-width: 900px) {
  #otherWrapper .rsWrapper > div .content .titleBox > h1 br {
    display: initial;
  }

  #otherWrapper .rsWrapper > div .content .titleBox > h1 span {
    font-size: 20px;
  }
  #otherWrapper #listWrapper .rsWrapper > div .listBox .staffBox,
  #otherWrapper .rsWrapper > div .content .listBox .list {
    width: calc(100% / 2 - 10px);
    height: 220px;
  }
  #otherWrapper #listWrapper .rsWrapper > div .listBox .staffBox {
    height: auto;
  }
  #otherWrapper #listWrapper .rsWrapper > div .listBox .staffBox:nth-child(6n),
  #otherWrapper #listWrapper .rsWrapper > div .listBox .staffBox:nth-child(4n),
  #otherWrapper .rsWrapper > div .content .listBox .list:nth-child(6n),
  #otherWrapper .rsWrapper > div .content .listBox .list:nth-child(4n) {
    margin: 0 20px 20px 0;
  }

  #otherWrapper #listWrapper .rsWrapper > div .listBox .staffBox:nth-child(2n),
  #otherWrapper .rsWrapper > div .content .listBox .list:nth-child(2n) {
    margin: 0 0 20px;
  }
  .form .info-area .content .box.third {
    width: 100%;
    gap: 20px;
  }
}

@media (max-width: 800px) {
  #otherWrapper .filter,
  #otherWrapper .rsWrapper > div .content .titleBox .search,
  #otherWrapper .rsWrapper > div .content .searchBox > div:nth-child(2) {
    display: none;
  }

  #otherWrapper .rsWrapper > div .content .listBox .list .btn.mobile {
    display: flex;
  }

  #otherWrapper .rsWrapper > div .content > .titleBox,
  #otherWrapper .rsWrapper > div .content .searchBox {
    margin: 0 0 30px;
  }
  #otherWrapper .rsWrapper > div .content .titleBox > h1 {
    font-size: 20px;
    line-height: 1.5;
  }
  #otherWrapper .rsWrapper > div .content .titleBox > h1 span {
    display: block;
    font-size: 26px;
  }
  #otherWrapper .rsWrapper > div .content > .titleBox > img {
    display: initial;
    width: 18px;
  }

  #otherWrapper .rsWrapper > div .content .searchBox > div {
    width: 100%;
    justify-content: space-between;
  }

  #mobileFixedBox {
    display: flex;
  }

  #otherWrapper .rsWrapper > div .content .listBox .list {
    width: calc(100% / 2 - 10px);
    height: 184px;
  }

  #otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox div .title {
    flex-direction: column;
    align-items: flex-end;
  }

  #otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox div .title h1 {
    width: 100%;
    font-size: 13px;
    margin: 0 0 5px;
  }

  #otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox div .title p {
    font-size: 16px;
  }
  
  /* 마켓플레이스 가격 */
  #otherWrapper #listWrapper .rsWrapper > div .listBox .staffBox .greyBox {
    align-items: start;
  }
  #otherWrapper #listWrapper .rsWrapper > div .listBox .staffBox .greyBox > div > div {
    flex-direction: column;
  }
  #otherWrapper #listWrapper .rsWrapper > div .listBox .staffBox .greyBox > div > div p {
    width: 100%;
    text-align: right;
  }
}
