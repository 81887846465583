#populerPromptSlider {
  display: block;
  /* width: calc(100% - 80px); */
}

#populerPromptSlider .list {
  padding: 0 4px;
}

#populerPromptSlider .list > div {
  padding: 4px;
  border-radius: 16px;
  position: relative;
}

#populerPromptSlider .list > div > img {
  width: 100%;
  height: 185px;
  border-radius: 16px;
}

#populerPromptSlider .list > div .absoluteBox {
  position: absolute;
  top: 4px;
  height: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 16px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}

#populerPromptSlider .list > div .absoluteBox .user {
  padding: 8px;
}

#populerPromptSlider .list > div .absoluteBox .user div {
  width: auto;
}

#populerPromptSlider .list > div .absoluteBox .user div img {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
}

#populerPromptSlider .list > div .absoluteBox .user div p {
  font-size: 13px;
  color: #ffffff;
}

#populerPromptSlider .list > div .absoluteBox .title > p {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin: 0 0 4px;
  padding: 0 10px;
}

#populerPromptSlider .list > div .absoluteBox .title .greyBox {
  background: #e2e2e2;
  padding: 8px 12px;
  border-radius: 0 0 16px 16px;
  gap: 12px;
}
.profileList .greyBox > img,
#populerPromptSlider .list > div .absoluteBox .title .greyBox > img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin: 0;
}

#populerPromptSlider .list > div .absoluteBox .title .greyBox > div {
  width: 100%;
}

#populerPromptSlider .list > div .absoluteBox .title .greyBox div > p {
  font-size: 14px;
  font-weight: 600;
  color: #0e0e1f;
}

#populerPromptSlider .list > div .absoluteBox .title .greyBox div div .imgBox {
  width: auto;
}

#populerPromptSlider .list > div .absoluteBox .title .greyBox div div .imgBox img:first-child {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
}

#populerPromptSlider .list > div .absoluteBox .title .greyBox div div .imgBox img {
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
}

#populerPromptSlider .list > div .absoluteBox .title .greyBox div div p {
  font-size: 13px;
  font-weight: 600;
}

#populerPromptSlider .arrowLeft,
#populerPromptSlider .arrowRight {
  position: absolute;
  width: 36px;
  margin: 0 4px 0 0;
  height: 36px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 100%;
  font-size: 12px;
  color: #000000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 1000;
}

#populerPromptSlider .arrowLeft:hover,
#populerPromptSlider .arrowRight:hover {
  background: linear-gradient(to right, #b041f3, #ec15ff);
  color: #ffffff;
}

#populerPromptSlider .arrowLeft,
#populerPromptSlider .arrowRight {
  top: 43%;
  right: -40px;
  margin: -18px 0 0;
}

#populerPromptSlider .slick-dots {
  display: none !important;
}

@media (max-width: 700px) {
  #populerPromptSlider .list > div .absoluteBox .title > p {
    display: none;
  }

  #populerPromptSlider .list > div .absoluteBox .title .greyBox > img {
    width: 24px;
    height: 24px;
    margin: 0 4px 0 0;
  }

  #populerPromptSlider .list > div .absoluteBox .title .greyBox > div {
    width: calc(100% - 24px - 4px);
  }

  #populerPromptSlider .list > div .absoluteBox .title .greyBox div div .imgBox img:first-child {
    display: none;
  }

  #populerPromptSlider .list > div .absoluteBox .user > img {
    display: none;
  }

  #populerPromptSlider .list > div .absoluteBox .user {
    padding: 8px;
  }

  #populerPromptSlider .list > div .absoluteBox .title .greyBox div > p {
    font-size: 12px;
  }

  #populerPromptSlider .arrowLeft {
    left: -10px;
  }

  #populerPromptSlider .arrowRight {
    right: -10px;
  }
  #populerPromptSlider .arrowLeft,
  #populerPromptSlider .arrowRight {
    background: rgba(255, 255, 255, 0.7);
  }

  #populerPromptSlider .slick-list {
    padding: 0 !important;
  }

  #populerPromptSlider .no-prompts-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    font-size: 80px;
    color: #555;
    text-align: center;
  }
}
