@charset "UTF-8";
/* 프롬프트 타이틀 탭 */

#depth2 .title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px 16px 28px;
}

#myPage > div > .title,
#prompt > div > .title,
#myMessage > div > .title,
#depth2 > div > .title,
#otherWrapper > div > .title,
#prompt > div > .title {
  /* padding: 40px 0 32px; */
  padding: 28px 0 16px;
  height: 92px;
  gap: 36px;
  align-items: flex-start;
  overflow: scroll;
}

#myPage > div > .title .overflowBox,
#depth2 > div > .title .overflowBox {
  padding: 0;
  height: 40px;
  min-width: 800px;
}

#myMessage > div > .title .overflowBox > div,
#myMessage > div > .title > div,
#depth2 > div > .title .overflowBox > div,
#depth2 > div > .title > div,
#otherWrapper > div > .title .overflowBox > div,
#otherWrapper > div > .title > div,
#prompt > div > .title .overflowBox > div,
#prompt > div > .title > div {
  margin: 0 8px;
  width: auto;
  position: relative;
  gap: 36px;
}

#myPage > div > .title .overflowBox > div {
  margin: 0 30px;
  width: auto;
  position: relative;
}

/*default tab*/
#myMessage > div .overflowBox div h1,
#depth2 > div .overflowBox div h1,
#otherWrapper > div .overflowBox div h1,
#prompt > div .overflowBox div h1,
#prompt > div > .title div h1,
#myPage > div > .title .overflowBox div h1,
#depth2 > div > .title .overflowBox div h1 {
  /* font-size: 32px; */
  font-size: 28px;
  font-weight: bold;
  color: #cccccc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 0;
  text-wrap: nowrap;
}

#prompt > div > .title .overflowBox .flexColCenter {
  position: relative;
}

#myPage > div > .title .overflowBox div .round,
#prompt > div > .title div .round {
  display: none;
}

/* 프롬프트 2depth 탭 */

#myPage > div > .title .overflowBox div h1:hover,
#myPage > div > .title .overflowBox div#active h1,
#prompt > div > .title div h1:hover,
#prompt > div > .title div#active h1,
#myMessage > div .overflowBox div h1:hover,
#depth2 > div .overflowBox div h1:hover,
#otherWrapper > div .overflowBox div h1:hover,
#prompt > div .overflowBox div h1:hover,
#myMessage > div .overflowBox div#active h1,
#depth2 > div .overflowBox div#active h1,
#otherWrapper > div .overflowBox div#active h1,
#prompt > div .overflowBox div#active h1 {
  color: #0e0e1f;
}
#myMessage > div .overflowBox div .round,
#depth2 > div .overflowBox div .round,
#otherWrapper > div .overflowBox div .round,
#prompt > div .overflowBox div .round {
  display: none;
}

#myPage > div > .title .overflowBox div#active .round,
#myMessage > div .overflowBox div#active .round,
#depth2 > div .overflowBox div#active .round,
#depth2 > div > .title .overflowBox div#active .round,
#otherWrapper > div .overflowBox div#active .round,
#prompt > div .overflowBox div#active .round,
#prompt > div > .title div#active .round {
  display: flex;
  position: absolute;
  top: -2px;
  left: -10px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #fe5139;
}

@media (max-width: 700px) {
  #myPage > div > .title .overflowBox,
  #myMessage > div > .title .overflowBox,
  #depth2 > div > .title .overflowBox,
  #otherWrapper > div > .title .overflowBox,
  #prompt > div > .title .overflowBox {
    gap: 20px;
    height: 39px;
    min-width: auto;
  }
  #myPage > div > .title .overflowBox div,
  #myMessage > div > .title .overflowBox div,
  #depth2 > div > .title .overflowBox div,
  #otherWrapper > div > .title .overflowBox div,
  #prompt > div > .title .overflowBox div {
    gap: 20px;
    margin: 0;
  }
  #myPage > div > .title .overflowBox div h1,
  #myMessage > div > .title .overflowBox div h1,
  #depth2 > div > .title .overflowBox div h1,
  #otherWrapper > div > .title .overflowBox div h1,
  #prompt > div > .title .overflowBox div h1 {
    font-size: 26px;
    padding-left: 8px;
  }
  #prompt > div > .title {
    gap: 10px;
  }
  #myPage > div > .title .overflowBox div h1,
  #prompt > div .title div h1 {
    font-size: 24px;
  }
  .myMarketplacesSection .rsWrapper:has(.title) {
    padding: 0;
  }
  #myPage > div > .title .overflowBox div#active .round,
  #myMessage > div > .title .overflowBox div#active .round,
  #depth2 > div > .title .overflowBox div#active .round,
  #otherWrapper > div > .title .overflowBox div#active .round,
  #prompt > div > .title .overflowBox div#active .round {
    top: 0px;
    left: 0px;
    width: 6px;
    height: 6px;
  }
  #myMessage > div {
    border-bottom: none;
    padding: 0;
  }
  #myMessage > div .title {
    justify-content: flex-start;
    padding: 30px 0 20px 8px;
  }
  #myMessage > div .title .overflowBox {
    justify-content: flex-start;
  }
  #depth2 .title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 20px;
    gap: 20px;
  }
  #depth2 > div > .title .overflowBox div h1 {
    font-size: 26px;
  }
  /* #myPage > div > .title .overflowBox div {
    margin: 0 20px 0 10px;
  } */
  /* #depth2 > div > .title .overflowBox div#active .round {
    top: 0px;
    left: 0px;
    width: 6px;
    height: 6px;
  } */
  /* #myPage > div > .title .overflowBox div h1 {
    font-size: 24px;
  } */

  /* #myPage > div > .title {
    justify-content: flex-start;
  } */

  /* #myPage > div > .title .overflowBox {
    justify-content: flex-start;
  } */

  /* #myPage > div > .title .overflowBox div#active .round {
    width: 6px;
    height: 6px;
    top: -6px;
    left: -6px;
  } */
}

/* 
#prompt > div > .title {
  padding: 40px 0 32px;
}

#prompt > div > .title > div {
  margin: 0 30px;
  width: auto;
  position: relative;
}

#prompt > div > .title div h1 {
  font-size: 32px;
  font-weight: bold;
  color: #cccccc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#prompt > div > .title div h1:hover,
#prompt > div > .title div#active h1 {
  color: #0e0e1f;
}

#prompt > div > .title div .round {
  display: none;
}

#prompt > div > .title div#active .round {
  display: flex;
  position: absolute;
  top: -6px;
  left: -6px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #fe5139;
}

#builder > div .tab {
  padding: 0 0 16px;
  border-bottom: solid 2px #000;
}

#builder > div .tab #pinkBtn2 {
  width: 150px;
  height: 41px;
  border-radius: 24px;
  font-size: 14px;
  margin: 0 2px;
  line-height: 1;
}

#builder > div .tab #pinkBtn2.active {
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  color: #ffffff;
  border: 1px solid transparent;
} */
