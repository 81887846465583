@charset "UTF-8";

@import 'tab.css';

#pinkBtn2 {
  font-weight: 600;
  transition: none;
}

#root {
  min-width: 360px;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  width: auto;
  height: 26px !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  margin: 0 8px 0 0;
  padding: 2px;
  line-height: 1;
}
.badge::after {
  display: none;
}
.badge.pro {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}
.badge.basic {
  background-image: inherit;
  background-color: #f8ab30;
}
.badge.beginner {
  background-image: inherit;
  background-color: #6d6c75;
}

.badge_s {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  width: auto;
  height: 26px !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  margin: 0 10px 0 0;
  padding: 2px;
  line-height: 1;
  width: auto;
  padding: 1px 2px;
  font-size: 11px;
  font-weight: 500;
  height: 23px !important;
  min-width: 44px;
  margin: 0;
}
.badge_s::after {
  display: none;
}
.badge_s.pro {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}
.badge_s.basic {
  background-image: inherit;
  background-color: #f8ab30;
}
.badge_s.beginner {
  background-image: inherit;
  background-color: #6d6c75;
}

.ranking {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  gap: 12px;
  cursor: pointer;
  gap: 8px;
}
.ranking::after {
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: '';
}
.ranking.music::before {
  background: url(/src/assets/images/common/i-rankMusic.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: '';
}
.ranking.image::before {
  background: url(/src/assets/images/common/i-rankImage.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: '';
}
.ranking.video::before {
  background: url(/src/assets/images/common/i-rankVideo.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: '';
}
.ranking.text::before {
  background: url(/src/assets/images/common/i-rankText.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: '';
}

.ranking_s {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  gap: 12px;
  cursor: pointer;
  font-size: 14px;
  min-width: 100px;
  gap: 8px;
}
.ranking_s::after {
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: '';
}
.ranking_s::after {
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
  width: 16px;
  height: 16px;
  content: '';
}
.ranking_s.music::before {
  background: url(/src/assets/images/common/i-rankMusic.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: '';
}
.ranking_s.image::before {
  background: url(/src/assets/images/common/i-rankImage.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: '';
}
.ranking_s.video::before {
  background: url(/src/assets/images/common/i-rankVideo.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: '';
}
.ranking_s.text::before {
  background: url(/src/assets/images/common/i-rankText.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: '';
}

.approval {
  display: inline-flex;
  min-width: 48px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  border: 1px solid transparent;
  height: 22px;
}
.approval::before {
  display: none;
}
.approval.on {
  background: var(--gra-pink, linear-gradient(90deg, #ca79fc 0%, #ff5eb6 100%));
}
.approval.ok {
  background: #0fc9f3;
}
.approval.request {
  background: #f8c930;
}
.approval.off {
  background: #fd8632;
}
.approval.rejection {
  background: #fe393c;
}
.approval.disable {
  background: #fff;
  border-color: #cccccc;
  color: #0e0e1f;
}
.approval.done {
  background: #525162;
}

.btn-default {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
  border-color: #e2dfe4;
  color: #0e0e1f;
}
.btn-default:hover {
  color: #0e0e1f !important;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
.btn-primery {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #e94cf7 !important;
  border-color: #e94cf7;
  color: #fff;
}
.btn-primery:hover {
  color: #fff !important;
}
.btn-dark {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #525162 !important;
  border-color: #525162;
  color: #fff;
}
.btn-dark:hover {
  color: #fff !important;
}
.btn-gra {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%) !important;
  color: #fff;
}
.btn-red {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: #f34144 !important;
  color: #fff;
  height: inherit;
  min-width: inherit;
}
.btn-line {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #3e3d53;
  color: #3e3d53;
}
.btn-gray2 {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #a3a1a7 !important;
  border-color: #a3a1a7 !important;
  color: #fff;
}
.btn-pinkGray {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: #fdf2ff !important;
  border: 1px solid #eee2f0 !important;
  color: #c49bcc;
  min-width: none;
}
.btn-defaultR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
  border-color: #e2dfe4;
  color: #0e0e1f;
  border-radius: 24px;
}
.btn-defaultR:hover {
  color: #0e0e1f !important;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
.btn-darkR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #525162 !important;
  border-color: #525162;
  color: #fff;
  border-radius: 24px;
}
.btn-darkR:hover {
  color: #fff !important;
}
.btn-graR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%) !important;
  color: #fff;
  border-radius: 24px;
}
.btn-redR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: #f34144 !important;
  color: #fff;
  border-radius: 24px;
}
.btn-lineR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #3e3d53;
  color: #3e3d53;
  border-radius: 24px;
}
.btn-grayR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #a3a1a7 !important;
  border-color: #a3a1a7 !important;
  color: #fff;
  border-radius: 24px;
}
.btn-pinkGrayR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: #fdf2ff !important;
  border: 1px solid #eee2f0 !important;
  color: #c49bcc;
  min-width: none;
  border-radius: 24px;
  padding: 0 8px;
}
.btn-close,
.btn-delete {
  padding: 4px;
  background: url(/src/assets/images/common/i-cacelPink.svg) no-repeat center;
  width: 12px;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

button[disabled] {
  background: #f2f2f2;
  color: #a29fab !important;
  border-color: transparent !important;
}
button[disabled]::before svg {
  fill: #a29fab !important;
}

.ant-btn-primary:hover,
.ant-btn-default:hover {
  box-shadow: inset 0 0 0 80px rgba(0, 0, 0, 0.1019607843);
}
.anticon-more,
.i-more {
  background: url(/src/assets/images/common/i-more.svg) no-repeat center;
  width: 18px;
  height: 32px;
  content: '';
  margin-left: 4px;
  margin-right: 4px;
}

button .icon,
button.icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  gap: 8px;
}
button .icon::before,
button.icon::before {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: contain;
  content: '';
}
button .icon::before:hover,
button.icon::before:hover {
  background-repeat: no-repeat;
  background-position: center;
}
button .icon:hover,
button.icon:hover {
  background-repeat: no-repeat;
  background-position: center;
}

.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  background: none;
  background-color: none;
}

button.icon.siren {
  display: inline-block;
  font-size: 0;
  gap: 0;
}
button.icon.siren::before {
  width: 22px;
  height: 22px;
  background: url(/src/assets/images/common/i-siren.svg) no-repeat center;
}
button.icon.siren:hover::before {
  background: url(/src/assets/images/common/i-sirenOn.svg) no-repeat center;
}
button.icon.siren.on::before {
  background: url(/src/assets/images/common/i-sirenOn.svg) no-repeat center;
}

.share::before {
  background-image: url(/src/assets/images/common/i-share.svg);
}

.listen::before {
  background-image: url(/src/assets/images/common/i-listen.svg);
  width: 22px;
  height: 20px;
}
.listen::before:hover {
  background-image: url(/src/assets/images/common/i-listenA.svg);
}

.keep::before {
  background: url(/src/assets/images/common/i-keep.svg);
}
.keep::before:hover {
  background-image: url(/src/assets/images/common/i-keepA.svg);
}

.put::before {
  background: url(/src/assets/images/common/i-basket.svg);
  width: 22px;
}
.put::before:hover {
  background-image: url(/src/assets/images/common/i-basketA.svg);
}

.sale::before {
  background: url(/src/assets/images/common/i-sale.svg);
  width: 22px;
}
.sale::before:hover {
  background-image: url(/src/assets/images/common/i-sale.svg);
}

.buying::before {
  background: url(/src/assets/images/common/i-buying.svg);
  width: 22px;
}
.buying::before:hover {
  background-image: url(/src/assets/images/common/i-buying.svg);
}

.link::before {
  background: url(/src/assets/images/common/i-link.svg);
  width: 16px;
}
.link::before:hover {
  background: url(/src/assets/images/common/i-link.svg);
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}

.trash::before {
  background: url(/src/assets/images/common/i-trashLine.svg);
  width: 22px;
}
.trash::before:hover {
  background-image: url(/src/assets/images/common/i-trashLine.svg);
}

.ques::before {
  background: url(/src/assets/images/common/i-ques.svg);
  width: 24px !important;
  height: 24px !important;
}
.ques::before :hover {
  background-image: url(/src/assets/images/common/i-quesW.svg);
}
.close,
.delete {
  padding: 4px;
}
.close::before,
.delete::before {
  background: url(/src/assets/images/common/i_del.svg);
  width: 22px;
}
.close::before:hover,
.delete::before:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}

.infoCount span {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 4px;
}
.infoCount span:before {
  background: url(/src/assets/images/common/i-heartLine.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: '';
}
.infoCount .good:before {
  background: url(/src/assets/images/common/i-heartLine.svg) no-repeat center;
}
.infoCount .sale:before {
  background: url(/src/assets/images/common/i-saleLine.svg) no-repeat center;
}

.fromTo {
  color: #2d2d40;
  font-weight: 500;
}

.blueSpan {
  color: #0d88d4 !important;
}

.w200 {
  width: 200px;
}
.rightT {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  justify-content: flex-end;
}

#root:has(section.userPage) + div .ant-modal .ant-modal-content,
.ant-modal-root .ant-modal .ant-modal-content,
.userPage .ant-modal .ant-modal-content {
  padding: 0;
}
#root:has(section.userPage) + div .ant-modal .ant-modal-content .terms_wrap h1,
.ant-modal-root .ant-modal .ant-modal-content .terms_wrap h1,
.userPage .ant-modal .ant-modal-content .terms_wrap h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
}
#root:has(section.userPage) + div .ant-modal .ant-modal-content .terms_wrap .docu,
.ant-modal-root .ant-modal .ant-modal-content .terms_wrap .docu,
.userPage .ant-modal .ant-modal-content .terms_wrap .docu {
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-height: 640px;
  overflow: auto;
  gap: 20px;
  line-height: 1.6;
  font-weight: 400;
  padding-bottom: 40px;
}
#root:has(section.userPage) + div .ant-modal .ant-modal-content .terms_wrap .docu h2,
.ant-modal-root .ant-modal .ant-modal-content .terms_wrap .docu h2,
.userPage .ant-modal .ant-modal-content .terms_wrap .docu h2 {
  margin-bottom: 12px;
}
#root:has(section.userPage) + div .ant-modal .ant-modal-content .terms_wrap .docu table,
.ant-modal-root .ant-modal .ant-modal-content .terms_wrap .docu table,
.userPage .ant-modal .ant-modal-content .terms_wrap .docu table {
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 16px;
}
#root:has(section.userPage) + div .ant-modal .ant-modal-content .terms_wrap .docu table th,
.ant-modal-root .ant-modal .ant-modal-content .terms_wrap .docu table th,
.userPage .ant-modal .ant-modal-content .terms_wrap .docu table th {
  height: 40px;
  background: #eee;
}
#root:has(section.userPage) + div .ant-modal .ant-modal-content .terms_wrap .docu table td,
.ant-modal-root .ant-modal .ant-modal-content .terms_wrap .docu table td,
.userPage .ant-modal .ant-modal-content .terms_wrap .docu table td {
  padding: 16px;
  border: 1px solid #ddd;
}
#root:has(section.userPage) + div .ant-modal-header.ant-modal .ant-modal-title,
#root:has(section.userPage) + div .ant-modal-header .ant-modal-title,
.ant-modal-root .ant-modal-header.ant-modal .ant-modal-title,
.ant-modal-root .ant-modal-header .ant-modal-title,
.userPage .ant-modal-header.ant-modal .ant-modal-title,
.userPage .ant-modal-header .ant-modal-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  padding: 20px 24px;
  font-size: 24px;
  font-weight: 700;
}

#root:has(section.userPage) + div .ant-modal-body,
.ant-modal-root .ant-modal-body,
.userPage .ant-modal-body {
  padding: 16px 40px;
}
#root:has(section.userPage) + div .ant-modal-body,
.ant-modal-root .ant-modal-body,
.userPage .ant-modal-body .ant-typography {
  font-size: 12px;
  font-weight: 500;
}
#root:has(section.userPage) + div .ant-modal-body .checkLabel,
.ant-modal-root .ant-modal-body .checkLabel,
.userPage .ant-modal-body .checkLabel {
  margin-bottom: 8px;
}
#root:has(section.userPage) + div .ant-modal-body .ant-modal-confirm-body-wrapper,
.ant-modal-root .ant-modal-body .ant-modal-confirm-body-wrapper,
.userPage .ant-modal-body .ant-modal-confirm-body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
#root:has(section.userPage) + div .ant-modal-body:before,
#root:has(section.userPage) + div .ant-modal-body:after,
.ant-modal-root .ant-modal-body:before,
.ant-modal-root .ant-modal-body:after,
.userPage .ant-modal-body:before,
.userPage .ant-modal-body:after {
  display: none;
}
#root:has(section.userPage) + div .ant-modal-body .ant-input-affix-wrapper > input.ant-input,
.ant-modal-root .ant-modal-body .ant-input-affix-wrapper > input.ant-input,
.userPage .ant-modal-body .ant-input-affix-wrapper > input.ant-input {
  display: flex;
  height: 38px;
  padding: 7px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid var(--b-input, #e2dfe4);
  background: #f7f7f8;
}
#root:has(section.userPage) + div .ant-modal-body .ant-input-affix-wrapper > input.ant-input:hover,
.ant-modal-root .ant-modal-body .ant-input-affix-wrapper > input.ant-input:hover,
.userPage .ant-modal-body .ant-input-affix-wrapper > input.ant-input:hover {
  background-color: transparent;
}
#root:has(section.userPage) + div .ant-modal-body .ant-input-affix-wrapper #newName,
#root:has(section.userPage) + div .ant-modal-body .ant-input-affix-wrapper.ant-input-password input,
.ant-modal-root .ant-modal-body .ant-input-affix-wrapper #newName,
.ant-modal-root .ant-modal-body .ant-input-affix-wrapper.ant-input-password input,
.userPage .ant-modal-body .ant-input-affix-wrapper #newName,
.userPage .ant-modal-body .ant-input-affix-wrapper.ant-input-password input {
  border: 0;
}
#root:has(section.userPage) + div .ant-modal-body .ant-input-outlined:hover,
.ant-modal-root .ant-modal-body .ant-input-outlined:hover,
.userPage .ant-modal-body .ant-input-outlined:hover {
  background: transparent;
}
#root:has(section.userPage) + div .ant-modal-body .ant-input-outlined:hover,
#root:has(section.userPage) + div .ant-modal-body .ant-input-outlined:focus,
#root:has(section.userPage) + div .ant-modal-body .ant-input-outlined:focus-within,
.ant-modal-root .ant-modal-body .ant-input-outlined:hover,
.ant-modal-root .ant-modal-body .ant-input-outlined:focus,
.ant-modal-root .ant-modal-body .ant-input-outlined:focus-within,
.userPage .ant-modal-body .ant-input-outlined:hover,
.userPage .ant-modal-body .ant-input-outlined:focus,
.userPage .ant-modal-body .ant-input-outlined:focus-within {
  border-color: #c718e2;
}
#root:has(section.userPage) + div .ant-modal-body .ant-input-outlined:focus-within input,
.ant-modal-root .ant-modal-body .ant-input-outlined:focus-within input,
.userPage .ant-modal-body .ant-input-outlined:focus-within input {
  background: transparent;
}
#root:has(section.userPage) + div .ant-modal-body .btnBox button.btn-default,
.ant-modal-root .ant-modal-body .btnBox button.btn-default,
.userPage .ant-modal-body .btnBox button.btn-default {
  border: 1px solid #525162;
}
#root:has(section.userPage) + div .ant-modal-footer,
#root:has(section.userPage) + div .ant-modal-confirm-btns,
.ant-modal-root .ant-modal-footer,
.ant-modal-root .ant-modal-confirm-btns,
.userPage .ant-modal-footer,
.userPage .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 40px 24px;
}
#root:has(section.userPage) + div .p20 .ant-modal-body,
.ant-modal-root .p20 .ant-modal-body,
.userPage .p20 .ant-modal-body {
  padding: 0 20px 24px;
}
#root:has(section.userPage) + div .p20 .ant-modal-body h1.title,
.ant-modal-root .p20 .ant-modal-body h1.title,
.userPage .p20 .ant-modal-body h1.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 76px;
  padding: 20px 0;
}
#root:has(section.userPage) + div .p20 .ant-modal-body form,
.ant-modal-root .p20 .ant-modal-body form,
.userPage .p20 .ant-modal-body form {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  flex: auto;
  gap: 16px;
  width: 100%;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .inputArea,
.ant-modal-root .p20 .ant-modal-body .inputArea,
.userPage .p20 .ant-modal-body .inputArea {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 16px;
  border-radius: 8px;
  background: #f9f9f9;
  gap: 16px;
  width: 100%;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .inputArea .ant-form-item > div,
.ant-modal-root .p20 .ant-modal-body .inputArea .ant-form-item > div,
.userPage .p20 .ant-modal-body .inputArea .ant-form-item > div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  flex: auto;
  gap: 8px;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .inputArea .ant-form-item > div > div,
.ant-modal-root .p20 .ant-modal-body .inputArea .ant-form-item > div > div,
.userPage .p20 .ant-modal-body .inputArea .ant-form-item > div > div {
  width: 100%;
  flex: auto;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .inputArea .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector,
.ant-modal-root .p20 .ant-modal-body .inputArea .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector,
.userPage .p20 .ant-modal-body .inputArea .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .term,
.ant-modal-root .p20 .ant-modal-body .term,
.userPage .p20 .ant-modal-body .term {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  gap: 8px;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .term h3,
.ant-modal-root .p20 .ant-modal-body .term h3,
.userPage .p20 .ant-modal-body .term h3 {
  height: 36px;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .term > .ant-row,
.ant-modal-root .p20 .ant-modal-body .term > .ant-row,
.userPage .p20 .ant-modal-body .term > .ant-row {
  justify-content: space-between;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .term > .ant-row .termDoc,
.ant-modal-root .p20 .ant-modal-body .term > .ant-row .termDoc,
.userPage .p20 .ant-modal-body .term > .ant-row .termDoc {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .term > .ant-row .ant-row,
.ant-modal-root .p20 .ant-modal-body .term > .ant-row .ant-row,
.userPage .p20 .ant-modal-body .term > .ant-row .ant-row {
  align-items: center;
  gap: 12px;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .termCenter,
.ant-modal-root .p20 .ant-modal-body .termCenter,
.userPage .p20 .ant-modal-body .termCenter {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .termCenter .ant-form-item-control-input-content label,
.ant-modal-root .p20 .ant-modal-body .termCenter .ant-form-item-control-input-content label,
.userPage .p20 .ant-modal-body .termCenter .ant-form-item-control-input-content label {
  text-align: center;
  font-weight: 500;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .agreeArea,
.ant-modal-root .p20 .ant-modal-body .agreeArea,
.userPage .p20 .ant-modal-body .agreeArea {
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .agreeArea h2,
.ant-modal-root .p20 .ant-modal-body .agreeArea h2,
.userPage .p20 .ant-modal-body .agreeArea h2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 0 12px;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .agreeArea h2:before,
.ant-modal-root .p20 .ant-modal-body .agreeArea h2:before,
.userPage .p20 .ant-modal-body .agreeArea h2:before {
  content: '';
  width: 18px;
  height: 18px;
  background: url(/src/assets/images/common/i-cautionRed.svg) no-repeat center;
  display: flex;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .btnArea .ant-form-item-control-input-content,
.ant-modal-root .p20 .ant-modal-body .btnArea .ant-form-item-control-input-content,
.userPage .p20 .ant-modal-body .btnArea .ant-form-item-control-input-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-top: 20px;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .ant-form-item,
.ant-modal-root .p20 .ant-modal-body .ant-form-item,
.userPage .p20 .ant-modal-body .ant-form-item {
  gap: 8px;
  margin: 0;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .ant-form-item .ant-input-outlined,
#root:has(section.userPage) + div .p20 .ant-modal-body .ant-form-item input,
.ant-modal-root .p20 .ant-modal-body .ant-form-item .ant-input-outlined,
.ant-modal-root .p20 .ant-modal-body .ant-form-item input,
.userPage .p20 .ant-modal-body .ant-form-item .ant-input-outlined,
.userPage .p20 .ant-modal-body .ant-form-item input {
  background: #fff;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .ant-form-item .ant-input-outlined[type='password'],
#root:has(section.userPage) + div .p20 .ant-modal-body .ant-form-item input[type='password'],
.ant-modal-root .p20 .ant-modal-body .ant-form-item .ant-input-outlined[type='password'],
.ant-modal-root .p20 .ant-modal-body .ant-form-item input[type='password'],
.userPage .p20 .ant-modal-body .ant-form-item .ant-input-outlined[type='password'],
.userPage .p20 .ant-modal-body .ant-form-item input[type='password'] {
  padding: 0;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .ant-form-item .ant-form-item-label,
.ant-modal-root .p20 .ant-modal-body .ant-form-item .ant-form-item-label,
.userPage .p20 .ant-modal-body .ant-form-item .ant-form-item-label {
  text-align: left;
  padding: 0;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .ant-form-item .ant-form-item-label > label,
.ant-modal-root .p20 .ant-modal-body .ant-form-item .ant-form-item-label > label,
.userPage .p20 .ant-modal-body .ant-form-item .ant-form-item-label > label {
  font-size: 12px;
  line-height: 1;
}
#root:has(section.userPage) + div .p20 .ant-modal-body .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before,
.ant-modal-root .p20 .ant-modal-body .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before,
.userPage .p20 .ant-modal-body .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
#root:has(section.userPage) + div .p20 .ant-modal-close,
.ant-modal-root .p20 .ant-modal-close,
.userPage .p20 .ant-modal-close {
  top: 26px;
}
#root:has(section.userPage) + div .ant-modal-footer button span,
.ant-modal-root .ant-modal-footer button span,
.userPage .ant-modal-footer button span {
  font-size: 14px;
}
#root:has(section.userPage) + div #populerPromptSlider .list > div .absoluteBox .title > p,
.ant-modal-root #populerPromptSlider .list > div .absoluteBox .title > p,
.userPage #populerPromptSlider .list > div .absoluteBox .title > p {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 4px 10px;
  text-shadow: 0px 0px 4px rgb(0, 0, 0);
}
#root:has(section.userPage) + div .ant-modal .ant-modal-content,
.ant-modal-root .ant-modal .ant-modal-content,
.userPage .ant-modal .ant-modal-content {
  padding: 0;
}
#root:has(section.userPage) + div .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
#root:has(section.userPage) + div .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon,
.ant-modal-root .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-root .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon,
.userPage .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.userPage .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #f138fc;
  font-size: 40px;
}
#root:has(section.userPage) + div .ant-modal-confirm .ant-modal-confirm-body-wrapper::before,
#root:has(section.userPage) + div .ant-modal-confirm .ant-modal-confirm-body-wrapper::after,
.ant-modal-root .ant-modal-confirm .ant-modal-confirm-body-wrapper::before,
.ant-modal-root .ant-modal-confirm .ant-modal-confirm-body-wrapper::after,
.userPage .ant-modal-confirm .ant-modal-confirm-body-wrapper::before,
.userPage .ant-modal-confirm .ant-modal-confirm-body-wrapper::after {
  display: none;
  content: '';
}
#root:has(section.userPage) + div .ant-modal-confirm .ant-modal-confirm-body,
.ant-modal-root .ant-modal-confirm .ant-modal-confirm-body,
.userPage .ant-modal-confirm .ant-modal-confirm-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  gap: 24px;
  padding: 36px 40px 16px;
}

.ant-modal-confirm .ant-modal-confirm-content {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}
#root:has(section.userPage) + div .ant-modal-confirm .ant-modal-confirm-content *,
.ant-modal-root .ant-modal-confirm .ant-modal-confirm-content *,
.userPage .ant-modal-confirm .ant-modal-confirm-content * {
  font-family: 'Pretendard Variable', 'Pretendard';
  color: #000;
  font-size: 18px;
}
#root:has(section.userPage) + div .ant-modal-confirm .ant-modal-confirm-body > .anticon,
.ant-modal-root .ant-modal-confirm .ant-modal-confirm-body > .anticon,
.userPage .ant-modal-confirm .ant-modal-confirm-body > .anticon {
  margin-inline-end: 0px;
}
#root:has(section.userPage) + div .ant-modal-confirm-btns,
.ant-modal-root .ant-modal-confirm-btns,
.userPage .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  gap: 12px;
}
#root:has(section.userPage) + div .ant-modal-confirm-btns .ant-btn + .ant-btn,
.ant-modal-root .ant-modal-confirm-btns .ant-btn + .ant-btn,
.userPage .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin: 0;
}
#root:has(section.userPage) + div .ant-btn-primary,
.ant-modal-root .ant-btn-primary,
.userPage .ant-btn-primary {
  color: #eee;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  min-width: 104px;
}
#root:has(section.userPage) + div .ant-btn-variant-solid,
.ant-modal-root .ant-btn-variant-solid,
.userPage .ant-btn-variant-solid {
  color: #eee;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}
#root:has(section.userPage) + div .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div .ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div .ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root .ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root .ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.userPage .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.userPage .ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover,
.userPage .ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
#root:has(section.userPage) + div .ant-btn-default,
.ant-modal-root .ant-btn-default,
.userPage .ant-btn-default {
  background-color: #ffffff;
  border-color: #e2dfe4;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  min-width: 100px;
}
#root:has(section.userPage) + div .ant-btn-default.btn-gray2,
.ant-modal-root .ant-btn-default.btn-gray2,
.userPage .ant-btn-default.btn-gray2 {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #a3a1a7 !important;
  border-color: #a3a1a7 !important;
  color: #fff;
}
#root:has(section.userPage) + div .ant-btn-default.btn-dark,
.ant-modal-root .ant-btn-default.btn-dark,
.userPage .ant-btn-default.btn-dark {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #525162 !important;
  border-color: #525162;
  color: #fff;
}
#root:has(section.userPage) + div .ant-btn-default.btn-dark:hover,
.ant-modal-root .ant-btn-default.btn-dark:hover,
.userPage .ant-btn-default.btn-dark:hover {
  color: #fff !important;
}
#root:has(section.userPage) + div .ant-btn-default.icon,
.ant-modal-root .ant-btn-default.icon,
.userPage .ant-btn-default.icon {
  border: 0;
  padding: 4px;
  min-width: auto;
}
#root:has(section.userPage) + div .ant-btn-default.icon span,
#root:has(section.userPage) + div .ant-btn-default.icon p,
.ant-modal-root .ant-btn-default.icon span,
.ant-modal-root .ant-btn-default.icon p,
.userPage .ant-btn-default.icon span,
.userPage .ant-btn-default.icon p {
  display: none;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div .ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div .ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root .ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root .ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.userPage .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.userPage .ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.userPage .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.userPage .ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #000;
  border-color: #e2dfe4;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
#root:has(section.userPage) + div .ant-btn:not(:disabled):focus-visible,
.ant-modal-root .ant-btn:not(:disabled):focus-visible,
.userPage .ant-btn:not(:disabled):focus-visible {
  outline: none;
  outline-offset: 0;
}
#root:has(section.userPage) + div .ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):active,
.ant-modal-root .ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):active,
.userPage .ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):active {
  background: #f138fc;
  color: #fff;
}

.essentialBox .ant-form-item-label > label {
  height: auto;
  font-size: 12px;
}
.essentialBox .ant-form-item-label > label::after {
  content: '*' !important;
  color: #f34144;
  line-height: 0.5;
  padding-top: 4px;
  margin-left: 4px;
  font-weight: 700;
  font-size: 16px;
  visibility: visible !important;
  margin-inline-start: 4px !important;
}

label.ant-form-item-required,
.basicEssential {
  height: auto;
  font-size: 12px;
}
label.ant-form-item-required::after,
.basicEssential::after {
  content: '*' !important;
  color: #f34144;
  line-height: 0.5;
  padding-top: 4px;
  margin-left: 4px;
  font-weight: 700;
  font-size: 16px;
  visibility: visible !important;
  margin-inline-start: 4px !important;
}

.major {
  display: flex;
  padding: 0;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.major img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.majorModel {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  padding: 12px;
  gap: 2px;
  text-wrap-mode: nowrap;
  flex-wrap: wrap;
}
.majorModel span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50px;
  padding: 4px 5px;
  background: linear-gradient(90deg, rgba(202, 121, 252, 0.7) 0%, rgba(255, 94, 182, 0.7) 100%);
  text-wrap-mode: nowrap;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.36px;
  line-height: 1;
}
.majorModel span.title {
  color: var(--c-ff, #fff);
  text-align: center;
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.5);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 12.1px */
  letter-spacing: -0.22px;
  background: transparent;
  margin: 0;
}

.majorModelW {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  padding: 12px;
  gap: 2px;
  text-wrap-mode: nowrap;
  flex-wrap: wrap;
  padding: 0;
}
.majorModelW span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50px;
  padding: 4px 5px;
  background: linear-gradient(90deg, rgba(202, 121, 252, 0.7) 0%, rgba(255, 94, 182, 0.7) 100%);
  text-wrap-mode: nowrap;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.36px;
  line-height: 1;
}
.majorModelW span.title {
  color: var(--c-ff, #fff);
  text-align: center;
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.5);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 12.1px */
  letter-spacing: -0.22px;
  background: transparent;
  margin: 0;
}
.majorModelW span {
  border-radius: 12px;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  border: 1px solid var(--b-selectLine, #c4c3c7);
  background: #fff;
  color: #6d6c75;
}

/* .majorModel {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  padding: 12px;
  gap: 2px;
  text-wrap-mode: nowrap;
  flex-wrap: wrap;
  padding: 0;
}
.majorModel span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50px;
  padding: 4px 5px;
  background: linear-gradient(90deg, rgba(202, 121, 252, 0.7) 0%, rgba(255, 94, 182, 0.7) 100%);
  text-wrap-mode: nowrap;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.36px;
  line-height: 1;
} */

#detail .majorModel span.title,
#message .rsWrapper .profile .majorModel span.title {
  color: var(--t-blueLabel, #8790a3);
  text-align: center;
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.2px */
  letter-spacing: -0.24px;
  background: transparent;
  border: none;
}
#detail .majorModel span,
#message .rsWrapper .profile .majorModel span {
  border-radius: 12px;
  border: 1px solid var(--b-selectLine, #c4c3c7);
  background: #fff;
  color: #6d6c75;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 11px */
  letter-spacing: -0.33px;
}

.classRank {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 4px;
}
.classRank span {
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
  color: #fff;
}
.classRank span::after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-image: url('/src/assets/images/common/btn_down.svg') no-repeat right center;
}
.classRank .ant-dropdown-menu-title-content {
  gap: 8px;
}
.classRank .ant-dropdown-menu-title-content *,
.classRank .ant-dropdown-menu-title-content p {
  color: #0e0e1f !important;
}

.absolBox,
.absoluteBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.absolBox .user,
.absoluteBox .user {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
  border-radius: 8px;
  padding: 8px;
}
.absolBox .user p,
.absolBox .user h1,
.absoluteBox .user p,
.absoluteBox .user h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
  color: #fff;
}
.absolBox .user > div,
.absoluteBox .user > div {
  padding: 0;
}
.absolBox .user img,
.absoluteBox .user img {
  display: none;
}
.absolBox .title,
.absoluteBox .title {
  margin: 0;
}
.absolBox .title h1,
.absoluteBox .title h1 {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  line-height: 1.4;
  margin: 0;
}
.absolBox .title:has(h2),
.absoluteBox .title:has(h2) {
  background: #e2e2e2;
  padding: 8px;
}
.absolBox .title:has(h2) .infoCount,
.absoluteBox .title:has(h2) .infoCount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 4px;
}
.absolBox .title h2,
.absoluteBox .title h2 {
  line-height: 1.4;
  margin: 0;
  font-size: 13px;
  padding: 3px 0px;
}
.absolBox .title h2:has(p),
.absoluteBox .title h2:has(p) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.absolBox .title h2 span,
.absoluteBox .title h2 span {
  width: calc(100% - 70px);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.absolBox .title h2 p,
.absoluteBox .title h2 p {
  min-width: 50px;
  text-align: right;
}
.absolBox .title .greyBox h1,
.absoluteBox .title .greyBox h1 {
  text-shadow: none;
}

.absolBox .user {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
}
.absolBox .user p,
.absolBox .user h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
.absolBox .title {
  /* margin-top: 0; */
  /* margin-bottom: 0; */
  padding: 8px;
  margin: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
}
.absolBox .title h1,
.absolBox .title p {
  background: none;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
.absolBox .title h1 {
  line-height: 1.4;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.profile .flexColStart {
  gap: 4px;
}
.profile h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  color: #ffffff;
}
.profile h2 {
  font-size: 11px;
  font-weight: 400;
}
.profile .snsArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.profile .snsArea a img {
  margin: 0;
  width: 16px;
}
.profile .snsArea a img:hover {
  fill: #ca79fc;
}
.profile .followBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0 20px 0;
  margin: 0;
}
.profile .followBox li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  color: #fff;
}
.profile .followBox li p {
  font-size: 13px;
}
.profile .followBox li span {
  font-size: 16px;
  text-decoration: underline;
  font-weight: 500;
}
.profile .followBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}
.profile .followBtn button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #b041f3;
  background: rgba(230, 26, 254, 0.2);
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  height: 40px;
  width: 100%;
  gap: 4px;
}
.profile .followBtn button::before {
  content: '';
  width: 16px;
  height: 16px;
  background: url(/src/assets/images/profile/follow.svg) no-repeat center center;
}
.profile .followBtn button.donation::before {
  content: '';
  background: url(/src/assets/images/profile/donation.svg) no-repeat center center;
}
.profile .followBtn button.request::before {
  content: '';
  background: url(/src/assets/images/profile/request.svg) no-repeat center center;
}
.profile .followBtn .bottom {
  padding: 4px 0;
}
.profile .bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 14px;
}
.profile .bottom .rankingArea {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  min-width: 100px;
}
.profile .bottom .absoluteBox {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
}
.profile .bottom .absoluteBox h3 {
  font-weight: 500;
}

#whiteBtn:hover {
  background: inherit;
  color: inherit;
}

.info-area button {
  font-size: 12px;
  font-weight: 500;
}
.info-area button img {
  font-size: 12px;
  font-weight: 500;
  width: 20px;
}

.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0;
  background: url(/src/assets/images/common/bg-dash.png) repeat-x left center;
  padding: 20px 0;
  margin: 0 0 40px;
}
.ant-divider-horizontal.ant-divider-with-text span {
  background: #fff;
  font-size: 24px;
  padding: 0 12px;
  font-weight: 600;
  display: flex;
  gap: 12px;
  align-items: center;
}
.ant-divider-horizontal.ant-divider-with-text span img {
  height: 20px;
}
.ant-divider-horizontal.ant-divider-with-text::before {
  position: relative;
  width: 36px;
  height: 24px;
  border-block-start: 1px solid transparent;
  border-block-start-color: inherit;
  border-block-end: 0;
  content: '';
  margin-top: -22px;
  background: #fff url(/src/assets/images/profile/profile_01.svg) no-repeat 16px 0px;
}
.ant-divider-horizontal.ant-divider-with-text::after {
  width: 0;
}

.cautionBlue {
  display: flex;
  width: 100%;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--bg-sky, #eff8f9);
}
.cautionBlue h3 {
  display: flex;
  gap: 8px;
  color: var(--c-comment, #0d88d4);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  border-bottom: 1px solid #dfdce3;
  width: 100%;
  padding: 12px 16px;
  flex-direction: column;
  align-items: center;
}
.cautionBlue .box {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 16px 12px;
  gap: 8px;
}
.cautionBlue .box p {
  font-size: 12px;
}

#promptSales .titleBox,
.profileTitle,
#myHome .rsWrapper .promptBox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: url(/src/assets/images/common/bg-dash.png) repeat-x center;
  height: auto;
  line-height: 1;
  padding: 20px 0;
}
#promptSales .titleBox div,
.profileTitle div,
#myHome .rsWrapper .promptBox div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  gap: 0;
  padding: 0 12px;
  font-size: 20px;
  font-weight: 700;
}
#promptSales .titleBox div img,
.profileTitle div img,
#myHome .rsWrapper .promptBox div img {
  height: 24px;
}
#promptSales .titleBox div button,
.profileTitle div button,
#myHome .rsWrapper .promptBox div button {
  font-size: 14px;
  border-radius: 50px;
  padding: 4px;
  height: 24px;
  width: 24px;
  margin: 0;
}
#promptSales .titleBox div button:hover,
.profileTitle div button:hover,
#myHome .rsWrapper .promptBox div button:hover {
  border-radius: 50px;
  background: #e3e1e4;
  transition: all 0.2s ease;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}
#promptSales .titleBox div:has(img),
.profileTitle div:has(img),
#myHome .rsWrapper .promptBox div:has(img) {
  gap: 12px;
}

#promptSales .titleBox {
  margin-top: -24px;
}

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: none;
}

.profileList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 80px);
}
.profileList .list > div > img {
  width: 100%;
}

#myHome .rsWrapper > .box div {
  font-size: 20px;
}
#myHome > .top > .rsWrapper > .profile {
  padding: 12px;
}

#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox {
  padding: 0;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .user {
  width: 100%;
  margin-bottom: 0;
  padding: 4px 8px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 9.67px;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .user p,
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .user h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .user img {
  display: none;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .price {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  padding: 8px;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox div {
  justify-content: start;
}

#popularWrapper .rsWrapper .userBox .appraisal .classBadge .major {
  gap: 10px;
}
#popularWrapper .rsWrapper .userBox .appraisal .classBadge .major img {
  height: 24px !important;
  width: 24px !important;
}

#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child p,
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child h1,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child p,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child p,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child p {
  width: fit-content;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child p p,
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child p h1,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child p p,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child p h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}

.contentsList {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.contentsList .contents {
  margin: 0;
}

#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents {
  margin: 0;
}

.rankBox .absolBox,
.rankBox .absoluteBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rankBox .absolBox .flexRowBetween,
.rankBox .absoluteBox .flexRowBetween {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 8px;
  padding: 0 8px;
  justify-content: space-between;
}
.rankBox .absolBox .flexRowBetween .user,
.rankBox .absoluteBox .flexRowBetween .user {
  padding: 16px 0;
  background: none;
}
.rankBox .absolBox .flexRowBetween .user p,
.rankBox .absolBox .flexRowBetween .user h1,
.rankBox .absoluteBox .flexRowBetween .user p,
.rankBox .absoluteBox .flexRowBetween .user h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
.rankBox .absolBox h1,
.rankBox .absoluteBox h1 {
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.rankBox .absolBox .priceBox,
.rankBox .absoluteBox .priceBox {
  padding: 16px;
  justify-content: flex-end;
  align-items: flex-end;
}

.comment {
  color: #0d88d4 !important;
}

header {
  box-shadow: inset 0 -1px 0px 0px rgb(230, 230, 230);
}

#header .rsWrapper .userWrapper .btnArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-top: 8px;
}
#header .rsWrapper .userWrapper .userInfo {
  padding: 16px 8px;
}
#header .rsWrapper .iconBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  gap: 20px;
}
#header .rsWrapper .iconBox:has(.pre) {
  gap: 8px;
}
#header .rsWrapper .iconBox .pre {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid #e2dfe4;
  padding: 8px 12px;
  border-radius: 8px;
  height: 36px;
  margin-left: 4px;
  font-weight: 600;
  text-wrap-mode: nowrap;
  cursor: pointer;
}
#header .rsWrapper .iconBox .pre img {
  margin: 0;
  width: 16px;
  height: 16px;
}
#header .rsWrapper .iconBox .pre:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
#header .rsWrapper .iconBox .pre.member {
  border-color: #c718e2;
  color: #c718e2;
}

.rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .flexRowCenter {
  width: auto;
}
.rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .flexRowCenter img {
  display: none;
}
.rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .close {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .close img {
  width: 72%;
  height: 72%;
}

.profileSection .rsWrapper.flexColCenter {
  width: 100%;
  margin: 0;
  min-width: 320px;
}
.profileSection .rsWrapper.flexColCenter #myHome .promptThumList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1600px;
  padding: 24px 0 64px;
  gap: 28px;
}
.profileSection .rsWrapper.flexColCenter #myHome .promptThumList #populerPromptSlider {
  margin-bottom: 12px;
}
.profileSection .rsWrapper.flexColCenter #myHome .top .anticon {
  font-size: 16px;
}
.profileSection .rsWrapper.flexColCenter #myHome .top .ant-rate .ant-rate-star:not(:last-child) {
  margin: 0;
}
.profileSection .rsWrapper .ant-row div:first-child {
  width: 100%;
}

.rsWrapper .listBox .gerneBox .absolBox div .user,
.rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child,
.rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 8px;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 8px;
}
.rsWrapper .listBox .gerneBox .absolBox div .user img,
.rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child img,
.rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child img {
  display: none;
}

.rsWrapper .info div .contentsBox .musicBox .music > .user,
.rsWrapper .thumbBox .absolBox > div > div {
  width: auto;
}
.rsWrapper .info div .contentsBox .musicBox .music > .user img,
.rsWrapper .thumbBox .absolBox > div > div img {
  display: none;
}
.rsWrapper .info div .contentsBox .musicBox .music > .user p,
.rsWrapper .info div .contentsBox .musicBox .music > .user h1,
.rsWrapper .thumbBox .absolBox > div > div p,
.rsWrapper .thumbBox .absolBox > div > div h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}

#popularSlider .list > div .absoluteBox .user {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
  padding-left: 0;
  height: 36px;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 1.11%, rgba(0, 0, 0, 0) 100%);
}
#popularSlider .list > div .absoluteBox .user p,
#popularSlider .list > div .absoluteBox .user h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
#popularSlider .list > div .absoluteBox .user div {
  height: auto;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 8px;
}
#popularSlider .list > div .absoluteBox .user div img {
  display: none;
}
#popularSlider .list > div .absoluteBox .user .aiBox p,
#popularSlider .list > div .absoluteBox .user .aiBox h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}

.contentsList .absoluteBox .flexRowStart img {
  display: none;
}

.absoluteBox .title .greyBox .imgBox .ant-rate {
  font-size: 14px;
}
.absoluteBox .title .greyBox .imgBox .ant-rate.ant-rate .ant-rate-star:not(:last-child) {
  margin-inline-end: 0px;
}

#otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox .user img {
  display: none;
}

@media (max-width: 1280px) {
  #musicWrapper .rsWrapper > div:first-child {
    flex-direction: row;
  }
}
#musicWrapper .filter .listBox {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: start;
}

.thumbBox .absolBox > div {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
}

.thumbBox .absolBox .name {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
}

.ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

#aiWrapper > h1 span {
  font-size: 48px;
}

.mainIcon {
  margin: -100px 78px -20px 0;
  width: 136px;
}

#newSlider .musicBox {
  padding: 10px;
}
#newSlider .musicBox div:first-child {
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

#top10 #newSlider .slick-list {
  height: 290px;
}
#top10 #newSlider .slick-center {
  width: 280px;
}
#top10 #newSlider .slick-current,
#top10 #newSlider .slick-current.slick-active:hover {
  transform: scale(1.57895);
}
#top10 #newSlider .slick-active:hover {
  transform: scale(1.05);
}
#top10 #newSlider #active {
  cursor: pointer;
}
#top10 #newSlider #active img {
  content: url(/src/assets/images/common/play_icon_top.png);
}

.titleBox .noMore:hover {
  cursor: default;
}
.titleBox .search {
  border-radius: 4px;
  border: 1px solid var(--b-input, #e2dfe4);
}
.titleBox .search:has(.ant-input-outlined):focus,
.titleBox .search:has(.ant-input-outlined):focus-within {
  border: 1px solid #c718e2;
  background: #fff !important;
}
.titleBox .search:has(.ant-input-outlined):hover {
  background: inherit;
}
.titleBox .search .ant-input-outlined {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}
.titleBox .search .ant-input-outlined img {
  margin: 0;
}
.titleBox .search .ant-input-outlined:hover {
  background: inherit;
}
.titleBox .search .ant-input-affix-wrapper .ant-input-clear-icon {
  font-size: 20px;
}

.searchBox > div:last-child div {
  gap: 12px;
}

#popularWrapper .rankBox .rsWrapper .thumbnail .absoluteBox {
  padding: 0;
}

#myProfile > .rsWrapper.flexColCenter {
  max-width: 1520px;
  justify-content: center;
  margin: auto;
  min-width: 200px;
}
#myProfile > .rsWrapper.flexColCenter + .ant-form {
  width: 100%;
}
#myProfile .myInfo .content .box {
  column-gap: 40px;
}
#myProfile .myInfo .content .box .noResult {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #f7f7f8;
  border-radius: 8px;
  padding: 20px;
  color: #6d6c75;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  height: 140px;
}
#myProfile .myInfo .content .box .thumb + .infoBox .box2 .info {
  width: calc(50% + 90px);
}
#myProfile .myInfo .content .box .thumb + .infoBox .box2 .info:first-child {
  width: calc(50% - 53px);
}
#myProfile .myInfo .content .box .thumbUpdate {
  width: 140px;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-form-item {
  padding: 0;
  width: 140px;
  height: 140px;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-form-item .ant-upload-btn {
  padding: 0;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-wrapper {
  border-radius: 4px;
  overflow: hidden;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover,
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-wrapper .ant-upload-drag-hover:not(.ant-upload-disabled),
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-wrapper .ant-upload-drag {
  border-color: transparent;
  background: transparent;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-form-item-control {
  display: flex;
  justify-content: center;
  align-items: center;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-form-item-control .ant-form-item-control-input {
  align-items: flex-start;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-drag {
  background: transparent;
  border: 0;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-drag-container {
  position: relative;
  width: 100%;
  height: 100%;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-drag-container .imageContainer {
  width: 100%;
}
#myProfile .myInfo .content .box .profileUpload,
#myProfile .myInfo .content .box .ant-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
}
#myProfile .myInfo .content .infoBoxUpdate {
  width: 100%;
  padding: 0;
  gap: 12px;
}
#myProfile .myInfo .content .infoBoxUpdate img {
  border-radius: 16px;
}
#myProfile .myInfo .info .ant-image {
  width: 100%;
  margin: 0;
  border: none;
  background: transparent;
}
#myProfile .myInfo .info h1 + div {
  width: 100%;
  margin: 0;
  border: none;
  background: transparent;
}
#myProfile .myInfo .info h1 + div .ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 0;
}
#myProfile .myInfo .info .plusBannerBox .ant-form-item,
#myProfile .myInfo .info .plusBannerBox .ant-upload-drag-container {
  width: 100%;
  height: 100%;
}
#myProfile .myInfo .info .plusBannerBox .ant-form-item .imageContainer,
#myProfile .myInfo .info .plusBannerBox .ant-upload-drag-container .imageContainer {
  position: relative;
  display: flex;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* min-height: 150px; */
  max-height: 300px;
  overflow: hidden;
}
#myProfile .myInfo .info .plusBannerBox .ant-form-item .ant-form-item-control,
#myProfile .myInfo .info .plusBannerBox .ant-form-item .ant-form-item-control-input,
#myProfile .myInfo .info .plusBannerBox .ant-upload-drag-container .ant-form-item-control,
#myProfile .myInfo .info .plusBannerBox .ant-upload-drag-container .ant-form-item-control-input {
  height: min-content;
}
#myProfile .myInfo .info .plusBannerBox .plus {
  margin: 20px 0 4px;
}
#myProfile .myInfo .about_me {
  display: flex;
  padding: 4px 0px;
  align-items: flex-start;
  gap: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  min-height: 30px;
}
#myProfile .userInfo .content .box .info > .rowBox:not(.ant-select-selector) {
  justify-content: start;
}
#myProfile .userInfo .content .box .info div p {
  color: inherit;
}

.userInfo .box:last-child {
  margin-bottom: 0;
}
/* .userInfo .info span {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */
.userInfo .info .ant-select-selection-wrap {
  flex-direction: row;
}

h1 {
  font-weight: 600;
}

.content:has(.flexColStart.box) {
  gap: 0;
}
.content:has(.flexColStart.box) .flexColStart.box {
  gap: 0;
}

.noResult {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #f7f7f8;
  border-radius: 8px;
  padding: 20px;
  color: #6d6c75;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
}

.communitySection .rsWrapper.flexColCenter {
  padding-bottom: 20px;
}

#depth2 .titleBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;
}
#depth2 .titleBox .title {
  padding: 0;
}

.ant-layout {
  width: 100%;
  padding: 0;
  margin: auto;
  margin-bottom: 64px;
}
.ant-layout .aui-grid {
  width: 100%;
}

img,
.ant-card-bordered {
  border: 0;
}

.sun-editor .se-wrapper .sun-editor:focus {
  border-color: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}

/* radio */
.ant-checkbox,
.ant-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
}
.ant-checkbox *,
.ant-radio-group * {
  text-wrap-mode: nowrap;
}

.ant-radio-wrapper {
  /* RATE */
}
.ant-radio-wrapper span {
  text-wrap-mode: nowrap;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-color: transparent;
  width: 20px;
  height: 20px;
}
.ant-radio-wrapper .ant-radio-checked:hover .ant-radio-inner {
  border-color: #ec15ff;
}
.ant-radio-wrapper .ant-radio-inner {
  width: 20px;
  height: 20px;
}

.ant-radio-wrapper:hover .ant-checkbox-wrapper-disabled .ant-racheckboxdio-inner,
.ant-radio-wrapper:hover .ant-checkbox-wrapper-disabled .ant-checkbox-innerr::after,
.ant-radio-wrapper .ant-radio-checked:has([disabled]) .ant-radio-inner,
.ant-radio-wrapper .ant-radio-checked:has([disabled]) .ant-radio-innerr::after,
.ant-radio-wrapper:hover .ant-radio-disabled .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio-disabled .ant-radio-innerr::after,
.ant-radio-wrapper .ant-radio-disabled .ant-radio-checked + input:disabled + .ant-radio-inner,
.ant-radio-wrapper .ant-radio-disabled .ant-radio-checked + input:disabled + .ant-radio-inner::after,
.ant-radio-wrapper .ant-radio-disabled .ant-radio-checked + input:disabled:hover + .ant-radio-inner::after {
  border-color: #dfdce4 !important;
  background: #dfdce4;
}

/* checkbox */
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #c718e2;
}

.ant-checkbox .ant-checkbox-inner:after {
  top: 43%;
  inset-inline-start: 25%;
  display: table;
  width: 6.714286px;
  height: 12.142857px;
  border: 3px solid #fff;
  border-top: 0;
  border-inline-start: 0;
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
}

.profileSection .ant-form {
  width: 1600px;
  gap: 10px;
  padding: 20px 40px;
}
.profileSection .ant-row:has(.thumbUpdate) {
  padding: 0;
}
.profileSection .ant-form-item {
  margin: 0;
}
.profileSection .ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  padding: 0;
  gap: 24px;
}
.profileSection .ant-row .title {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: start;
  color: #3e3d53;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  gap: 12px;
  margin: 0;
}
.profileSection .ant-row .title::before {
  background: url(/src/assets/images/profile/profile_01.svg) no-repeat left center;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.fillter + div .titleBox .ant-layout-header {
  display: flex;
  height: 71px;
  padding-bottom: 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.fillter + div .titleBox .ant-input-outlined:focus,
.fillter + div .titleBox .ant-input-outlined:focus-within {
  border-color: #c718e2;
  box-shadow: none;
  background-color: inherit;
}
.fillter + div .titleBox .ant-input-outlined {
  background: inherit;
  border-width: 0;
}
.fillter + div .titleBox .ant-input-outlined:focus,
.fillter + div .titleBox .ant-input-outlined:focus-within {
  box-shadow: none;
}

.ant-input-outlined {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 13px;
  border-radius: 4px;
  border: 1px solid var(--b-input, #e2dfe4);
  background: #f7f7f8;
}
div:has(> .textArea:hover) {
  background: #fff;
  border: 1px solid #e94cf7;
}

div:has(> .textArea:focus) {
  background: #fff !important;
  border: 1px solid #e94cf7;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  display: flex;
  height: 40px;
  padding: 4px 8px 4px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--c-selectLine, #dfdce4);
  background: var(--bg-input, #ffffff);
}

.ant-select .ant-select-arrow {
  margin-top: -12px;
  width: 24px;
  height: 24px;
}
.ant-select .ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
  background: url(/src/assets/images/common/arr_down.svg) no-repeat center;
  width: 24px;
  height: 24px;
}
.ant-select .ant-select-arrow .anticon svg {
  display: none;
}

.ant-select-selector:hover {
  background: #fff !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 500;
  background-color: #fdf2ff;
}

.ant-select-dropdown .ant-select-item {
  min-height: 40px;
  padding-top: 10px;
}

.ant-select-dropdown {
  padding: 0;
  border-radius: 4px;
  border: 1px solid #c718e2;
  background: #fff;
}

.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #c718e2;
  box-shadow: none;
  background: #fff;
  outline: 0;
}

#builder div .content .layout .ant-layout div div .input:focus + button {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}

#builder div .content .layout .selectWrapper .ant-select,
.ant-select-single {
  height: 40px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item:has(.ant-upload-list-item-thumbnail) {
  padding: 0;
  border: 0;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-image-preview-root .ant-image-preview-img-wrapper {
  border-radius: 16px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before {
  background-color: transparent;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload-btn:has(.ant-upload-drag-container > p) {
  justify-content: start;
  align-items: start;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-btn:has(.ant-upload-drag-container > p) {
  justify-content: start;
  align-items: start;
  padding: 4px 0;
}
/* .ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container,
.ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container > div {
  gap: 4px;
} */
.plus span {
  width: 24px;
  height: 24px;;
  background:url(/src/assets/images/common/i-upload.svg) no-repeat center;
}
.plus span svg {
  display: none;
}
/* .ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container > p {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #0d88d4 !important;
} */
.ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container > p {
  display: flex;
  align-items: center;
  gap: 5px;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container > p::before {
  background: url(/src/assets/images/common/btn-down.svg) no-repeat center;
  content: '';
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container > p button {
  background: url(/src/assets/images/common/icon_close.svg) no-repeat center;
  content: '';
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item::before {
  width: 100%;
  height: 100%;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-download,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-download,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-delete,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-delete {
  color: #0e0e1f;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
  color: #ffffff;
  background: #c718e2;
}

.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
  color: #000;
  border-color: #e2dfe4;
}

.ant-switch .ant-switch-handle::before {
  border-radius: 100%;
}

.ant-switch:hover:not(.ant-switch-disabled),
.ant-switch {
  background: #0d88d4;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  color: #eee;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}

.ant-switch .ant-switch-inner .ant-switch-inner-checked {
  margin-inline-start: calc(-100% + 22px - 56px);
  margin-inline-end: calc(100% - 22px + 56px);
}

.ant-tree .ant-tree-switcher,
.ant-tree .ant-tree-checkbox {
  margin-inline-end: 0px;
}

.ant-tree .ant-tree-indent-unit{
  width: 6px;
  line-height: 2.8;
}

.ant-tree .ant-tree-switcher{
  width: 12px;
  line-height: 2.8;
}

.ant-tree .ant-tree-switcher:before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.ant-tree .ant-tree-switcher.ant-tree-switcher-noop::before {
  background: url(/src/assets/images/common/i-doc.svg) no-repeat center;
}

.ant-tree .ant-tree-treenode-selected .ant-tree-switcher.ant-tree-switcher-noop::before {
  background: url(/src/assets/images/common/i-docW.svg) no-repeat center;
}
.ant-tree .ant-tree-treenode-selected svg {
  fill: #fff;
}

.ant-tree .ant-tree-treenode {
  line-height: 36px;
  color: #0e0e1f;
  font-weight: 400;
}

.ant-btn-variant-text:not(:disabled):not(.ant-btn-disabled):hover,
.ant-tree .ant-tree-switcher:not(.ant-tree-switcher-noop):hover:before {
  background-color: transparent;
}

.ant-tree .ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 14px;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: none;
}

.ant-tree-treenode-selected,
.ant-tree-treenode-selected:hover,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:hover:before {
  background: linear-gradient(90deg, #ca79fc 0%, #ff5eb6 100%);
  color: #fff;
  font-weight: 500;
}
.ant-tree-treenode-selected span,
.ant-tree-treenode-selected:hover span,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:before span,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:hover:before span {
  color: #fff;
}
.ant-tree-treenode-selected span svg,
.ant-tree-treenode-selected:hover span svg,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:before span svg,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:hover:before span svg {
  fill: #fff;
}

.ant-tree .ant-tree-treenode:before {
  display: none;
}

.ant-tree .ant-tree-treenode {
  margin-bottom: 0;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:hover {
  color: #fff;
}

.ant-tree .ant-tree-node-content-wrapper {
  height: 34px;
  line-height: 2.8;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}

.ant-tree .ant-tree-treenode:not(.ant-tree-treenode-disabled) .ant-tree-node-content-wrapper:hover {
  color: #0e0e1f;
  background-color: transparent;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent !important;
  color: #fff !important;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected.ant-tree-treenode svg {
  fill: #fff;
}

.ant-tree .ant-tree-node-content-wrapper {
  padding-inline: 4px;
}
/* .ant-input-outlined:hover, */
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  border-color: #c718e2;
  background-color: #ffffff;
  box-shadow: none;
  font-size: 13px;
}

textarea.ant-input-outlined:focus + button {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%) !important;
  color: #fff;
}

input:-internal-autofill-selected.ant-input-outlined,
input:-internal-autofill-selected {
  background-color: #fff !important;
}

.ant-tree .ant-tree-treenode {
  align-items: center;
}

.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):active,
.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):active {
  color: #fff;
  border-color: #e94cf7;
  background: #ffffff;
}

.boardsSection .title {
  margin: 0 16px;
  padding: 20px 0;
  width: calc(100% - 32px);
}
.boardsSection .title .titleBox::before {
  width: 20px;
  height: 20px;
  background-size: 20px;
  content: '';
}
.boardsSection .title .searchBox.none::before {
  width: 24px;
  height: 24px;
  background-size: 18px;
  content: '';
  background: url(/src/assets/images/common/search-solid.svg) no-repeat center;
  fill: #525162;
  display: none;
}
.boardsSection .boardWrapper .searchBox .result .searchCount {
  gap: 12px !important;
  font-size: 16px !important;
  font-weight: 700;
}
.boardsSection .boardWrapper .searchBox .result .searchCount span {
  font-size: 13px;
}
.boardsSection .boardWrapper .searchBox .btn {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
}
.boardsSection .boardWrapper .searchBox .btn-search {
  width: 32px;
  height: 32px;
  content: '';
  background: url(/src/assets/images/common/i-search.svg) no-repeat center/18px;
  fill: #525162;
  border-radius: 4px;
  display: none;
}
.boardsSection .boardWrapper .searchBox .btn-search::hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
.boardsSection .boardWrapper .searchBox .btn .search {
  display: flex;
  position: absolute;
  left: -14px;
  right: -14px;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  padding: 8px;
  top: 8px;
  z-index: 2;
}
.boardsSection .boardWrapper .searchBox form .inputText {
  width: calc(100% - 120px - 40px - 24px);
}
.boardsSection .boardWrapper .searchBox form .inputText .ant-row div:first-child {
  width: 100%;
}

.comment-system .ant-list-items {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 24px;
}

.repleBox .ant-comment-nested div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 20px;
}
.repleBox .ant-comment .comment-date {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 16px;
}

.aui-grid-content-panel-mask:has(+ .aui-grid-left-main-panel) .aui-grid .aui-grid-default-header:first-child {
  border-radius: 0 8px 8px 0 !important;
}

@media (max-width: 1100px) {
  #mypage .notice-area {
    width: 100%;
  }
  #mypage .rsWrapper {
    padding: 0;
    width: 100%;
  }
  #mypage .rsWrapper .aui-grid {
    width: 100% !important;
  }
}
@media (max-width: 900px) {
  #builder div .content {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: start;
  }
  #builder div .content .builderGuide h1 {
    font-size: 36px;
  }
  #builder div .content .builderGuide h2 {
    font-size: 20px;
  }
  #builder div .content .builderGuide p img {
    width: 100%;
  }
  #builder div .content:has(.builderGuide) .mobileTab {
    display: none;
  }
  #builder div .content .layout .ant-layout .ant-layout-has-sider {
    padding: 28px 0 0 !important;
    border: 0;
  }
  .myMarketplacesSection #otherWrapper #listWrapper .rsWrapper > div .listBox .staffBox,
  #otherWrapper .rsWrapper > div .content .listBox .list {
    width: calc(50% - 0px);
    margin: 0px 10px 4px 0;
  }
  .myMarketplacesSection #otherWrapper #listWrapper .rsWrapper > div .listBox .staffBox,
  #otherWrapper .rsWrapper > div .content .listBox .staffBox :nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 800px) {
  #myProfile .userInfo .content .box .info:first-child > .rowBox > .mobileInput {
    width: 100%;
  }
  #myProfile .userInfo .content .box .info > .rowBox #darkGrey {
    width: 100%;
  }
  #myProfile .myInfo .content .box .thumbUpdate {
    width: 100%;
  }
  #myProfile .myInfo .content .box .thumbUpdate .plusBox {
    width: 100%;
    height: 140px;
  }
  #myProfile .myInfo .content .box .infoBox .box2:last-child,
  #myProfile .myInfo .content .box .infoBoxUpdate .box2:last-child {
    flex-direction: column;
  }
  .ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 0;
  }
  .profileSection .ant-form-item {
    width: 100%;
  }
  #myProfile .rowBox .ant-form-item {
    flex: 1;
  }
  #myProfile .userInfo .content .box .info > .rowBox .ant-select {
    width: 100%;
  }
  .profileSection .rsWrapper .ant-row div:first-child {
    margin: 0 !important;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList {
    width: 100%;
    padding: 0;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList > div {
    width: 100%;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList #myProfile .myInfo .content .box .thumb .flexRowBetween {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList #myProfile .myInfo .content .box .thumb .flexRowBetween .flexRowBetween {
    width: auto;
    justify-content: center;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList #myProfile .myInfo .content .box .thumb {
    margin: 0;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList .slider-container .slick-track {
    display: flex;
    flex-direction: row;
    /* width: 100% !important; */
  }
  #myProfile .myInfo .content .box .thumb + .infoBox .box2 .info:first-child,
  #myProfile .myInfo .content .box .thumb + .infoBox .box2 .info:last-child {
    width: 100%;
  }
  #myProfile .myInfo .content .box .infoBox .box2:last-child {
    flex-direction: column;
  }

  #musicBox.player {
    height: 100vh;
  }
  #musicBox .listBox {
    height: inherit;
  }
  #builder div .content .layout .ant-layout main > .imgThum {
    height: 130px !important;
    border-radius: 8px;
  }
}
@media (max-width: 700px) {
  .badge_s {
    padding: 1px 3px;
    height: 23px !important;
    /* min-width: 43px; */
    font-size: 10px;
    line-height: 0.5;
  }
  #root:has(section.userPage) + div .ant-btn-default.btn-dark,
  .ant-modal-root .ant-btn-default.btn-dark,
  .userPage .ant-btn-default.btn-dark {
    font-size: 14px;
    height: 32px;
    min-width: 72px;
    font-weight: 500;
  }
  .profileSection .ant-form {
    width: 100%;
    padding: 0;
  }
  #myHome .filter {
    border: none;
  }
  #myHome .filter .rsWrapper .listBox .title img {
    margin: 0;
  }
  #builder > div .tab {
    padding: 0;
    border-bottom: none;
  }
  #otherWrapper > div .tab {
    justify-content: flex-start;
    border: none;
  }
  #otherWrapper > div .tab button {
    padding: 12px;
    width: auto !important;
    min-width: 80px;
    height: 32px !important;
  }
  .form .info-area .content .box.third .half input {
    height: 32px;
  }
  .ant-form-item .ant-form-item-control-input {
    min-height: auto;
  }
  .comment-system {
    gap: 0px !important;
  }
  .comment-system h2 {
    display: none !important;
  }
  .comment-system .repleWriteArea * {
    min-height: 72px;
  }
  .comment-system .repleBox > .ant-comment {
    border-radius: 20px 20px 16px 20px !important;
    gap: 10px !important;
    padding: 10px !important;
  }
  .ant-comment .ant-comment-inner {
    padding-block: 0;
  }
  #board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner {
    gap: 8px;
  }
  #board-detail .repleBox .ant-comment-avatar .ant-avatar,
  #board-detail .repleBox .ant-comment-avatar .ant-avatar img {
    width: 32px;
    height: 32px;
  }
  #builder div .content .builderGuide h1 {
    font-size: 24px;
  }
  #builder div .content .builderGuide h2 {
    font-size: 16px;
  }
  #builder div .content .builderGuide p img {
    width: 100%;
  }
  #builder div .content:has(.builderGuide) .mobileTab {
    display: none;
  }
  .ranking_s {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    gap: 12px;
    cursor: pointer;
    font-size: 14px;
    min-width: 100px;
    gap: 4px;
    min-width: 34px;
    font-size: 12px;
    height: 18px;
  }
  .ranking_s::after {
    background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
    width: 20px;
    height: 20px;
    content: '';
  }
  .ranking_s::after {
    background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
    width: 16px;
    height: 16px;
    content: '';
  }
  .ranking_s.music::before {
    background: url(/src/assets/images/common/i-rankMusic.svg) no-repeat center/14px;
    width: 14px;
    height: 14px;
    content: '';
  }
  .ranking_s.image::before {
    background: url(/src/assets/images/common/i-rankImage.svg) no-repeat center/14px;
    width: 14px;
    height: 14px;
    content: '';
  }
  .ranking_s.video::before {
    background: url(/src/assets/images/common/i-rankVideo.svg) no-repeat center/14px;
    width: 14px;
    height: 14px;
    content: '';
  }
  .ranking_s.text::before {
    background: url(/src/assets/images/common/i-rankText.svg) no-repeat center/14px;
    width: 14px;
    height: 14px;
    content: '';
  }
  .ranking_s::after {
    background: url(/src/assets/images/common/btn-downB.svg) no-repeat center 0/14px;
    width: 14px;
    height: 14px;
    content: '';
    background-size: 12px;
  }
  .myMarketplacesSection #prompt {
    padding: 0 16px;
  }
  .myMarketplacesSection #prompt #otherWrapper {
    padding: 0;
  }
  .myMarketplacesSection #prompt #otherWrapper > div .tab {
    border-bottom: solid 2px #000;
    padding: 0 0 16px;
  }
  .myMarketplacesSection #prompt #otherWrapper .rsWrapper {
    padding: 0;
  }
  .myMarketplacesSection #prompt #otherWrapper .rsWrapper > div.listBox .staffBox {
    width: calc(50% - 2px);
    margin: 0 4px 4px 0;
  }
  .myMarketplacesSection #prompt #otherWrapper .rsWrapper > div.listBox .staffBox:nth-child(2n) {
    margin-right: 0;
  }
  .myMarketplacesSection #prompt #otherWrapper .rsWrapper > div.listBox .staffBox.top {
    padding: 30px 0;
    height: 127px;
  }
  #builder > div .tab #pinkBtn2 {
    height: 32px;
  }
  #promptSales .titleBox {
    margin-top: -24px;
    padding: 0;
  }
  .ant-select .ant-select-arrow {
    margin-top: -16px;
    width: 24px;
    height: 24px;
  }
  .button-group {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    padding: 0;
  }
  .button-group button,
  .button-group .ant-btn-default.btn-dark {
    height: 40px;
    width: 100%;
    margin-top: 0;
  }
  #board-detail .board-detail-wrapper .comment-system .ant-comment .repleWriteArea textarea {
    padding: 8px 12px;
    font-size: 13px;
  }
  .userPage .ant-btn-default.btn-gray2 {
    min-width: 72px;
  }
  #board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .btnArea {
    padding: 0;
    gap: 0;
  }
  #board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content {
    padding: 0 !important;
  }
  #board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox > .ant-comment .repleBox .ant-comment {
    padding: 0 !important;
  }
  .ant-modal-root .ant-modal-footer {
    padding: 16px 16px 24px;
  }
  .ant-modal-root .p20 .ant-modal-footer button {
    width: 100%;
  }
  .ant-modal-root .p20 .ant-modal-body .term {
    padding: 16px 0;
  }
  .cautionBlue {
    padding: 0px;
  }
  .ant-divider-horizontal.ant-divider-with-text {
    padding: 0px;
  }
  .mypageSection .ant-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }
}

.ant-tree,
.ant-popover,
.ant-collapse > .ant-collapse-item > .ant-collapse-header,
.ant-tooltip,
.sun-editor-editable,
.ant-layout,
.ant-menu,
.ant-tree,
.ant-select,
.ant-card,
[class^='ant-modal'],
.ant-collapse-item,
.ant-checkbox-wrapper,
.ant-radio-wrapper .ant-radio,
.ant-input-number .ant-input-number-input,
.ant-input-number,
.ant-upload-wrapper,
.ant-radio-wrapper,
.ant-form-item,
.ant-row,
.ant-radio-group,
.ant-layout,
.ant-layout-sider,
.ant-form,
.ant-btn,
.ant-dropdown,
.ant-dropdown-menu-submenu,
[class^='ant-image'],
[class*=' ant-image'],
.ant-list,
.ant-comment,
.ant-input,
.ant-col {
  font-family:
    'Pretendard Variable',
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    'Helvetica Neue',
    'Segoe UI',
    'Apple SD Gothic Neo',
    'Noto Sans KR',
    'Malgun Gothic',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    sans-serif;
}

.ant-input-number-outlined {
  border-width: 0;
  background: transparent;
}
.ant-input-number-outlined input {
  text-align: right;
}

.ant-input-number-outlined:focus,
.ant-input-number-outlined:focus-within {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}

.noResult * {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #a3a1a7;
  text-align: center;
  margin-top: 0px;
  background: transparent url(/src/assets/images/common/help_icon.svg) no-repeat left center/30px;
  padding: 10px 0 10px 52px;
  min-height: 120px;
}

.ant-input-affix-wrapper::before {
  display: none;
}

.none {
  display: none !important;
}
.box16 {
  border-radius: 16px;
  overflow: hidden;
}
.custom-checkbox .ant-checkbox-inner {
  border-color: #d9d9d9;
  background: rgba(0, 0, 0, 0.04);
}

.custom-checkbox .ant-checkbox-input {
  cursor: not-allowed;
}
