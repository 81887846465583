/* Ai등록모달 등록영역 */
.modal .modalAi {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  border-radius: 8px;
  padding: 20px 24px;
  background-color: #fff;
}
.modalAi .modalAi__headerArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}
.modalAi .modalAi__wrap {
  height: 100%;
  overflow: scroll;
}
.modalAi .modalAi__addArea {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.05);
}
.modalAi .modalAi__addArea p {
  font-size: 12px;
  color: #464548;
}
.modalAi .modalAi__addArea button {
  width: 120px;
  border-radius: 24px;
  padding: 12px;
}
.modalAi .modalAi__addArea button.btn-reset {
  border: 1px solid #aaa;
  background: #fff;
}
.modalAi .modalAi__addArea .optBox {
  display: flex;
  gap: 20px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #c2b8cb;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
/* Ai등록모달 등록영역 스크린샷 이미지첨부 */
.modalAi .modalAi__addArea .optBox .add p {
  margin-bottom: 12px;
}
.modalAi .modalAi__addArea .optBox .add form div {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 124px;
  height: 124px;
  border-radius: 4px;
  background-color: #f3f3f3;
}
.modalAi .modalAi__addArea .optBox .add form div span {
  border: 0 !important;
}

/* Ai등록모달 등록영역 스크린샷 옵션 */
.modalAi .modalAi__addArea .optBox ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 3;
}
.modalAi .modalAi__addArea .optBox ul li {
  display: flex;
  align-items: center;
}
.modalAi .modalAi__addArea .optBox ul li p {
  min-width: 100px;
}
.modalAi .modalAi__addArea .optBox ul li .datepicker {
  display: flex;
  gap: 10px;
  width: 100%;
}
.modalAi .modalAi__addArea .optBox ul li .datepicker .ant-picker {
  flex: 1;
}
.modalAi .modalAi__addArea .btnBox {
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
  padding: 0;
}
/* Ai등록모달 그리드영역  */
.modalAi .modalAi__girdArea .searchBox {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 0;
}
.modalAi .modalAi__girdArea .searchBox button {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.modalAi .modalAi__girdArea .searchBox .select {
  margin-left: auto;
}
.modalAi .modalAi__girdArea .searchBox .select > div {
  width: 120px;
}
/* 반응형 */
@media (max-width: 700px) {
  .modal .modalAi {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0 16px 30px 16px;
  }
  .modalAi .modalAi__headerArea {
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    padding: 18px 0;
    font-size: 26px;
  }
  .modalAi .modalAi__headerArea p {
    order: 2;
  }
  .modalAi .modalAi__headerArea button {
    order: 1;
    align-self: flex-end;
  }
  .modalAi .modalAi__addArea .optBox {
    flex-direction: column;
  }
  .modalAi .modalAi__addArea .optBox .add form div {
    width: 100%;
  }
  .modalAi .modalAi__addArea .optBox ul {
    gap: 20px;
  }
  .modalAi .modalAi__addArea .optBox ul li {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
  .modalAi__girdArea {
    margin-top: 20px;
  }
  .modalAi .modalAi__girdArea .searchBox {
    justify-content: start;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 12px;
    padding: 0;
    margin-bottom: 20px;
  }
  .modalAi .modalAi__girdArea .searchBox .ant-picker {
    width: calc(51% - 40px);
  }
  .modalAi .modalAi__girdArea .searchBox .select {
    width: 100%;
    margin-left: 0;
  }
  .modalAi .modalAi__girdArea .searchBox .select > div {
    width: 100%;
  }
  body:has(.modalAi) .ant-tooltip .ant-tooltip-inner {
    min-height: auto;
    padding: 4px;
    font-size: 10px;
  }
  /* design.css 영향 받는 부분 */
  #myPage .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector,
  #myPage .ant-select-single {
    height: auto;
  }
  /* 그리드 영역 */
  .modalAi .aui-grid-default-header .aui-grid-renderer-base {
    font-size: 13px;
  }
  .modalAi .aui-grid-default-column .aui-grid-renderer-base{
    font-size: 11px;
  }
  .modalAi .aui-grid-default-column .certStat {
    width: auto;
    font-size: 10px;
  }
}

/* 팔로우 리스트 모달, followBox를 부모로 삼음 */
.modalFol {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.modalFol .modal-content {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.modalFol .modal-content .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.modalFol .modal-content .title h2 {
  font-size: 24px;
  font-weight: 600;
}
.followBox .modal-content .data {
  max-height: 320px;
  overflow-y: auto;
}
.certStat {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background-color: #ccc;
  width: 76px;
  color: #fff;
}
.state01 {
  background-color: #525162;
}
.state02 {
  background-color: #0fc9f3;
}
.state91 {
  background-color: #f8c930;
}
.state99 {
  background-color: #fe393c;
}

/* 반응형 */
@media (max-width: 700px) {
  /* 팔로워 확인 모달 */
  .modalFol {
    position: fixed;
    top: 0;
    left: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
  }
  .modalFol .modal-content {
    width: 90%;
  }
  .modalFol .modal-content .data {
    max-height: 90%;
  }
}
