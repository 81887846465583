.loginSection .up {
  display: none;
}
#login {
  width: 580px;
  padding: 20px 70px;
}
#login .top {
  margin: 0 0 20px;
  background: #ffffff;
  padding: 0;
}
#login .top div {
  width: 29px;
  height: 29px;
  border-radius: 100%;
  border: 1px solid #cccccc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#login .top div:hover {
  background: #cccccc;
}
#login .logo {
  width: 310px;
  padding: 15px 0 40px;
}
#login .logo img {
  width: 100%;
}
#login .email .input {
  width: calc(100% - 120px - 10px) !important;
}
#login .email #pinkBtn {
  width: 120px;
  height: 55px;
  border-radius: 5px;
  margin: 0 0 16px;
}
#login .input {
  width: 100%;
  height: 55px;
  /* background: #eeeeee; */
  border-radius: 5px;
  box-shadow: none;
  padding: 16px 20px;
  font-size: 16px;
  /* margin: 0 0 16; */
}
#login .input::placeholder {
  color: #a3a1a7;
}
#login .input:hover {
  border: 1px solid #ec15ff;
}
#login .input:focus {
  background: #ffffff;
  border: 1px solid #ec15ff;
}
#login .check {
  margin: 0 0 16px;
}
#login #purpleBtn {
  width: 100%;
  height: 58px;
  border-radius: 5px;
  font-size: 18px;
  margin: 0 0 26px;
}
#login .signup {
  margin: 0 0 24px;
}
#login .signup p {
  color: #676767;
  font-size: 13px;
  padding: 0 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#login .signup span:hover {
  color: #ec15ff;
}
#login .signup p:first-child {
  /*border-right: 1px solid #cccccc;*/
}
#login .line {
  position: relative;
  margin: 0 0 20px;
}
#login .line p {
  font-size: 16px;
  z-index: 2;
  padding: 0 10px;
  background: #ffffff;
  color: #6d6c75;
}
#login .line .absoluteBox {
  position: absolute;
  top: 50%;
  height: 1px;
  left: 0;
  margin: -0.5px 0 0;
  background: #dfdce4;
}

#login #kakao,
#login #naver,
#login #facebook,
#login #google {
  width: 100%;
  height: 58px;
  margin: 0 0 16px;
  border-radius: 4px;
}
#login #kakao img,
#login #naver img,
#login #facebook img,
#login #google img {
  width: 20px;
  margin: 0 20px 0 0;
}
#login #kakao p,
#login #naver p,
#login #facebook p,
#login #google p {
  font-size: 18px;
}
#login .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.loginSection.ant-input-outlined {
  background: #eee;
  font-weight: 500;
}
.loginSection #login .ant-row div:first-child {
  flex: 0 0 1;
}

#login input:-webkit-autofill {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}
#login input:-webkit-autofill:focus,
#login input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}
#login input:autofill {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}
#login input:autofill:hover,
#login input:autofill:focus,
#login input:autofill:active {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}

#signup,
#sign-in {
  font-size: 14px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
#signup.ant-checkbox-wrapper::hover.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
#signup.ant-checkbox-wrapper::hover .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
#sign-in.ant-checkbox-wrapper::hover.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
#sign-in.ant-checkbox-wrapper::hover .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border: #ec15ff;
  background-color: inherit;
}

@media (max-width: 700px) {
  #login {
    width: 100%;
    padding: 20px;
  }
  #login .input {
    font-size: 14px;
  }
  #login .input::placeholder {
    font-size: 14px;
  }
}
