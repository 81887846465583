/* Width/Height 입력 필드 숨기기 */
.se-dialog.sun-editor-common > div.se-dialog-inner > div.se-dialog-content.se-dialog-image > form > div._se_tab_content._se_tab_content_image > div > div:nth-child(3){
    display: none !important;
}
/* 대체 문자열 숨김 */
.sun-editor-common > div.se-dialog-inner > div.se-dialog-content.se-dialog-image > form > div._se_tab_content._se_tab_content_image > div > div:nth-child(2) {
    display: none !important;
}
/* Insert description 입력 필드 숨기기 */
.sun-editor-common > div.se-dialog-inner > div.se-dialog-content.se-dialog-image > form > div._se_tab_content._se_tab_content_image > div > div.se-dialog-form.se-dialog-form-footer {
    display: none !important;
}
/* 이미지, 링크 선택 tab 필드 숨기기 */
.sun-editor-common > div.se-dialog-inner > div.se-dialog-content.se-dialog-image > div.se-dialog-tabs {
    display: none !important;
}
/*
.sun-editor-common > div.se-dialog-inner > div.se-dialog-content.se-dialog-image > div.se-dialog-tabs > button._se_tab_link {
    display: none !important;
}

.sun-editor-common > div.se-dialog-inner > div.se-dialog-content.se-dialog-image > div.se-dialog-tabs > button._se_tab_link.active {
    display: none !important;
}
*/
/* 이미지 주소 입력 필드 숨기기 */
.sun-editor-common > div.se-dialog-inner > div.se-dialog-content.se-dialog-image > form > div._se_tab_content._se_tab_content_image > div > div:nth-child(1) > div:nth-child(2) {
    display: none !important;
}
