#alram {
    width: 100%;
    height: 80vh;
    padding: 25px 0 40px;

    .rsWrapper {
        height: 100%;

        .list {
            width: 373px;
            height: 100%;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background-color: #f3f3f3;
            overflow: hidden;
            justify-content: flex-start;

            .top {
                height: 52px;
                background: #ffffff;
                color: #000000;
                font-size: 16px;
            }

            .box {
                padding: 8px 12px 8px 24px;
                border-bottom: 1px solid #eae8ec;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:hover {
                    background: #ffffff;
                }

                &#active {
                    background: #ffffff;
                }

                &#new {
                    background: #fdf2ff;
                }

                >div:first-child {
                    width: auto;
                    position: relative;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                    }

                    .round {
                        border: 2px solid #ffffff;
                        background: #e94cf7;
                        width: 10px;
                        height: 10px;
                        border-radius: 100%;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }

                >div {
                    width: calc(100% - 40px - 20px);
                    margin: 0 12px;

                    >div:first-child {
                        margin-bottom: 4px;
                    }

                    h1 {
                        font-size: 16px;
                        font-weight: 600;
                        color: #0e0e1f;
                    }

                    h2 {
                        color: #656262;
                        font-size: 12px;
                    }

                    p {
                        font-size: 11px;
                        color: #a3a1a7;
                        font-weight: 500;
                    }

                    .count {
                        width: 26px;
                        background: #f34144;
                        border-radius: 100px;
                        padding: 4px;
                        font-size: 12px;
                        font-weight: 600;
                        color: #ffffff;
                    }
                }
            }
        }

        .content {
            width: calc(100% - 373px);
            padding: 0 0 0 28px;
            height: 100%;
            justify-content: flex-start;

            .top {
                padding: 24px 0;

                .svgBox {
                    width: auto;
                    margin-right: 20px;
                }

                h1 {
                    font-size: 24px;
                    font-weight: 700;
                    color: #0e0e1f;
                }
            }

            .date {
                margin: 8px 0 16px;

                p {
                    color: #a3a1a7;
                    font-size: 14px;
                    font-weight: 600;

                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }

            .box {
                align-items: flex-start;

                .icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    background: linear-gradient(to bottom, #13d5df 0%, #f138fc 100%);
                }

                div:not(.icon) {
                    width: calc(100% - 40px);
                    padding: 0 0 0 24px;

                    p {
                        color: #000000;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 1.5;
                    }
                }
            }
        }
    }

}

@media (max-width: 700px) {
    #alram {
        padding: 0;

        .rsWrapper {
            padding: 0;

            .top,
            .content {
                display: none;
            }

            .list {
                background: #ffffff;
                border: none;
                border-radius: 0;
                border-top: 1px solid #eae8ec;
                width: 100%;
            }
        }
    }
}