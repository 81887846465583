#imageSlider {
    display: block;
    width: 100%;
}

#imageSlider .list div {
    width: 100vw;
    height: 100vh;
}



#imageSlider .arrowLeft,
#imageSlider .arrowRight {
    position: absolute;
    width: 76px;
    margin: 0 4px 0 0;
    height: 160px;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    transition: all .2s ease-in-out;
    z-index: 1000;
    border-radius: 4px;
}

#imageSlider .arrowLeft:hover,
#imageSlider .arrowRight:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

#imageSlider .arrowLeft {
    top: 50%;
    left: 0;
    margin: -18px 0 0;
}

#imageSlider .arrowRight {
    top: 50%;
    right: 0;
    margin: -18px 0 0;
}

@media (max-width :700px) {
    #imageSlider .list div {
        width: 100%;
    }

    #imageSlider .list {
        padding: 0 16px;
    }

    #imageSlider .list img {
        max-width: 100%;
    }

    #imageSlider .arrowRight,
    #imageSlider .arrowLeft {
        width: 30px;
        height: 60px;
    }

}