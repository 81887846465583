#musicWrapper > .rsWrapper > div:first-child {
  position: relative;
  padding: 45px 0;
  align-items: flex-start;
}

#musicWrapper .filter {
  background: #f3f3f3;
  padding: 20px 0;
}

#musicWrapper .filter#filter .listBox {
  display: flex;
}

#musicWrapper .filter .listBox {
  display: none;
}

#musicWrapper .filter .rsWrapper .title {
  width: 152px;
  /* margin-top: 0; */
}

#musicWrapper .filter .rsWrapper .title h2 {
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
  margin: 0 0 0 20px;
}

#musicWrapper .filter .rsWrapper .reset #whiteBtn {
  width: 89px;
  height: 34px;
  border-radius: 24px;
  font-size: 14px;
  margin: 8px 0 0;
}

#musicWrapper .filter .rsWrapper .reset #whiteBtn img {
  width: 18px;
}

#musicWrapper .filter .rsWrapper .reset #greyBtn2 {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  font-size: 14px;
  color: #e94cf7;
}

#musicWrapper .filter .rsWrapper .reset #greyBtn4 {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  font-size: 14px;
}

/* #musicWrapper .filter .rsWrapper .listBox {
  margin: 0 0 8px;
  min-height: 36px;
} */

/* #musicWrapper .filter .rsWrapper .listBox :where(.css-dev-only-do-not-override-14i19y2).ant-radio-group {
  font-weight: 400;
  font-size: 14px;
} */

#musicWrapper .rsWrapper > div .content {
}

#musicWrapper .rsWrapper > div .content > .titleBox {
  margin: 0 0 60px;
}

#musicWrapper .rsWrapper > div .content .titleBox > h1 {
  font-size: 32px;
  font-weight: bold;
  color: #0e0e1f;
}

#musicWrapper .rsWrapper > div .content .titleBox > h1 br {
  display: none;
}

#musicWrapper .rsWrapper > div .content > .titleBox > img {
  display: none;
}

#musicWrapper .rsWrapper > div .content .titleBox .search {
  width: 400px;
  height: 48px;
  background: #f4f5f7;
  padding: 8px 12px;
  gap: 12px;
}

#musicWrapper .rsWrapper > div .content .titleBox .search img {
  width: 18px;
  margin: 0 13px 0 0;
  height: auto;
}

#musicWrapper .rsWrapper > div .content .titleBox .search input {
  border: none;
  background: none;
  width: calc(100% - 16px);
  box-shadow: none;
  font-size: 15px;
  line-height: 1;
  color: #000;
  font-weight: 400;
}

#musicWrapper .rsWrapper > div .content .titleBox .search input::placeholder {
  font-size: 13px;
  color: #a3a1a7;
}

#musicWrapper .rsWrapper > div .content .searchBox div {
  width: auto;
}

#musicWrapper .rsWrapper > div .content .searchBox div #pinkBtn2 {
  width: 80px;
  height: 33px;
  font-size: 14px;
  border-radius: 24px;
  margin: 0 4px 0 0;
}

#musicWrapper .rsWrapper > div .content .searchBox div #pinkBtn2.active {
  color: #ffffff;
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  border: none;
}

#musicWrapper .rsWrapper > div .content .searchBox div div {
  margin: 0 0 0 8px;
}

#musicWrapper .rsWrapper > div .content .searchBox div #pinkBtn {
  /* width: 120px; */
  height: 40px;
  border-radius: 4px;
}

/* #musicWrapper .rsWrapper > div .content .searchBox div #pinkBtn p:first-child {
  margin: 0 13px 0 0;
} */

#musicWrapper .rsWrapper > div .content .searchBox div #pinkBtn p,
#musicWrapper .rsWrapper > div .content .searchBox div #whiteBtn p {
  font-size: 14px;
}

#musicWrapper .rsWrapper > div .content .searchBox div #whiteBtn {
  width: auto;
  height: 40px;
  padding: 0 12px;
  border-radius: 4px;
}

#musicWrapper .rsWrapper > div .content .searchBox div #whiteBtn div img {
  width: 14px;
}

#musicWrapper .rsWrapper > div .content .searchBox {
  margin: 0 0 12px;
}

#musicWrapper .rsWrapper > div .content .listBox {
  padding: 16px 0 0;
  border-top: solid 2px #464548;
}

#musicWrapper .rsWrapper > div .content .listBox .list#active {
  background-color: #f3f3f3;
}

#musicWrapper .rsWrapper > div .content .listBox .list {
  padding: 12px 0;
  flex-wrap: wrap;
  border-radius: 8px;
  margin: 0 0 4px;
  gap: 5px;
  /* display: flex
;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%; */
}

#musicWrapper .rsWrapper > div .content .listBox .list .check {
  width: 20px;
  margin: 0 10px;
}

#musicWrapper .rsWrapper > div .content .listBox .list .rankBox {
  width: 80px;
  text-align: center;
}

#musicWrapper .rsWrapper > div .content .listBox .list .rankBox h1 {
  font-size: 14px;
}

#musicWrapper .rsWrapper > div .content .listBox .list .rankBox div {
  width: auto;
}

#musicWrapper .rsWrapper > div .content .listBox .list .rankBox div img {
  width: 10px;
}

#musicWrapper .rsWrapper > div .content .listBox .list .rankBox div p {
  font-size: 12px;
  color: #c4c3c7;
}

#musicWrapper .rsWrapper > div .content .listBox .list .rankBox div p#up {
  color: #f34144;
}

#musicWrapper .rsWrapper > div .content .listBox .list .rankBox div p#down {
  color: #0d88d4;
}

/* 60, 20, 20, 20 */
#musicWrapper .rsWrapper > div .content .listBox .list .thumb {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  margin: 0 10px;
}

#musicWrapper .rsWrapper > div .content .listBox .list .titleBox {
  /* width: calc((100% - 40px - 60px - 80px - 50px - 88px - 200px - 48px - 48px - 48px - 48px)); */
  flex: 1;
}

#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title {
  width: 60%;
  margin-top: 0;
  padding: 4px 12px;
  gap: 4px;
}

/* #musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div {
  margin: 0 0 8px;
} */

#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 3px 0 0;
}

#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title h1 {
  display: -webkit-box;
  font-size: 16px;
  color: #0e0e1f;
  font-weight: 600;
  cursor: pointer;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div p {
  font-size: 12px;
  color: #323146;
  font-weight: 400;
}

#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .userBox {
  width: 40%;
}

#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .userBox img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}

#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .userBox p {
  font-size: 14px;
  color: #0e0e1f;
  font-weight: 400;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: -0.28px;
}

#musicWrapper .rsWrapper > div .content .listBox .list .heart {
  width: 50px;
  padding: 0 12px;
  position: relative;
  cursor: pointer;
}

#musicWrapper .rsWrapper > div .content .listBox .list .heart img {
  width: 100%;
}

#musicWrapper .rsWrapper > div .content .listBox .list .heart img.activeIcon {
  position: absolute;
  width: calc(100% - 24px);
  height: auto;
  left: 12px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

#musicWrapper .rsWrapper > div .content .listBox .list .heart:hover img.activeIcon {
  opacity: 1;
}

#musicWrapper .rsWrapper > div .content .listBox .list .views {
  width: 88px;
}

#musicWrapper .rsWrapper > div .content .listBox .list .views p {
  font-size: 14px;
}

#musicWrapper .rsWrapper > div .content .listBox .list .views .icon {
  font-size: 24px;
  color: #cccccc;
}

#musicWrapper .rsWrapper > div .content .listBox .list .star {
  width: 200px;
}

#musicWrapper .rsWrapper > div .content .listBox .list .star p {
  font-size: 16px;
  color: #cccccc;
  margin: 0 0 0 3px;
}

#musicWrapper .rsWrapper > div .content .listBox .list .star p#active {
  color: #ffa514;
}

#musicWrapper .rsWrapper > div .content .listBox .list .btn {
  width: 44px;
  padding: 0 12px;
  position: relative;
  cursor: pointer;
  background-color: unset;
  border-radius: unset;
}

#musicWrapper .rsWrapper > div .content .listBox .list .btn img {
  width: 100%;
}

#musicWrapper .rsWrapper > div .content .listBox .list .btn img.activeIcon {
  opacity: 0;
  position: absolute;
  left: 12px;
  width: calc(100% - 24px);
  transition: all 0.2s ease-in-out;
  background: #fff;
}
#musicWrapper .rsWrapper > div .content .listBox .list#active .btn img.activeIcon {
  background-color: #f3f3f3;
}

#musicWrapper .rsWrapper > div .content .listBox .list .btn:hover img.activeIcon,
#musicWrapper .rsWrapper > div .content .listBox .list .btn#active img.activeIcon {
  opacity: 1;
}
/*design.css 이전*/
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title {
  width: 100%;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div {
  padding: 4px 0;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div img {
  display: none;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div p,
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div h1 {
  border-radius: 12px;
  border: 1px solid #c4c3c7;
  background: #fff;
  width: auto;
  color: #6d6c75;
  font-size: 11px;
  padding: 4px;
  font-weight: 700;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title .ant-input-outlined:focus,
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title .ant-input-outlined:focus-within {
  border: 0;
  box-shadow: none;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .userBox {
  width: 25%;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .gerneBox .absolBox div .title div img {
  background: #fff;
  border-radius: 100%;
}
#musicWrapper .rsWrapper > div .content .listBox .list .rankBox {
  gap: 4px;
}
#musicWrapper .rsWrapper > div .content .listBox .list .rankBox h1 {
  min-width: 24px;
}
#musicWrapper .rsWrapper > div .content .listBox .list .rankBox div {
  padding-left: 4px;
  font-weight: 600;
}
#musicWrapper .rsWrapper > div .content .listBox .list .rankBox div img {
  width: 16px;
}
#musicWrapper .rsWrapper > div .content .listBox .list .views .i-playHit {
  background: url(/src/assets/images/common/i-play.svg) no-repeat left center;
  padding-left: 24px;
}
#musicWrapper .rsWrapper > div .content .listBox .list .play img {
  width: calc(100% - 2px);
}
#musicWrapper .rsWrapper > div .content .listBox .list p {
  font-size: 14px;
  font-weight: 500;
}
#musicWrapper .rsWrapper > div .content .titleBox .search img {
  margin: 0;
}

.reWrapper .titleBox .search {
  gap: 16px;
}
.reWrapper .titleBox .search img {
  margin: 0;
}
.reWrapper .titleBox .search .ant-input-affix-wrapper {
  padding: 0;
  background: transparent;
  border-width: 0;
}

#musicInfo .rsWrapper .thumbBox .absolBox > div > img {
  width: 48px;
}

#musicBox .affixBox .tabBox,
#musicBox .affixBox .top {
  background: rgba(255, 255, 255, 0.3);
}
#musicBox .listBox.playlist .list .titleBox .user {
  margin: 0;
}
#musicBox .listBox.playlist .list .titleBox .user img {
  display: none;
}
#musicBox .listBox.playlist .list .titleBox .user p,
#musicBox .listBox.playlist .list .titleBox .user h1 {
  border-radius: 12px;
  border: 1px solid #c4c3c7;
  background: #fff;
  width: auto;
  color: #6d6c75;
  font-size: 11px;
  padding: 4px;
  font-weight: 700;
}
#musicBox .listBox.playlist .list .titleBox .user h1 {
  color: #0e0e1f;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  background: transparent;
}
#musicBox .listBox.playlist .list p {
  font-size: 24px;
  width: 16px;
}

/* 모바일필터 */
#musicMobileFilter > div:first-child > p {
  font-size: 30px;
  margin: 0 0 38px;
}

#musicMobileFilter .content h1 {
  font-size: 26px;
  font-weight: 700;
  margin: 0 0 5px;
  color: #323146;
}

#musicMobileFilter .content h2 {
  font-size: 20px;
  color: #323146;
  margin: 0 0 30px;
}

#musicMobileFilter .content .searchBox {
  height: 41px;
  border-bottom: 1px solid #87858b;
  padding: 12px 10px;
  margin: 0 0 20px;
}

#musicMobileFilter .content .searchBox input {
  width: calc(100% - 13px);
  border: none;
  padding: 0;
  box-shadow: none;
}

#musicMobileFilter .content .searchBox img {
  width: 13px;
}

#musicMobileFilter .content .listBox {
  border-bottom: 1px solid #dbdbe0;
}

#musicMobileFilter .content .listBox h3 {
  font-size: 18px;
  color: #323146;
  margin: 20px 0 10px;
}

#musicMobileFilter .content .listBox div {
  margin: 0 0 20px;
  flex-wrap: wrap;
}

#musicMobileFilter .content .listBox div .list {
  width: auto;
  padding: 8px;
  border-radius: 16px;
  margin: 0 8px 6px 0;
  border: 1px solid #d6d6d6;
}

#musicMobileFilter .content .listBox div .list#active {
  border: solid 1px #e94cf7;
}

#musicMobileFilter .content .listBox div .list#active p {
  color: #e94cf7;
}

#musicMobileFilter .content .listBox div .list#active .round {
  background: #e94cf7;
}

#musicMobileFilter .content .listBox div .list#active .round img {
  width: 6px;
  display: flex;
}

#musicMobileFilter .content .listBox div .list .round img {
  display: none;
}

#musicMobileFilter .content .listBox div .list .round {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #d6d6d6;
  margin: 0 6px 0 0;
}

#musicMobileFilter .content .listBox div .list p {
  font-size: 12px;
  color: #222;
}

#musicMobileFilter .btnBox {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 30px 20px;
  background: #ffffff;
}

#musicMobileFilter .btnBox #logoutBtn,
#musicMobileFilter .btnBox #pinkBtn {
  width: 49%;
  height: 37px;
  border-radius: 3px;
}

#musicMobileFilter .btnBox #logoutBtn img {
  width: 20px;
  margin: 0 10px 0 0;
}

#musicMobileFilter .btnBox #pinkBtn p {
  font-size: 14px;
  color: #0e0e1f;
}

#mobileFixedBox {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  padding: 10px 15px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #f4f5f7;
  background-color: #ffffff;
  z-index: 100;
  border-radius: 30px;
  display: none;
}

#mobileFixedBox .select {
  width: auto;
  padding: 11px 14px;
  background-color: #f4f5f7;
  border-radius: 40px;
}

#mobileFixedBox .select p {
  font-size: 14px;
}

#mobileFixedBox .select span {
  color: #e94cf7;
  font-weight: 700;
}

#mobileFixedBox > div {
  width: auto;
}

#mobileFixedBox > div img {
  width: 14px;
  margin: 0 0 0 30px;
}

#mobileFixedBox > div img:first-child {
  margin: 0;
}

#musicBox {
  /* position: fixed; */
  top: 70px;
  left: 20px;
  z-index: 100;
  /* width: 375px; */
  background: linear-gradient(to bottom, #bbecee, #f1c3f5);
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
}

#detail #musicBox {
  width: 100%;
  background: none;
  border-radius: unset;
  box-shadow: none;
}

#musicBox > div:first-child > p {
  font-size: 16px;
  padding: 12px 12px 0;
}

#musicBox .album {
  position: relative;
  margin-bottom: -40px;
  margin-top: 28px;
}

#musicBox .album .absoluteBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

#musicBox .album .absoluteBox div {
  width: 240px;
  height: 240px;
  border-radius: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

#musicBox .album .absoluteBox div .borderBox {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background: linear-gradient(to right, #0fabb3 0%, #c12cca 100%);
  padding: 2px;
}

#musicBox .album .absoluteBox div .borderBox img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

#musicBox .album > img {
  width: 240px;
  height: 240px;
  border-radius: 100%;
}

#musicBox .affixBox {
  width: 100%;
}

#musicBox .affixBox :where(.css-dev-only-do-not-override-14i19y2).ant-affix {
  background: linear-gradient(to bottom, #bbecee, #f1c3f5);
}

#musicBox .playBox {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 10px 0;
  z-index: 2;
  border-bottom: solid 1px rgba(0, 0, 0, 0.25);
  position: relative;
}

#musicBox .playBox > p {
  font-size: 14px;
  color: #ffffff;
  margin: 0 0 20px;
}

#musicBox .playBox .title {
  padding: 0 16px;
  margin: 0;
  gap: 12px;
  letter-spacing: -0.4px;
  text-align: center;
  line-height: 1.1;
}

#musicBox .playBox .title .flexRow {
  position: relative;
  display: flex;
}

#musicBox .playBox .title .flexRow img {
  width: 20px;
  cursor: pointer;
}

#musicBox .playBox .title .flexRow img.activeIcon {
  position: absolute;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

#musicBox .playBox .title .flexRow:hover img.activeIcon,
#musicBox .playBox .title .flexRow#active img.activeIcon {
  opacity: 1;
}

#musicBox .playBox .title h1 {
  font-size: 20px;
  font-weight: 600;
  font-family: 'pretendard';
  color: #0e0e1f;
}

#musicBox .playBox .gradient {
  width: 60%;
  height: 2px;
  background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}

#musicBox .playBox .time {
  margin: 8px 0 10px;
  padding: 0 8px;
}

#musicBox .playBox .time p {
  color: #656262;
  font-size: 12px;
}

#musicBox .playBox .time span {
  color: #0e0e1f;
}

#musicBox .playBox .play {
  margin: 0 0 18px;
}

#musicBox .playBox .play img {
  width: 22px;
  margin: 0 10px;
  cursor: pointer;
}

#musicBox .tabBox .tab {
  width: 50%;
  background: rgba(185, 138, 204, 0.2);
  height: 41px;
  padding: 12px 0;
  color: #86748f;
  font-size: 14px;
  font-weight: 700;
  font-family: 'pretendard';
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#musicBox .tabBox .tab:hover {
  background: transparent;
  color: #c718e2;
}

#musicBox .tabBox .tab#active {
  background: transparent;
  color: #c718e2;
}

#musicBox .top {
  padding: 10px 8px;
}

#musicBox .top div {
  width: auto;
}

#musicBox .top div .check {
  width: 20px;
  margin: 0 8px;
}

#musicBox .top div .arrowDown {
  font-size: 14px;
  color: #0e0e1f;
  margin: 0 14px 0 6px;
}

#musicBox .top div .arrowUp {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #e94cf7;
  color: #ffffff;
  font-size: 14px;
  margin: 0 11px 0 0;
}

#musicBox .top > div > p {
  font-size: 12px;
  color: #6d6c75;
}

#musicBox .top > div > p:first-child span {
  color: #c718e2;
}

#musicBox .top .antSelect {
  width: 120px;
  height: 32px;
}

#musicBox .top .antSelect .ant-select {
  width: 100%;
}

#musicBox .listBox {
  padding: 8px;
  justify-content: flex-start;
  height: 250px;
  overflow: auto;
  flex-wrap: wrap;
}

#musicBox .listBox .list {
  padding: 8px;
  position: relative;
  margin: 0 0 4px;
  border-radius: 4px;
  gap: 8px;
}

#musicBox .listBox .list#active {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

#musicBox .listBox .list .check {
  width: 20px;
  margin: 0 8px 0 0;
}

#musicBox .listBox .list .thumbnail {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  margin: 0;
}

#musicBox .listBox .list .titleBox {
  width: calc(100% - 48px - 8px - 8px - 20px - 12px - 10px - 20px - 8px);
}

#musicBox .listBox .list .titleBox div img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 5px 0 0;
}

#musicBox .listBox .list .titleBox div h1 {
  font-size: 14px;
  color: #0e0e1f;
  font-weight: 600;
}

#musicBox .listBox .list .titleBox div p {
  font-size: 12px;
  color: #87858b;
  width: 100%;
}

#musicBox .listBox .list .playIcon {
  width: 20px;
  position: relative;
  cursor: pointer;
}

#musicBox .listBox .list .playIcon#active img.activeIcon,
#musicBox .listBox .list .playIcon:hover img.activeIcon {
  opacity: 1;
}

#musicBox .listBox .list .playIcon#active img,
#musicBox .listBox .list .playIcon:hover img {
  opacity: 0;
}

#musicBox .listBox .list .playIcon img {
  width: 100%;
  height: auto;
}

#musicBox .listBox .list .playIcon img.activeIcon {
  position: absolute;
  left: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

#musicBox .listBox .list > p {
  color: #87858b;
  font-size: 20px;
  cursor: pointer;
}

#musicBox .listBox .absoluteBox {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 100px;
  border: 1px solid #c718e2;
  border-radius: 4px;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

#musicBox .listBox .absoluteBox .tab {
  padding: 8px 0;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#musicBox .listBox .absoluteBox .tab:hover {
  background: #f4f5f7;
}

#musicBox .bottom {
  border: solid 1px rgba(236, 21, 255, 0.2);
  /* background-color: rgba(255, 255, 255, 0.6); */
  background: #fbeffc;
  padding: 18px 42px;
  border-radius: 0 0 4px 4px;
}

#musicBox .bottom > div {
  width: 16px;
  position: relative;
}

#musicBox .bottom > div img {
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#musicBox .bottom > div:hover img.activeIcon {
  opacity: 1;
}

#musicBox .bottom > div img.activeIcon {
  position: absolute;
  opacity: 0;
}

#musicBox .ant-result .ant-result-title {
  font-size: 16px;
}

/* react-h5-audio-player */
#musicBox .rhap_container {
  padding: 0 8px;
  background-color: unset;
  box-shadow: unset;
}
#musicBox .rhap_progress-section {
  display: block;
  position: relative;
}

#musicBox .rhap_progress-section .rhap_time {
  font-size: 11px;
}

#musicBox .rhap_progress-section .rhap_current-time {
  position: absolute;
  right: 40px;
}

#musicBox .rhap_progress-section .separator {
  position: absolute;
  right: 33px;
  font-size: 11px;
}

#musicBox .rhap_progress-section .rhap_total-time {
  position: absolute;
  right: 0;
  color: #656262;
}

#musicBox .rhap_progress-section .rhap_progress-container {
  margin: 0 0 2px;
}

#musicBox .rhap_progress-section .rhap_progress-container .rhap_progress-filled {
  background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}

#musicBox .rhap_progress-section .rhap_progress-container .rhap_progress-indicator {
  width: 8px;
  height: 10px;
  margin-left: -5px;
  top: -4px;
  background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  border-radius: 4px;
}

#musicBox .rhap_controls-section {
  margin-top: 12px;
  justify-content: center;
  height: 32px;
}

#musicBox .rhap_controls-section .rhap_shuffle-controls .rhap_button-shuffle {
  /* position: relative;
  width: 40px;
  height: 40px;
  padding: 0; */
  padding-top: 6px;
}

#musicBox .rhap_controls-section .rhap_shuffle-controls .rhap_button-shuffle img {
  /* position: absolute;
  top: 12px;
  left: -4px; */
  width: 16px;
}

#musicBox .rhap_controls-section .rhap_main-controls .rhap_button-clear:hover:after,
#musicBox .rhap_controls-section .rhap_main-controls .rhap_button-clear:focus:after {
  display: none;
}

#musicBox .rhap_controls-section .rhap_main-controls .rhap_button-clear {
  margin: 0 2px;
  width: 40px;
  height: 40px;
}

#musicBox .rhap_controls-section .rhap_main-controls .rhap_button-clear img {
  width: 16px;
}

#detail #musicBox .rhap_controls-section .rhap_main-controls .rhap_button-clear img {
  width: 20px;
}

#musicBox .rhap_controls-section .rhap_main-controls .rhap_button-clear.rhap_play-pause-button img {
  padding-bottom: 2px;
}

/* #musicBox .rhap_controls-section .rhap_additional-controls {
  max-width: 40px;
} */

#detail #musicBox .rhap_controls-section .rhap_additional-controls {
  max-width: unset;
}

#musicBox .rhap_controls-section .rhap_additional-controls .rhap_button-clear:hover:after,
#musicBox .rhap_controls-section .rhap_additional-controls .rhap_button-clear:focus:after {
  display: none;
}

#musicBox .rhap_controls-section .rhap_additional-controls .rhap_button-clear img {
  /* position: absolute;
  right: -4px;
  top: 10px; */
  width: 22px;
}
/* react-h5-audio-player */
.rhap_progress-container {
  display: flex !important;
  align-items: flex-end !important;
  height: 18px !important;
}
.rhap_volume-indicator {
  background: #ec15ff !important;
  width: 4px !important;
}
#musicBox .rhap_volume-container .rhap_volume-bar-area {
  height: 2px;
}
.rhap_repeat-button {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px;
  color: #868686;
  margin-right: 20px !important;
}
.rhap_volume-button {
  flex: 0 0 20px;
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
  color: #000 !important;
  margin-right: 4px !important;
}
.rhap_progress-bar {
  height: 2px !important;
  margin-bottom: 3px;
}
.rhap_progress-bar:hover {
  height: 5px !important;
  transition: all 0.2s ease-in-out;
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 3px;
}
.rhap_main {
  gap: 4px;
}
.rhap_volume-button[aria-label='unMute'] {
  background: url(/src/assets/images/common/i-noSound.svg) no-repeat center;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  fill: #a29fab;
  margin-right: 4px !important;
}

@media (max-width: 1280px) {
  #musicWrapper .rsWrapper > div .filter {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }

  #musicWrapper .rsWrapper > div:first-child {
    flex-direction: column;
  }

  #musicWrapper .rsWrapper > div .content {
    width: 100%;
  }

  #musicWrapper .rsWrapper > div .filter .listBox {
    width: calc(100% / 4);
    border-bottom: none;
    border-right: 1px solid #dfdce4;
    padding: 0 20px;
    height: 210px;
    justify-content: flex-start;
  }
}

@media (max-width: 900px) {
  #musicWrapper .rsWrapper > div .content .titleBox > h1 {
    font-size: 20px;
    line-height: 1.5;
  }
  #musicWrapper .rsWrapper > div .content .titleBox > h1 br {
    display: initial;
  }

  #musicWrapper .rsWrapper > div .content .titleBox > h1 span {
    display: block;
    font-size: 26px;
  }

  /* 50 88  200*/
  #musicWrapper .rsWrapper > div .content .listBox .list .heart,
  #musicWrapper .rsWrapper > div .content .listBox .list .views,
  #musicWrapper .rsWrapper > div .content .listBox .list .star {
    display: none;
  }

  #musicWrapper .rsWrapper > div .content .listBox .list .titleBox {
    width: calc((100% - 40px - 60px - 200px - 48px - 48px - 48px - 48px));
  }
  #musicWrapper .filter,
  #musicWrapper .rsWrapper > div .content .titleBox .search,
  #musicWrapper .rsWrapper > div .content .searchBox > div:nth-child(2),
  #musicWrapper .rsWrapper > div .content .listBox .list .check,
  #musicWrapper .rsWrapper > div .content .listBox .list .btn,
  #musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div p {
    display: none;
  }

  #musicWrapper .rsWrapper > div .content .listBox .list .btn.mobile {
    display: flex;
  }

  #musicWrapper .rsWrapper > div .content > .titleBox,
  #musicWrapper .rsWrapper > div .content .searchBox {
    margin: 0 0 30px;
  }

  #musicWrapper .rsWrapper > div .content > .titleBox > img {
    display: initial;
    width: 18px;
  }

  #musicWrapper .rsWrapper > div .content .searchBox > div {
    width: 100%;
    justify-content: space-between;
  }

  #musicWrapper .rsWrapper > div .content .listBox .list .rankBox {
    width: 24px;
    padding: 0 0 0 5px;
    flex-direction: column;
  }

  #musicWrapper .rsWrapper > div .content .listBox .list .thumb {
    width: 50px;
    height: 50px;
  }

  #musicWrapper .rsWrapper > div .content .listBox .list .titleBox {
    width: calc(100% - 24px - 20px - 50px - 48px);
    flex-direction: column;
    align-items: flex-start;
  }

  #musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div {
    margin: 0;
  }

  #musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title {
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin: 0 0 10px;
  }

  #musicWrapper .rsWrapper > div .content .listBox .list .titleBox .userBox {
    width: 100%;
  }

  #musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div {
    width: auto;
  }

  #musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title h1 {
    font-size: 14px;
  }

  #mobileFixedBox {
    display: flex;
  }

  #musicBox {
    width: 100% !important;
    height: auto;
    top: 0 !important;
    left: -100% !important;
    /* z-index: 1000 !important; */
    justify-content: flex-start;
  }
  #musicBox .listBox {
    height: auto;
    min-height: 300px;
  }
  #detail #musicBox .rhap_controls-section {
    gap: 5%;
    margin-top: 30px;
  }
  #detail #musicBox .rhap_controls-section > div {
    flex: 1 !important;
  }
  #detail #musicBox .rhap_additional-controls {
    justify-content: end !important;
  }
  #detail #musicBox .rhap_additional-controls button {
    margin-right: 0;
  }
  #detail #musicBox .rhap_volume-controls {
    justify-content: start !important;
  }
  #detail #musicBox {
    /* margin: 0 0 30px; */
  }
}
