#myProfile .rsWrapper .titleBox {
  margin: 20px 0 30px;
  position: relative;
}

#myProfile .rsWrapper .titleBox .title {
  /* width: 160px; */
  width: auto;
  padding: 0 12px;
  background: #ffffff;
  z-index: 2;
  gap: 0;
}

#myProfile .rsWrapper .titleBox .title img {
  width: 24px;
  margin: 0 12px 0 0;
}

#myProfile .rsWrapper .titleBox .title h1 {
  font-size: 24px;
  font-weight: 600;
  color: #3e3d53;
}

#myProfile .rsWrapper .titleBox .line {
  position: absolute;
  top: 50%;
  left: 0;
  /* border: 1px dashed #c2b8cb; */
  margin: -0.5px 0 0;
  width: 100%;
  height: 1px;
}

#myProfile .userInfo,
#myProfile .myInfo,
#myProfile .sellerInfo,
#myProfile .payInfo {
  align-items: flex-start;
  border-bottom: 1px solid #dbdbe0;
  padding: 30px 0;
}

#myProfile .payInfo {
  border-bottom: none;
}

#myProfile .ai,
#myProfile .aiInfo {
  border-bottom: 1px solid #dbdbe0;
  padding: 30px 0;
  align-items: flex-start;
}

#myProfile .aiInfo {
  align-items: flex-start;
}

#myProfile .title {
  width: 180px;
  flex-wrap: wrap;
  margin-right: 24px;
  row-gap: 12px;
  gap: 12px;
}

#myProfile .title img {
  width: 20px;
  /* margin: 0 14px 0 0; */
}

#myProfile .title h1 {
  font-size: 16px;
  font-weight: 500;
  color: #3e3d53;
}

#myProfile .title div #darkGrey {
  width: 132px;
  padding: 11px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
}

#myProfile .sellerInfo .title div #darkGrey {
  width: 65px;
}

#myProfile .title div #darkGrey:hover {
  border: 1px;
}

#myProfile .content {
  width: calc(100% - 180px);
  flex-wrap: wrap;
  gap: 30px;
}
#myProfile .content:has(.tabBox) {
  gap: 0;
  row-gap: 8px;
  column-gap: 24px;
}
#myProfile .aiInfo .content {
  gap: 0px;
}
#myProfile .content .tabBox {
  gap: 12px;
  margin: 0;
}
#myProfile .content .tabBox div {
  margin: 0;
}

#myProfile .userInfo .content .box,
#myProfile .myInfo .content .box,
#myProfile .myInfo .content .box .infoBox .box2,
#myProfile .myInfo .content .box .infoBoxUpdate .box2,
#myProfile .myInfo .content > .info,
#myProfile .sellerInfo .content .info,
#myProfile .payInfo .content .info {
  /* margin: 0 0 26px; */
  align-items: flex-start;
}

#myProfile .myInfo .content .box .infoBox .box2:first-child {
  margin: 0 0 45px;
}

#myProfile .userInfo .content .box .info,
#myProfile .myInfo .content .box .infoBox .box2 .info,
#myProfile .myInfo .content .box .infoBoxUpdate .box2 .info {
  width: calc(50% - 20px);
}

#myProfile .userInfo .content .box .info h1,
#myProfile .myInfo .content .box .thumb h1,
#myProfile .myInfo .content .box .thumbUpdate h1,
#myProfile .myInfo .content .box .infoBox .box2 .info h1,
#myProfile .myInfo .content .info h1,
#myProfile .sellerInfo .content .info h1,
#myProfile .payInfo .content .info h1,
#myProfile .ai .content .box p {
  font-size: 12px;
  color: #464548;
}

#myProfile .userInfo .content .box .info > div > p {
  margin: 0 0 9px;
}

#myProfile .userInfo .content .box .info > div > p,
#myProfile .ai .content .box p span,
#myProfile .myInfo .content .info p,
#myProfile .myInfo .content .info h1 span,
#myProfile .userInfo .content .box .info h1 span {
  font-size: 12px;
  color: #0d88d4;
}

#myProfile .content .box .info > p,
#myProfile .content .box .infoBox .box2 .info p,
#myProfile .content .info p {
  /* font-size: 13px; */
  color: #000000;
  font-weight: 500;
}

#myProfile .userInfo .content .box .info input,
#myProfile .myInfo .content .box .infoBoxUpdate .box2 .info input,
#myProfile .sellerInfo .content .info input,
#myProfile .payInfo .content .info input,
#myProfile .myInfo .content .info input,
#myProfile .myInfo .content .info textarea {
  width: 100%;
  height: 40px;
  /* background: #f7f7f8;
  border: 1px solid #e2dfe4; */
  border-radius: 4px;
  outline: none;
  padding: 0 10px;
  box-sizing: border-box;
  resize: none;
  font-size: 14px;
}

#myProfile .myInfo .content .info textarea {
  padding: 10px;
  height: 100px;
}

#myProfile .myInfo .content .info:nth-child(3) input {
  margin: 0 0 30px;
}

#myProfile .userInfo .content .box .info input::placeholder,
#myProfile .myInfo .content .box .infoBox .box2 .info input::placeholder,
#myProfile .sellerInfo .content .info input::placeholder,
#myProfile .payInfo .content .info input::placeholder,
#myProfile .myInfo .content .info input::placeholder,
#myProfile .myInfo .content .info textarea::placeholder {
  /* color: #a3a1a7; */
}

#myProfile .userInfo .content .box .info input:disabled,
#myProfile .myInfo .content .box .infoBox .box2 .info input:disabled,
#myProfile .sellerInfo .content .info input:disabled,
#myProfile .myInfo .content .info input:disabled,
#myProfile .payInfo .content .info input:disabled,
#myProfile .myInfo .content .info textarea:disabled {
  /* background: #e9ecf1;
  border: 1px solid #e2dfe4; */
  /* color: #524f56; */
}

#myProfile .userInfo .content .box .info input:focus,
#myProfile .myInfo .content .box .infoBox .box2 .info input:focus,
#myProfile .sellerInfo .content .info input:focus,
#myProfile .myInfo .content .info input:focus,
#myProfile .payInfo .content .info input:focus,
#myProfile .myInfo .content .info textarea:focus {
  /* border: 1px solid #b041f3;
  background: #ffffff; */
}

#myProfile .userInfo .content .box .info #darkGrey {
  width: 105px;
  height: 33px;
  border-radius: 4px;
  font-size: 14px;
}

#myProfile .userInfo .content .box .info > .rowBox {
  position: relative;
  gap: 12px;
}

#myProfile .userInfo .content .box .info > .rowBox .ant-select {
  width: 150px;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox {
  position: absolute;
  top: 40px;
  right: 0;
  width: 400px;
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox .absolueTitle {
  margin: 0 0 31px;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox .absolueTitle h1 {
  font-size: 16px;
  font-weight: 700;
  color: #0e0e1f;
  margin: 0;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox .absolueTitle p {
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox .absolueTitle p:hover {
  color: #e21dfd;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox .box p {
  font-size: 12px;
  color: #464548;
  margin: 0 0 9px;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox div #darkGrey {
  margin: 0 16px 0 0;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox div #darkGrey,
#myProfile .userInfo .content .box .info > .rowBox .absoluteBox div #purpleBtn {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}

#myProfile .userInfo .content .box .info > .rowBox > input {
  width: calc(100% - 104px - 12px);
}

#myProfile .userInfo .content .box .info > .rowBox #darkGrey {
  width: 104px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}

#myProfile .userInfo .content .box .info > .rowBox .ant-select {
  /* width: calc(50% - 6px); */
  height: 40px;
}

#myProfile .myInfo .content .box .thumb {
  width: 140px;
}

#myProfile .myInfo .content .box .thumb img {
  width: 100%;
  height: 140px;
  border-radius: 16px;
}

#myProfile .myInfo .content .box .thumbUpdate {
  width: 317px;
}

#myProfile .myInfo .content .box .thumbUpdate img {
  width: 140px;
  height: 140px;
}

#myProfile .myInfo .content .box .thumbUpdate .plusBox {
  width: 140px;
  height: 140px;
  border-radius: 8px;
  border: 1px dashed #e21dfd;
  background: #fcf7fd;
  cursor: pointer;
  overflow: hidden;
}
#myProfile .myInfo .content .box .thumbUpdate .plusBox:has(img) {
  border: none;
}

#myProfile .myInfo .content .box .thumbUpdate .plusBox .plus {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #f0dbf4;
  color: #c49bcc;
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 4px;
}

#myProfile .myInfo .content .box .thumbUpdate .plusBox p {
  text-align: center;
  font-size: 12px;
  color: #c49bcc;
  line-height: 1.2;
}

#myProfile .myInfo .content .box .thumbUpdate .plusBox p::before {
  display: none;
}

#myProfile .myInfo .content .box .infoBoxUpdate {
  width: calc(100% - 0);
  height: 100%;
  justify-content: space-between;
  padding: 0;
  align-items: flex-start;
}

#myProfile .myInfo .content .box .infoBox {
  width: calc(100% - 140px);
  height: 100%;
  justify-content: space-between;
  padding: 0;
  align-items: flex-start;
}

.mypageSection .plusBannerBox,
#myProfile .myInfo .content .info .plusBannerBox {
  border: 1px dashed #e21dfd;
  background: #fcf7fd;
  min-height: 219px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
}

/* .plusBannerBox .ant-upload-wrapper .ant-upload-drag {
  background: none;
  border: none;
} */
#myProfile .myInfo .content .info .plusBannerBox:has(img) {
  border: none;
}

#myProfile .myInfo .content .info .plusBannerBox .plus {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #f0dbf4;
  color: #c49bcc;
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 4px;
}

#myProfile .myInfo .content .info .plusBannerBox p {
  text-align: center;
  font-size: 12px;
  color: #c49bcc;
  line-height: 1.2;
}

#myProfile .myInfo .content .info .plusBannerBox p::before {
  display: none;
}

#myProfile .myInfo .content .info h2 {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin: 0 0 12px;
}

#myProfile .myInfo .content .info h3 {
  font-size: 13px;
  color: #000000;
}

#myProfile .ai .content .ant-form-item {
  width: 200px;
  background: #f7f7f8;
  border: 1px solid #dfdce4;
}

#myProfile .ai .content > .tabBox {
  width: auto;
  gap: 12px;
  margin-left: 12px;
}

#myProfile .ai .content .tabBox .aiTab {
  width: auto;
  height: auto;
  padding: 5px 12px;
  border: 1px solid #cccccc;
  border-radius: 30px;
  margin: 0;
  gap: 12px;
}

#myProfile .ai .content .tabBox .aiTab h1 {
  /* font-size: 13px; */
  color: #0e0e1f;
  margin: 0;
  gap: 8px;
}

#myProfile .ai .content .tabBox .aiTab p {
  font-size: 13px;
  color: #0e0e1f;
}

#myProfile .aiInfo .content .ant-checkbox,
#myProfile .aiInfo .content .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

#myProfile .aiInfo .content .ant-checkbox + span img {
  width: 21px;
  margin: 0 0 -6px;
}

#myProfile .aiInfo .content > p {
  font-size: 12px;
  color: #464548;
  margin: 0 0 10px;
}

#myProfile .aiInfo .content p span {
  color: #0d88d4;
}

#myProfile .aiInfo .content .tabBox .tab {
  width: auto;
  padding: 8px 12px;
  border: 1px solid #cccccc;
  font-size: 13px;
  color: #0e0e1f;
  font-weight: 600;
  border-radius: 20px;
  margin: 0;
  line-height: 1;
  height: 34px;
}

#myProfile .btnBox #logoutBtn,
#myProfile .btnBox #darkGrey,
#myProfile .btnBox #purpleBtn {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin: 14px 8px 0;
}
#myProfile .btnBox #logoutBtn {
  border-radius: 4px;
  border: 1px solid var(--t-groupTitleD, #6d6c75);
}
#myProfile .secession {
  margin: 34px 0 0;
}

#myProfile .secession .title #logoutBtn {
  width: 125px;
  height: 34px;
  border-radius: 24px;
}

#myProfile .secession .title #logoutBtn img {
  width: 18px;
  margin: 0 10px 0 0;
}
/* #myprofile 전체에서 사용될 탭버튼 */
#myProfile #pinkBtn2 {
  width: 80px;
  height: 33px;
  border-radius: 24px;
}

/* #myProfile 전체 영역 반응형 */
@media (max-width: 700px) {
  #myProfile .content,
  #myProfile .myInfo .content .box {
    gap: 30px;
  }
  #myProfile .userInfo,
  #myProfile .myInfo,
  #myProfile .payInfo {
    flex-direction: column;
    margin: 0 0 30px;
    padding: 0 0 30px 0;
    gap: 30px;
  }

  #myProfile .sellerInfo,
  #myProfile .ai,
  #myProfile .aiInfo {
    padding: 0 0 30px;
    width: 100%;
    flex-direction: column;
    margin: 0 0 30px;
  }

  #myProfile .userInfo .content,
  #myProfile .myInfo .content,
  #myProfile .ai .content,
  #myProfile .aiInfo .content,
  #myProfile .sellerInfo .content,
  #myProfile .payInfo .content {
    width: 100%;
    flex-wrap: wrap;
  }

  #myProfile .userInfo .content .box,
  #myProfile .myInfo .content .box,
  #myProfile .myInfo .content .box .infoBox .box2,
  #myProfile .myInfo .content .box .infoBoxUpdate .box2,
  #myProfile .sellerInfo .content .info,
  #myProfile .payInfo .content .info {
    flex-direction: column;
    margin: 0;
    gap: 30px;
  }
  #myProfile .myInfo .content {
    gap: 30px; 
  }

  #myProfile .userInfo .content .box:nth-child(3) {
    flex-direction: row;
  }

  #myProfile .userInfo .content .box #logoutBtn {
    width: 80px;
    height: 30px;
    border-radius: 4px;
    font-size: 12px;
  }

  #myProfile .userInfo .content .box .info,
  #myProfile .myInfo .content .box .infoBox .box2 .info,
  #myProfile .myInfo .content .box .infoBoxUpdate {
    width: 100%;
    padding: 0;
  }
  #myProfile .myInfo .content .box .infoBox .box2 {
    flex: 1;
  }

  #myProfile .myInfo .content .box .thumb {
    width: 100%;
  }

  #myProfile .myInfo .content .box .thumb .profileUpload {
    justify-content: flex-start;
  }

  #myProfile .myInfo .content .box .thumb img {
    width: 140px;
    height: 140px;
    border-radius: 16px;
  }

  #myProfile .myInfo .content .info .plusBannerBox {
    min-height: 110px;
  }

/*   #myProfile .myInfo .content .info img {
    height: 138px;
  }
 */
  #myProfile .myInfo .content .box .infoBox {
    flex-direction: row;
    padding: 0;
    width: 100%;
  }

  #myProfile .myInfo .content .box .infoBox .box2:first-child {
    margin: 0;
  }

  #myProfile .myInfo .content .box .infoBox .box2:last-child,
  #myProfile .myInfo .content .box .infoBoxUpdate .box2:last-child {
    flex-direction: row;
  }

  #myProfile .userInfo .content .box .info input,
  #myProfile .myInfo .content .box .infoBoxUpdate .box2 .info input,
  #myProfile .sellerInfo .content .info input,
  #myProfile .payInfo .content .info input,
  #myProfile .myInfo .content .info input,
  #myProfile .myInfo .content .info textarea {
    font-size: 13px;
  }

  #myProfile .userInfo .content .box .info > .rowBox {
    flex-wrap: wrap;
  }

  #myProfile .userInfo .content .box .info:first-child > .rowBox .ant-select {
    width: 86px;
  }

  #myProfile .userInfo .content .box .info:first-child > .rowBox > .mobileInput {
    width: calc(100% - 86px - 10px);
  }

  #myProfile .userInfo .content .box .info > .rowBox #darkGrey.mobileBtn {
    margin: 7px 0 0;
    width: 100%;
  }

  #myProfile .userInfo .content .box:nth-child(3)#update {
    flex-direction: column;
  }

  #myProfile .ai .content .box p span,
  #myProfile .myInfo .content .info p,
  #myProfile .myInfo .content .info h1 span,
  #myProfile .userInfo .content .box .info h1 span {
    font-size: 10px;
  }

  #myProfile .myInfo .content .box .thumbUpdate {
    width: auto;
  }

  #myProfile .myInfo .content .box .thumbUpdate img {
    margin: 0 10px 0 0;
  }
  #myProfile .myInfo .content .box .infoBoxUpdate {
    flex-direction: row;
  }
  #myProfile .myInfo .content .box .infoBoxUpdate .box2 .info {
    width: 100%;
  }

  #myProfile .myInfo .content .info:nth-child(3) input {
    margin: 0 0 5px;
  }

  #myProfile .myInfo .content .info textarea {
    height: 40px;
  }

  #myProfile .ai .content .ant-form-item {
    width: 50%;
  }

  #myProfile .ai .content > .tabBox {
    width: 100%;
    flex-wrap: wrap;
    margin: 10px 0 0;
  }

  #myProfile .aiInfo .title {
    width: 100%;
    row-gap: 30px;
    margin-bottom: 10px;
  }

  #myProfile .aiInfo #darkGrey {
    width: 100%;
    height: 40px;
    font-size: 14px;
    border-radius: 4px;
  }

  #myProfile .secession {
    margin: 0 0 30px;
  }

  /* #myProfile .btnBox {
    justify-content: space-between;
  }
 */
  #myProfile .btnBox #logoutBtn,
  #myProfile .btnBox #darkGrey,
  #myProfile .btnBox #purpleBtn {
    margin: 0;
    width: calc(100% / 3 - 10px);
  }

  #myProfile .btnBox#update {
    justify-content: space-between;
  }

  #myProfile .btnBox#update #logoutBtn,
  #myProfile .btnBox#update #purpleBtn {
    width: calc(50% - 8px);
  }
}
.ant-upload-wrapper .ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: transparent;
  border: transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.thumbUpdate .profileBox .ant-upload-wrapper .ant-upload-drag {
  height: 140px;
}
#myProfile .myInfo .about_me,
#myProfile .myInfo .content > div:last-child > div:nth-child(2) .ant-row  {
  margin-bottom: 30px;
}
/* 내 프로필 소개이미지 수정모드 전 */
#myProfile .myInfo .info .ant-image img {
  max-height: 320px !important;
}
@media (max-width: 700px) {
  #myProfile .myInfo .info .ant-image img {
    max-height: 150px !important;
  }
}
/* 내 프로필 소개이미지 수정모드 이미지 업로드시 상태 변화 */
.info .ant-upload-wrapper .ant-upload-drag {
  height: auto;
}
.info .ant-upload-wrapper .ant-upload-drag .imageContainer img:first-child {
  width: 100%;
  max-height: 320px;
  object-fit: cover;
}
@media (max-width: 700px) {
  .info .ant-upload-wrapper .ant-upload-drag {
    height: 150px;
  }
  .info .ant-upload-wrapper .ant-upload-drag .imageContainer img:first-child {
    max-height: 150px;
  }
}

/* 소개 이미지 관련 padding 제거, 높이 고정 css */
body:has(#myProfile) .ant-upload-wrapper .ant-upload-drag .ant-upload.ant-upload {
  padding: 0 !important;
  /* height: 214px !important; 내 프로필 이미지 위치 제어가 안되어 주석처리리 */
  margin-bottom: 0 !important;
}
body:has(#myProfile) body :where(.css-dev-only-do-not-override-14i19y2).ant-upload-wrapper .flexColCenter.plusBannerBox .ant-upload-drag .ant-upload {
  padding: 0 !important;
}
body:has(#myProfile) .flexColCenter.plusBannerBox .ant-upload-drag .ant-upload.ant-upload.ant-upload {
  padding: 0 !important;
}

body:has(#myProfile) :where(.css-dev-only-do-not-override-14i19y2).ant-upload-wrapper .ant-upload-drag {
  background: none !important;
  border: none !important;
  width: 100% !important;
}
/* ///////////// */
/* ///////////// */
/* SELLER MODAL */
/* ///////////// */
/* ///////////// */
.sellerModal .title {
  margin: 0 0 37px;
}
.sellerModal .title h1 {
  font-size: 24px;
  font-weight: 700;
  color: #0e0e1f;
}
.sellerModal .title p {
  font-size: 24px;
}
.sellerModal .check {
  margin: 0 0 17px;
}
.sellerModal .check p {
  font-size: 13px;
  color: #000000;
  margin: 0 0 -3px 20px;
  border-bottom: 1px solid #000000;
  line-height: 1;
}
.sellerModal .scroll {
  max-height: 600px;
  overflow-y: auto;
  justify-content: flex-start;
}
.sellerModal .scroll .boxWrapper1,
.sellerModal .scroll .boxWrapper2,
.sellerModal .scroll .boxWrapper3,
.sellerModal .scroll .boxWrapper4 {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 0 0 24px;
}
.sellerModal .scroll .boxWrapper1 .box p,
.sellerModal .scroll .boxWrapper2 .box p,
.sellerModal .scroll .boxWrapper3 .box p,
.sellerModal .scroll .boxWrapper4 .box p {
  font-size: 12px;
  color: #0e0e1f;
  margin: 0 0 9px;
}
.sellerModal .scroll .boxWrapper1 .box:first-child {
  width: 200px;
}
.sellerModal .scroll .boxWrapper1 .box:last-child {
  width: calc(100% - 200px - 40px);
}
.sellerModal .scroll .boxWrapper1 .box:first-child .tab {
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  border-radius: 4px;
  padding: 4px;
  height: 40px;
}
.sellerModal .scroll .boxWrapper1 .box:first-child .tab div {
  width: calc(50% - 2px);
  font-size: 14px;
  color: #ffffff;
  border-radius: 4px;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.sellerModal .scroll .boxWrapper1 .box:first-child .tab div:hover {
  background: #ffffff;
  color: #464548;
}
.sellerModal .scroll .boxWrapper1 .box:first-child .tab div#active {
  background: #ffffff;
  color: #464548;
}
.sellerModal .scroll .boxWrapper1 .box:last-child #darkGrey {
  width: 88px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
}
.sellerModal .scroll .boxWrapper1 .box:last-child input {
  width: calc(100% - 88px - 12px);
}
.sellerModal .scroll .boxWrapper1 .box input,
.sellerModal .scroll .boxWrapper2 .box input,
.sellerModal .scroll .boxWrapper3 .box input,
.sellerModal .scroll .boxWrapper4 .box input {
  height: 40px;
  outline: none;
  border: 1px solid #e2dfe4;
  background: #f7f7f8;
  border-radius: 4px;
  padding: 0 12px;
  font-size: 13px;
}
.sellerModal .scroll .boxWrapper1 .box input::placeholder,
.sellerModal .scroll .boxWrapper2 .box input::placeholder,
.sellerModal .scroll .boxWrapper3 .box input::placeholder,
.sellerModal .scroll .boxWrapper4 .box input::placeholder {
  color: #a3a1a7;
}

.sellerModal .scroll .boxWrapper1 .box input:disabled,
.sellerModal .scroll .boxWrapper2 .box input:disabled,
.sellerModal .scroll .boxWrapper3 .box input:disabled,
.sellerModal .scroll .boxWrapper4 .box input:disabled {
  border: 1px solid #e2dfe4;
  background: #e9ecf1;
  color: #524f56;
}
.sellerModal .scroll .boxWrapper2 > div:first-child {
  padding: 0 0 16px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  margin: 0 0 16px;
}
.sellerModal .scroll .boxWrapper2 div:first-child .box {
  width: 200px;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box input {
  width: 100%;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box:last-child {
  width: 224px;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box:last-child input {
  width: calc(100% - 88px - 12px);
}

.sellerModal .scroll .boxWrapper2 div:first-child .box:last-child #darkGrey {
  width: 88px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
}

.sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:first-child {
  width: 200px;
}

.sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:last-child {
  width: calc(100% - 200px - 40px);
}

.sellerModal .scroll .boxWrapper2 > div:not(:last-child) {
  margin: 0 0 17px;
}

.sellerModal .scroll .boxWrapper2 div .box input {
  width: 100%;
}

.sellerModal .scroll .boxWrapper2 div:nth-child(3) .box,
.sellerModal .scroll .boxWrapper2 div:nth-child(4) .box {
  width: calc(100% / 2 - 20px);
}

.sellerModal .scroll .boxWrapper3 > div:first-child,
.sellerModal .scroll .boxWrapper4 > div:first-child {
  margin: 0 0 17px;
}

.sellerModal .scroll .boxWrapper3 div:first-child .box,
.sellerModal .scroll .boxWrapper4 div:first-child .box {
  width: 200px;
}

.sellerModal .scroll .boxWrapper3 div .box input,
.sellerModal .scroll .boxWrapper4 div .box input {
  width: 100%;
}

.sellerModal .scroll .boxWrapper4 div:first-child .ant-select {
  width: 200px;
  height: 40px;
}

.sellerModal .scroll .boxWrapper4 div:last-child input {
  width: 212px;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content > p {
  font-size: 13px;
  margin: 0 16px;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content .inputRound {
  width: 40px;
  border-radius: 100%;
  margin: 0 16px 0 0;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content .round {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #000000;
  margin: 0 16px 0 0;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content .round:last-child {
  margin: 0 44px 0 0;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content #darkGrey {
  width: 88px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}

.sellerModal .scroll .btnBox #purpleBtn,
.sellerModal .scroll .btnBox #logoutBtn {
  width: 100px;
  height: 40px;
  margin: 0 5px;
  font-size: 14px;
  border-radius: 4px;
}

@media (max-width: 800px) {
  .sellerModal .scroll {
    max-height: none;
  }

  .sellerModal .check {
    justify-content: space-between;
  }

  .sellerModal .scroll .boxWrapper2 > div,
  .sellerModal .scroll .boxWrapper2 > div:first-child,
  .sellerModal .scroll .boxWrapper1,
  .sellerModal .scroll .boxWrapper2,
  .sellerModal .scroll .boxWrapper3,
  .sellerModal .scroll .boxWrapper4 {
    flex-direction: column;
    align-items: flex-start;
  }

  .sellerModal .scroll .boxWrapper3 > div:first-child,
  .sellerModal .scroll .boxWrapper4 > div:first-child {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }

  .sellerModal .scroll .boxWrapper4 > div:first-child input,
  .sellerModal .scroll .boxWrapper4 div:first-child .ant-select,
  .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:last-child,
  .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:first-child,
  .sellerModal .scroll .boxWrapper2 div:nth-child(3) .box,
  .sellerModal .scroll .boxWrapper2 div:nth-child(4) .box,
  .sellerModal .scroll .boxWrapper1 .box:last-child,
  .sellerModal .scroll .boxWrapper2 div:first-child .box:last-child {
    width: 100%;
  }

  .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:last-child,
  .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:first-child,
  .sellerModal .scroll .boxWrapper2 div:nth-child(3) .box,
  .sellerModal .scroll .boxWrapper2 div:nth-child(4) .box,
  .sellerModal .scroll .boxWrapper1 .box {
    margin: 0 0 11px;
  }

  .sellerModal .scroll .boxWrapper2 > div:not(:last-child),
  .sellerModal .scroll .boxWrapper1 .box:last-child {
    margin: 0 0 10px;
  }

  .sellerModal .scroll .boxWrapper3 div:first-child .box,
  .sellerModal .scroll .boxWrapper4 div:first-child .box,
  .sellerModal .scroll .boxWrapper2 div:first-child .box {
    width: 100%;
    margin: 0 0 10px;
  }

  .sellerModal .scroll .boxWrapper2 div:first-child .box:last-child input {
    width: calc(100% - 88px - 12px);
  }

  .sellerModal .scroll .boxWrapper4 > div:last-child > div > div {
    flex-wrap: wrap;
  }

  .sellerModal .scroll .boxWrapper4 div:last-child .box .content .inputRound {
    margin: 0 5px 0 0;
  }

  .sellerModal .scroll .boxWrapper4 div:last-child .box .content .round {
    width: 10px;
    height: 10px;
    margin: 0 5px 0 0;
  }

  .sellerModal .scroll .boxWrapper4 > div:last-child > div > div input {
    width: 40%;
  }

  .sellerModal .scroll .boxWrapper4 div:last-child .box .content #darkGrey {
    width: 100%;
    margin: 20px 0 0;
  }

  .sellerModal .scroll .btnBox {
    justify-content: space-between;
  }

  .sellerModal .scroll .btnBox #purpleBtn,
  .sellerModal .scroll .btnBox #logoutBtn {
    width: calc(50% - 8px);
  }
}

/* //////// */
/* //////// */
/* AIMODAL */
/* //////// */
/* //////// */

.aiModal .title {
  margin: 0 0 37px;
}
.aiModal .title h1 {
  font-size: 24px;
  font-weight: 700;
  color: #0e0e1f;
}
.aiModal .title p {
  font-size: 24px;
}
.aiModal .top {
  padding: 12px;
  border-radius: 16px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.05);
  align-items: flex-end;
  margin: 0 0 28px;
}
.aiModal .top .box {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #c2b8cb;
  height: 188px;
  width: calc(100% - 120px - 24px);
  background: #ffffff;
}
.aiModal .top .box .thumb {
  width: 123px;
}

.aiModal .top .box .thumb p {
  font-size: 12px;
  font-weight: 700;
  color: #464548;
  margin: 0 0 13px;
}

.aiModal .top .box .thumb .plusBox {
  width: 100%;
  height: 123px;
  border-radius: 4px;
  background: #f3f3f3;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.aiModal .top .box .thumb .plusBox .plus {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.1);
  font-size: 20px;
  color: #ffffff;
}

.aiModal .top .box .thumb .plusBox:hover {
  background: #f0dbf4;
}

.aiModal .top .box > div {
  width: calc(100% - 123px - 20px);
}

.aiModal .top .box > div .infoBox:not(:last-child) {
  width: 100%;
  margin: 0 0 12px;
}

.aiModal .top .box > div .infoBox p {
  font-size: 12px;
  font-weight: 700;
  color: #464548;
}

.aiModal .top .box > div .infoBox .ant-select {
  width: 300px;
  height: 40px;
}

.aiModal .top .box > div .infoBox input {
  height: 40px;
  width: 300px;
  border: 1px solid #e2dfe4;
  background: #f7f7f8;
  outline: none;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 13px;
}

.aiModal .top .box > div .infoBox input::placeholder {
  font-size: 13px;
  color: #a3a1a7;
}

.aiModal .top #purpleBtn {
  width: 120px;
  height: 40px;
  font-size: 14px;
  border-radius: 24px;
}

.aiModal .filter {
  margin: 0 0 16px;
}

.aiModal .filter .ant-select {
  border-radius: 4px;
  width: 120px;
  height: 40px;
}

.aiModal .filter > div {
  width: auto;
}

.aiModal .filter > div input {
  width: 140px;
  height: 40px;
  border: 1px solid #e2dfe4;
  background: #f7f7f8;
  border-radius: 4px;
  padding: 8px 12px;
  outline: none;
  font-size: 13px;
}

.aiModal .filter > div p {
  font-size: 13px;
  margin: 0 12px;
}

.aiModal .filter > div #purpleBtn {
  width: 40px;
  height: 40px;
  margin: 0 0 0 12px;
  font-size: 20px;
  border-radius: 4px;
}

.aiModal .contentTop {
  border-top: 2px solid #000000;
  background: #f3f2fa;
  height: 48px;
}

.aiModal .contentTop div {
  font-size: 14px;
  color: #323146;
  font-weight: 500;
}

.aiModal .scroll {
  max-height: 350px;
  justify-content: flex-start;
  overflow: auto;
}

.aiModal .contentTop div:nth-child(1),
.aiModal .scroll .content div:nth-child(1) {
  width: 180px;
}

.aiModal .contentTop div:nth-child(2),
.aiModal .contentTop div:nth-child(4),
.aiModal .scroll .content div:nth-child(2),
.aiModal .scroll .content div:nth-child(4) {
  width: 120px;
}

.aiModal .scroll .content div:nth-child(4) .tab {
  padding: 4px 8px;
  font-size: 13px;
  color: #ffffff;
  width: auto;
  border-radius: 50px;
}

.aiModal .scroll .content div:nth-child(4) .tab.success {
  background: #525162;
}

.aiModal .scroll .content div:nth-child(4) .tab.accept {
  background: #0fc9f3;
}

.aiModal .scroll .content div:nth-child(4) .tab.ing {
  background: #f8c930;
}

.aiModal .scroll .content div:nth-child(4) .tab.fail {
  background: #fe393c;
}

.aiModal .contentTop div:nth-child(3),
.aiModal .scroll .content div:nth-child(3) {
  width: 140px;
}

.aiModal .scroll .content div:nth-child(3) img {
  width: 80%;
}

.aiModal .contentTop div:nth-child(6),
.aiModal .scroll .content div:nth-child(6) {
  width: 80px;
}

.aiModal .scroll .content div:nth-child(6) img {
  width: 20px;
}

.aiModal .contentTop div:nth-child(5),
.aiModal .scroll .content div:nth-child(5) {
  width: calc(100% - 180px - 120px - 120px - 140px - 80px);
}

.aiModal .scroll .content {
  border-bottom: 1px solid #e2dfe4;
  padding: 8px 0;
}

.aiModal .scroll .content div:nth-child(5) {
  font-size: 16px;
  color: #464548;
}

.aiModal .scroll .content div {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}

/* MODAL */
.dataModal .title {
  margin: 0 0 20px;
}

.dataModal .title h1 {
  font-size: 24px;
  color: #0e0e1f;
  font-weight: 700;
}

.dataModal .title p {
  font-size: 24px;
}

.dataModal .boxWrapper {
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 40px 0;
  margin: 0 0 24px;
}

.dataModal .boxWrapper .box {
  width: 210px;
  height: 226px;
  padding: 20px;
}

.dataModal .boxWrapper .box div h1 {
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 8px;
}

.dataModal .boxWrapper .box div h1 span {
  font-size: 16px;
  font-weight: 400;
}

.dataModal .boxWrapper .box div h2 {
  font-size: 24px;
  color: #3e3d53;
  font-weight: 700;
}

.dataModal .boxWrapper .box#active {
  border: 1px solid #b041f3;
  border-radius: 40px;
  padding: 24px;
}

.dataModal .boxWrapper .box #greyBtn3,
.dataModal .boxWrapper .box #purpleBtn {
  width: 100%;
  height: 50px;
  border-radius: 24px;
}

.dataModal .boxWrapper .box .btn {
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.03);
  font-size: 16px;
  font-weight: 700;
  color: #4a4b50;
  border-radius: 24px;
}
@media (max-width: 700px) {
  .dataModal .boxWrapper {
    padding: 0;
    border: none;
    flex-wrap: wrap;
  }

  .dataModal .boxWrapper .box {
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 30px;
    height: auto;
    margin: 0 0 20px;
  }

  .dataModal .boxWrapper .box > div:first-child {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 10px;
  }

  .dataModal .boxWrapper .box > div h1 {
    margin: 0;
  }
}

.plan-content > p {
  font-size: 12px;
  color: #464548;
  margin: 20px 0 17px;
}

.plan-content .checkBox {
  margin: 0 0 16px;
}

.plan-content .checkBox .check {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #2687e9;
  font-size: 10px;
  color: #ffffff;
  margin: 0 10px 0 0;
}

.plan-content .checkBox p {
  font-size: 14px;
}

#pinkBtn3 > span {
  width: 100%;
  height: 50px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: none;
}

#pinkBtn3 > span:hover {
  background: linear-gradient(to right, #b041f3, #ec15ff);
}

#greyBtn6 {
  width: 100%;
  height: 50px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  background: rgba(0, 0, 0, 0.03);
  color: #a29fab;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#greyBtn6:hover {
  background: #a29fab;
  color: #000000;
}

.btnBox #darkGreyBorder2,
.btnBox #purpleBtn {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 5px;
}

/*================================
마이페이지 플랜정보 
================================*/
/* 공통 */
#plan {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 1600px;
}
#plan .btnBox {
  display: none;
}
#plan .tit {
  font-size: 16px;
  font-weight: 700;
  color: #3e3d53;
}

/* 첫번째 섹션 - 사용정보 */
#plan .topArea .box {
  display: flex;
  padding: 20px 0;
  border: 1px solid #dbdbe0;
  border-radius: 8px;
}
#plan .topArea .box > li {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: start;
}
#plan .topArea .box > li.row {
  flex-direction: row;
}
#plan .topArea .box > li.row > div {
  flex: 1;
}
#plan .topArea .box > li.row > div:last-child {
  padding-left: 20px;
  border-left: 1px solid #e0dbe4;
}
#plan .topArea .box > li:not(:last-child) {
  border-right: 1px solid #e0dbe4;
}
#plan .topArea .box > li h2 {
  font-size: 12px;
  font-weight: 700;
  color: #464548;
  margin-bottom: 20px;
}
#plan .topArea .box > li h2 span {
  color: #fe393c;
}
#plan .topArea .box > li strong {
  display: block;
  font-size: 28px;
  font-weight: 600;
  color: #000000;
}
/* 다음결제일에 있는 아이콘인듯 */
#plan .topArea .box > li:first-child > div img {
  width: 30px;
  margin: 0 0 0 70px;
}
#plan .topArea .box > li p {
  font-size: 12px;
  color: #0d88d4;
}
/* 쿠폰 사용중 뱃지인듯 */
#plan .topArea .box > li .coupon {
  width: auto;
  border-radius: 50px;
  padding: 4px 8px;
  border: 1px solid #fe393c;
  color: #f34144;
  font-size: 11px;
  margin: 0 0 0 4px;
}
#plan .topArea .box > li #pinkBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 24px;
  font-size: 14px;
  line-height: 1;
}

/* 세번째 섹션 - 플랜정보 */
#plan .planArea .switchBox {
  padding: 32px 0 40px;
}
#plan .planArea .switchBox .ant-switch-inner-checked {
  height: 36px;
  line-height: 36px;
}
#plan .planArea .switchBox .ant-switch-inner-unchecked {
  height: 10px;
  line-height: 10px;
}
#plan .planArea .switchBox .ant-switch.ant-switch-checked {
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
}
#plan .planArea .switchBox .ant-switch {
  width: 80px;
  height: 36px;
  margin: 0 16px 0 0;
  background: #0d88d4;
}
#plan .planArea .switchBox .ant-switch .ant-switch-handle {
  width: 26px;
  height: 26px;
  inset-inline-start: 4px;
  top: 5px;
}
#plan .planArea .switchBox .ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 30px) !important;
}
#plan .planArea .switchBox .ant-switch .ant-switch-handle::before {
  border-radius: 100%;
}
#plan .planArea .switchBox h1 {
  font-size: 24px;
  font-weight: 700;
  color: #323146;
}
#plan .planArea .switchBox p {
  margin-top: 16px;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
}
#plan .planArea .switchBox p span {
  color: #c62cff;
}
#plan .planArea .contentBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 56px;
}
#plan .planArea .contentBox .box {
  width: 388px;
  height: 520px;
  padding: 24px;
  padding-top: 40px;
  border-radius: 40px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
#plan .planArea .contentBox .box#active,
#plan .planArea .contentBox .box:hover {
  border: 1px solid #b041f3;
}
/* box Cont - top bottom 으로 나눔 */
#plan .planArea .contentBox .box .boxCont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
#plan .planArea .contentBox .box .boxCont .top h1 {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -1px;
  color: #3e3d53;
}
#plan .planArea .contentBox .box .boxCont .top h1 span {
  font-size: 16px;
  color: #524f56;
  margin-left: 8px;
}
#plan .planArea .contentBox .box .boxCont .top .planType {
  padding: 8px 0 20px;
  border-bottom: 1px dashed #524f56;
}
#plan .planArea .contentBox .box .boxCont .top .planType p {
  font-size: 20px;
  color: #3e3d53;
  font-weight: 700;
}
#plan .planArea .contentBox .box .boxCont .top .planType p span {
  font-weight: 500;
}
#plan .planArea .contentBox .box .boxCont .top .planType .tab {
  width: 50px;
  height: 30px;
  font-size: 12px;
  color: #ffffff;
  margin: 0 0 0 12px;
  border-radius: 20px;
}
#plan .planArea .contentBox .box .boxCont .top .planType .tab.beginner {
  background: #6d6c75;
}
#plan .planArea .contentBox .box .boxCont .top .planType .tab.basic {
  background: #f8ab30;
}
#plan .planArea .contentBox .box .boxCont .top .planType .tab.pro {
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  position: relative;
}
#plan .planArea .contentBox .box .boxCont .top .planType .tab.pro::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 20px;
  height: 32px;
  width: 52px;
  background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  z-index: -1;
}
#plan .planArea .contentBox .box .boxCont .bottom {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 12px;
  font-size: 14px;
}
#plan .planArea .contentBox .box .boxCont .bottom li {
  padding: 8px 0;
}
#plan .planArea .contentBox .box .boxCont .bottom li b {
  font-size: 12px;
  font-weight: 500;
  color: #464548;
}
#plan .planArea .contentBox .box .boxCont .bottom li .check {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border-radius: 100%;
  background: #2687e9;
  text-align: center;
  color: #ffffff;
}
#plan .planArea .contentBox .box .boxCont .bottom li .check span {
  scale: 0.7;
}
/* 버튼 */
#plan .planArea .contentBox .box #greyBtn6,
#plan .planArea .contentBox .box #pinkBtn3 {
  width: 100%;
  height: 50px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
}
#plan .planArea .contentBox .box .btn {
  width: 100%;
  height: 50px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.03);
  color: #4a4b50;
}

@media (max-width: 700px) {
  #plan {
    gap: 50px;
  }
  /* 첫번째 섹션 - 사용정보 */
  #plan .topArea .box {
    flex-direction: column;
    padding: 0 20px;
  }
  #plan .topArea .box > li {
    padding: 20px 0;
  }
  #plan .topArea .box > li:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #e0dbe4;
  }
  #plan .topArea .box > li h2 {
    margin-bottom: 0;
  }
  /* 두번째 섹션 - 쿠폰정보 그리드 */
  #plan .l-section__header {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }
  #plan .l-section__header > div {
    width: 100%;
    gap: 2%;
  }
  #plan .l-section__header > div:last-child {
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 0;
  }
  #plan .l-section__header > div:last-child label {
    width: 100%;
    margin-bottom: 10px;
  }
  #plan .l-section__header > div:last-child input.default {
    flex: 4;
  }
  #plan .l-section__header > div:last-child button {
    flex: 1;
  }
  /* 세번째 섹션 - 플랜정보 */
  #plan .topArea .box > li:nth-of-type(2) strong,
  #plan .topArea .box > li:nth-of-type(3) strong {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
  }
  #plan .planArea .switchBox {
    padding: 0 0 50px 0;
  }
  #plan .planArea .contentBox {
    flex-direction: column;
    gap: 30px;
  }
  #plan .planArea .contentBox .box {
    width: 100%;
    padding: 30px;
    height: auto;
    min-height: 500px;
  }
  #plan .planArea .contentBox .box .boxCont .top .planType {
    padding: 15px 0;
  }
}

/*================================
마이페이지 랭킹
================================*/
.rankingArea {
  width: 100%;
}
.rankingArea .aui-grid-body-panel {
  top: 0 !important;
}
.rankingArea__header {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 12px;
  border-bottom: 2px solid #464548;
}
.rankingArea__header .btnBox {
  display: flex;
  gap: 4px;
}
.rankingArea__header .inputBox {
  display: flex;
  gap: 4px;
  width: 100%;
}
.rankingArea__header .inputBox input {
  width: 250px;
}
.rankingArea__header .inputBox button {
  border-radius: 4px;
}
.rankingArea__header .inputBox .btn-searchIcon {
  width: 40px;
  height: 40px;
}
.rankingArea__header .inputBox .btn-reset {
  width: 40px;
  height: 40px;
  border-color: #6d6c75;
}
.rankingArea__header .inputBox .btn-block {
  padding: 8px 12px;
  margin-left: auto;
}
.rankingArea .modal .modalHeader {
  padding-bottom: 20px;
}
.rankingArea .modal .modalCont .inputArea {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
}
.rankingArea .modal .modalCont .inputArea input {
  width: 250px;
}
.rankingArea .modal .modalCont .inputArea > span {
  margin-right: 16px;
  font-size: 16px;
}
.rankingArea .modal .modalCont .inputArea .btn-searchIcon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.rankingArea .modal .modalCont .inputArea .btn-unblock {
  height: 40px;
  padding: 4px 12px;
  margin-left: auto;
}
.rankingArea .modal .modalCont .inputArea .ant-select {
  width: 120px;
}

@media (max-width: 700px) {
  .rankingArea__header {
    flex-direction: column;
    align-items: start;
    gap: 10px;
    padding-bottom: 30px;
  }
  .rankingArea__header .inputBox input {
    flex: 1;
  }
  .rankingArea__header .inputBox .btn-block {
    width: 40px;
    height: 40px;
    gap: 0;
    margin-left: 0;
    font-size: 0;
    color: transparent;
  }
  .rankingArea .modal .modalCont .inputArea {
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 4px;
    padding-top: 0;
  }
  .rankingArea .modal .modalCont .inputArea * {
    order: 1;
  }
  .rankingArea .modal .modalCont .inputArea input {
    width: calc(100% - 44px - 44px);
  }
  .rankingArea .modal .modalCont .inputArea > span {
    width: calc(100% - 4px - 120px);
    order: 2;
    margin-right: 0;
  }
  .rankingArea .modal .modalCont .inputArea .btn-unblock {
    width: 40px;
    margin-left: 0;
    gap: 0;
    font-size: 0;
  }
  .rankingArea .modal .modalCont .inputArea .ant-select {
    order: 3;
  }
}

/*================================
마이페이지 결제내역
================================*/
.payDetail .searchArea {
  display: flex;
  gap: 10px;
  padding-bottom: 12px;
  border-bottom: 2px solid #000;
}
@media (max-width: 700px) {
  .payDetail .searchArea {
    flex-wrap: wrap;
  }
  .payDetail .ant-select {
    width: 100% !important;
  }
  .payDetail .searchArea .dateBox {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .payDetail .searchArea .dateBox .ant-picker {
    flex: 1;
  }
}