@charset "UTF-8";
.flexColCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.flexColCenter img.uploadthum {
  position: absolute;
  /* top: 0; */
  left: 0;
  width: 100%;

  /* height: 100%;  비율 상관없이 가려지게*/
}
.flexColCenter img.uploadimg {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
}

.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.flexColAround {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.flexColBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flexRowStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.flexRowEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.flexRowAround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.flexRowBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flexWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flexWrapCenter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.rsWrapper {
  width: 1518px;
}

.s-container.admin .rsWrapper {
  width: inherit;
}

@media (max-width: 1518px) {
  .rsWrapper {
    width: 1280px;
    width: calc(100% - 32px);
  }
}
@media (max-width: 1280px) {
  .rsWrapper {
    width: 1100px;
    width: calc(100% - 32px);
  }
}
@media (max-width: 1100px) {
  .rsWrapper {
    width: 100%;
    padding: 0 16px;
  }
}
/* btn */
#pinkBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e94cf7;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #ffffff;
  border: 1px solid #e94cf7;
}

.btnDisable {
  background: grey !important;
  border: 1px solid #e94cf7 !important;
  cursor: auto !important;
}

#pinkBtn:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#pinkBtn div {
  width: auto;
  position: relative;
}
#pinkBtn div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#pinkBtn:hover img.activeImg {
  opacity: 1;
}

#purpleBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #b041f3, #ec15ff);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #ffffff;
  border: 1px solid transparent;
  gap: 8px;
  font-size: 16px;
}
#purpleBtn:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#purpleBtn div {
  width: auto;
  position: relative;
}
#purpleBtn div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#purpleBtn:hover img.activeImg {
  opacity: 1;
}

#whiteBorderBtn {
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#whiteBorderBtn:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}

.active#whiteBorderBtn {
  background: rgba(255, 255, 255, 0.5);
}

#whiteBorderBtn div {
  width: auto;
  position: relative;
}
#whiteBorderBtn div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#whiteBorderBtn:hover img.activeImg {
  opacity: 1;
}

#whiteBtn {
  width: 80px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #6d6c75;
  color: #000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
#whiteBtn div {
  width: auto;
  position: relative;
}
#whiteBtn div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#whiteBtn:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}

#whiteBtn2 {
  width: 80px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  color: #000000;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#whiteBtn2 div {
  width: auto;
  position: relative;
}
#whiteBtn2 div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#whiteBtn2:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}

#loginBtn {
  background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  cursor: pointer;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#loginBtn:hover {
  border: 1px solid #f138fc;
  background: transparent;
  color: #13d5df;
}
#loginBtn div {
  width: auto;
  position: relative;
}
#loginBtn div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#loginBtn:hover img.activeImg {
  opacity: 1;
}

#logoutBtn {
  background: #ffffff;
  border: solid 1px #efecf1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  outline: 0;
}
#logoutBtn:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#logoutBtn div {
  width: auto;
  position: relative;
}
#logoutBtn div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#logoutBtn:hover img.activeImg {
  opacity: 1;
}

#pinkBtn2 {
  border: solid 1px #d7d4db;
  background: #ffffff;
  color: #3e3d53;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#pinkBtn2.active,
#pinkBtn2:hover {
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  color: #ffffff;
  border: 1px solid transparent;
}
#pinkBtn2 div {
  width: auto;
  position: relative;
}
#pinkBtn2 div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#pinkBtn2:hover img.activeImg {
  opacity: 1;
}

#darkGrey {
  border: solid 1px #525162;
  background: #525162;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#darkGrey:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#darkGrey div {
  width: auto;
  position: relative;
}
#darkGrey div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#darkGrey:hover img.activeImg {
  opacity: 1;
}

#darkGrey2 {
  border: solid 1px #ccc;
  background: #ffffff;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#darkGrey2:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#darkGrey2 div {
  width: auto;
  position: relative;
}
#darkGrey2 div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#darkGrey2:hover img.activeImg {
  opacity: 1;
}

#darkGrey3 {
  background: #525162;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: none;
}
#darkGrey3:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#darkGrey3 div {
  width: auto;
  position: relative;
}
#darkGrey3 div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#darkGrey3:hover img.activeImg {
  opacity: 1;
}

#darkGreyBorder {
  border: 1px solid #3e3d53;
  background: transparent;
  color: #3e3d53;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#darkGreyBorder:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#darkGreyBorder div {
  width: auto;
  position: relative;
}
#darkGreyBorder div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#darkGreyBorder:hover img.activeImg {
  opacity: 1;
}

#blueBtn {
  background: #eaf2f8;
  color: #939ca7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: solid 1px rgba(0, 0, 0, 0.05);
}
#blueBtn:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#blueBtn div {
  width: auto;
  position: relative;
}
#blueBtn div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#blueBtn:hover img.activeImg {
  opacity: 1;
}

#greyBtn {
  border: solid 1px #a3a1a7;
  background: #a3a1a7;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#greyBtn:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#greyBtn div {
  width: auto;
  position: relative;
}
#greyBtn div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#greyBtn:hover img.activeImg {
  opacity: 1;
}

#greyBtn2 {
  border: solid 1px #ffffff;
  background: #ffffff;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#greyBtn2:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#greyBtn2 div {
  width: auto;
  position: relative;
}
#greyBtn2 div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#greyBtn2:hover img.activeImg {
  opacity: 1;
}

#greyBtn3 {
  border: solid 1px #f4f5f7;
  background: #f4f5f7;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#greyBtn3:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#greyBtn3 div {
  width: auto;
  position: relative;
}
#greyBtn3 div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#greyBtn3:hover img.activeImg {
  opacity: 1;
}

#greyBtn4 {
  border: solid 1px #f3f3f3;
  background: #f3f3f3;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#greyBtn4:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#greyBtn4 div {
  width: auto;
  position: relative;
}
#greyBtn4 div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#greyBtn4:hover img.activeImg {
  opacity: 1;
}

#greyBtn5 {
  border: solid 1px #e2dfe4;
  background: transparent;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#greyBtn5:hover {
  background: #e2dfe4;
}

#kakao {
  border: solid 1px #fee500;
  background: #fee500;
  color: #191919;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
}
#kakao:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#kakao div {
  width: auto;
  position: relative;
}
#kakao div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#kakao:hover img.activeImg {
  opacity: 1;
}

#naver {
  border: solid 1px #03cf5d;
  background: #03cf5d;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
}
#naver:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#naver div {
  width: auto;
  position: relative;
}
#naver div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#naver:hover img.activeImg {
  opacity: 1;
}

#facebook {
  border: solid 1px #3d5a98;
  background: #3d5a98;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
}
#facebook:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#facebook div {
  width: auto;
  position: relative;
}
#facebook div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#facebook:hover img.activeImg {
  opacity: 1;
}

#google {
  border: solid 1px #d7d7d7;
  background: #ffffff;
  color: #0e0e1f;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
}
#google:hover {
  border: 0;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
}
#google div {
  width: auto;
  position: relative;
}
#google div img.activeImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}
#google:hover img.activeImg {
  opacity: 1;
}

/* 스크롤바 */
::-webkit-scrollbar {
  display: none;
}

.scroll::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
  display: initial;
  height: 6px;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #8c8c8c;
  border-radius: 4px;
}

/* checkbox */
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-color: #ec15ff;
}

/* select */
.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #ec15ff !important;
}

.ant-select-selector:hover {
  border-color: #ec15ff !important;
}

/* radio */
.ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner,
:where(.css-dev-only-do-not-override-5wsri9).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #ec15ff;
  background-color: #ec15ff;
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #ec15ff !important;
}

/* RATE */
.ant-rate {
  color: #ffa514;
}
.ant-rate .ant-rate-star:not(:last-child) {
  margin: 0 2px;
}
.ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

/* checkbox */
.ant-rate .ant-rate-star:not(:last-child) {
  margin: 0 2px;
}

.ant-rate .ant-rate-star:not(:last-child) {
  margin: 0 2px;
}
.ant-rate.not-empty.index-0 li.ant-rate-star:nth-child(n + 2),
.ant-rate.not-empty.index-0.width-0 li.ant-rate-star:nth-child(1) {
  display: none;
}
.ant-rate.not-empty.index-1 li.ant-rate-star:nth-child(n + 3),
.ant-rate.not-empty.index-1.width-0 li.ant-rate-star:nth-child(2) {
  display: none;
}
.ant-rate.not-empty.index-2 li.ant-rate-star:nth-child(n + 4),
.ant-rate.not-empty.index-2.width-0 li.ant-rate-star:nth-child(3) {
  display: none;
}
.ant-rate.not-empty.index-3 li.ant-rate-star:nth-child(n + 5),
.ant-rate.not-empty.index-3.width-0 li.ant-rate-star:nth-child(4) {
  display: none;
}
.ant-rate.not-empty.index-4.width-0 li.ant-rate-star:nth-child(5) {
  display: none;
}
.ant-rate.index-0.width-1 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
.ant-rate.index-1.width-1 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
.ant-rate.index-2.width-1 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
.ant-rate.index-3.width-1 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
.ant-rate.index-4.width-1 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 10% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
.ant-rate.index-0.width-2 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
.ant-rate.index-1.width-2 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
.ant-rate.index-2.width-2 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
.ant-rate.index-3.width-2 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
.ant-rate.index-4.width-2 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 20% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
.ant-rate.index-0.width-3 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
.ant-rate.index-1.width-3 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
.ant-rate.index-2.width-3 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
.ant-rate.index-3.width-3 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
.ant-rate.index-4.width-3 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 30% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
.ant-rate.index-0.width-4 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
.ant-rate.index-1.width-4 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
.ant-rate.index-2.width-4 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
.ant-rate.index-3.width-4 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
.ant-rate.index-4.width-4 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 40% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
.ant-rate.index-0.width-5 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
.ant-rate.index-1.width-5 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
.ant-rate.index-2.width-5 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
.ant-rate.index-3.width-5 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
.ant-rate.index-4.width-5 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 50% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
.ant-rate.index-0.width-6 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
.ant-rate.index-1.width-6 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
.ant-rate.index-2.width-6 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
.ant-rate.index-3.width-6 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
.ant-rate.index-4.width-6 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 60% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
.ant-rate.index-0.width-7 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
.ant-rate.index-1.width-7 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
.ant-rate.index-2.width-7 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
.ant-rate.index-3.width-7 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
.ant-rate.index-4.width-7 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 70% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
.ant-rate.index-0.width-8 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
.ant-rate.index-1.width-8 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
.ant-rate.index-2.width-8 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
.ant-rate.index-3.width-8 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
.ant-rate.index-4.width-8 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 80% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
.ant-rate.index-0.width-9 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
.ant-rate.index-1.width-9 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
.ant-rate.index-2.width-9 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
.ant-rate.index-3.width-9 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
.ant-rate.index-4.width-9 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 90% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}

/* .ant-rate.not-empty .ant-rate-star-zero {
  display: none;
} */
.popover-btn {
  border: 1px solid #9254de;
  border-radius: 10px;
  padding: 10px;
  background-color: #9254de;
  color: #ffffff;
}

.prompt-detail-prev.ant-tooltip .ant-tooltip-inner {
  padding: 0;
  background-color: unset;
  box-shadow: unset;
}
.prompt-detail-prev.ant-tooltip .ant-tooltip-inner #engineWrapper {
  padding: 0;
}
.prompt-detail-prev.ant-tooltip .ant-tooltip-inner .flexColCenter {
  display: block;
}
.prompt-detail-prev.ant-tooltip .ant-tooltip-inner #engineWrapper .rsWrapper .listBox .gerneBox {
  margin: 0;
}
.prompt-detail-prev.ant-tooltip .ant-tooltip-inner .flexColBetween .play {
  display: flex;
}