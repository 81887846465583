#newPromptSlider {
    display: block;
    width: 100%;
}

#newPromptSlider .list {
    padding: 0 4px;
}


#newPromptSlider .list>div {
    padding: 4px;
    border-radius: 16px;
    position: relative;
}

#newPromptSlider .list>div>img {
    width: 100%;
    height: 185px;
    border-radius: 16px;
}

#newPromptSlider .list>div .absoluteBox {
    position: absolute;
    top: 4px;
    height: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 16px;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}

#newPromptSlider .list>div .absoluteBox .user {
    padding: 8px;
}

#newPromptSlider .list>div .absoluteBox .user div {
    width: auto;
}

#newPromptSlider .list>div .absoluteBox .user div img {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
}

#newPromptSlider .list>div .absoluteBox .user div p {
    font-size: 13px;
    color: #ffffff;
}

#newPromptSlider .list>div .absoluteBox .title>p {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin: 0 0 4px;
    padding: 0 10px;
}

#newPromptSlider .list>div .absoluteBox .title .greyBox {
    background: #E2E2E2;
    padding: 8px 12px;
    border-radius: 0 0 16px 16px;
}

#newPromptSlider .list>div .absoluteBox .title .greyBox>img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin: 0 8px 0 0;
}

#newPromptSlider .list>div .absoluteBox .title .greyBox>div {
    width: calc(100% - 32px - 8px);
}

#newPromptSlider .list>div .absoluteBox .title .greyBox div>p {
    font-size: 14px;
    font-weight: 600;
    color: #0E0E1F;
}

#newPromptSlider .list>div .absoluteBox .title .greyBox div div .imgBox {
    width: auto;
}

#newPromptSlider .list>div .absoluteBox .title .greyBox div div .imgBox img:first-child {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
}

#newPromptSlider .list>div .absoluteBox .title .greyBox div div .imgBox img {
    width: 16px;
    height: 16px;
    margin: 0 4px 0 0;
}

#newPromptSlider .list>div .absoluteBox .title .greyBox div div p {
    font-size: 13px;
    font-weight: 600;
}


#newPromptSlider .arrowLeft,
#newPromptSlider .arrowRight {
    position: absolute;
    width: 36px;
    margin: 0 4px 0 0;
    height: 36px;
    background: #ffffff;
    border-radius: 100%;
    font-size: 12px;
    color: #000000;
    cursor: pointer;
    transition: all .2s ease-in-out;
    z-index: 1000;
}

#newPromptSlider .arrowLeft:hover,
#newPromptSlider .arrowRight:hover {
    background: linear-gradient(to right, #B041F3, #EC15FF);
    color: #ffffff;
}

#newPromptSlider .arrowLeft {
    top: 50%;
    left: -40px;
    margin: -18px 0 0;
}

#newPromptSlider .arrowRight {
    top: 50%;
    right: -40px;
    margin: -18px 0 0;
}

#newPromptSlider .slick-dots {
    display: none !important;
}


@media (max-width : 700px) {
    #newPromptSlider .list>div>img {
        height: 136px;
    }

    #newPromptSlider .list>div .absoluteBox .title>p {
        display: none;
    }

    #newPromptSlider .list>div .absoluteBox .title .greyBox>img {
        width: 24px;
        height: 24px;
        margin: 0 4px 0 0;
    }

    #newPromptSlider .list>div .absoluteBox .title .greyBox>div {
        width: calc(100% - 24px - 4px);
    }

    #newPromptSlider .list>div .absoluteBox .title .greyBox div div .imgBox img:first-child {
        display: none;
    }

    #newPromptSlider .list>div .absoluteBox .user>img {
        display: none;
    }

    #newPromptSlider .list>div .absoluteBox .user {
        padding: 8px;
    }

    #newPromptSlider .list>div .absoluteBox .title .greyBox div>p {
        font-size: 12px;
    }

    #newPromptSlider .arrowLeft {
        left: -10px;
        background: rgba(255, 255, 255, 0.2);
    }

    #newPromptSlider .arrowRight {
        right: -10px;
        background: rgba(255, 255, 255, 0.2);

    }




    #newPromptSlider .slick-list {
        padding: 0 !important;
    }


}