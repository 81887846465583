/* 게시판 작성페이지 썸네일 */

#boardDetail .imageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  height: 200px;
  gap: 12px;
}
#boardDetail .imageContainer img {
  width: 24px;
  height: 24px;
  display: flex;
}
#boardDetail .imageContainer img.uploadthum {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#boardDetail .plusBox .imageContainer img.expandIcon,
#boardDetail .plusBox .imageContainer img.changeIcon,
#boardDetail .plusBox .imageContainer img.trashIcon {
  z-index: 99;
}
#boardDetail .plusBox .imageContainer img.uploadthum {
  transition: filter 0.3s ease-in-out;
  filter: brightness(1);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#boardDetail .plusBox:hover .imageContainer img.expandIcon,
#boardDetail .plusBox:hover .imageContainer img.changeIcon,
#boardDetail .plusBox:hover .imageContainer img.trashIcon {
  opacity: 1;
}
#boardDetail .plusBox:hover .imageContainer img.uploadthum {
  filter: brightness(0.7);
}
#boardDetail .plusBox .ant-upload-disabled .imageContainer img {
  cursor: not-allowed;
}
#boardDetail .content:has(.flexColStart.box) .box.image .info {
  overflow: auto;
}
#boardDetail .info .ant-upload-wrapper.fileBtnBox .ant-upload-drag {
  height: auto;
}
#boardDetail .info .ant-upload-wrapper.fileBtnBox .ant-upload-drag .ant-upload-btn {
  padding: 0;
}
#boardDetail .info .ant-upload-wrapper.fileBtnBox .ant-upload-disabled button {
  cursor: not-allowed;
}
