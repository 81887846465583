#myPage {
  padding: 0 0 50px;
}

#myHome > .top {
  position: relative;
  min-height: 320px;
  padding: 27px 0;
  background: #efefef;
  /* background: linear-gradient(to bottom, rgb(129 204 208), rgb(134 159 208)); */
}

/* #myHome > .top:has(img) {
  background: rgba(0, 0, 0, 0.3);
} */

#myHome > .top > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  /* display: none; */
}

#myHome > .top > .rsWrapper > .profile {
  width: 320px;
  padding: 16px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.5);
}

#myHome > .top > .rsWrapper > .profile > .topProfile .thumb {
  width: 80px;
  height: 80px;
  border-radius: 16px;
  padding: 0;
}

#myHome > .top > .rsWrapper > .profile > .topProfile .thumb img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

#myHome > .top > .rsWrapper > .profile > .topProfile > div {
  width: calc(100% - 80px);
  padding: 0 0 0 16px;
}

#myHome > .top > .rsWrapper > .profile > .topProfile > div .gradeBox {
  margin: 0 0 4px;
}

#myHome > .top > .rsWrapper > .profile > .topProfile > div .gradeBox div {
  width: auto;
}

#myHome > .top > .rsWrapper > .profile > .topProfile > div .gradeBox div .grade {
  width: auto;
  font-size: 12px;
  color: #ffffff;
  width: 50px;
  height: 30px;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  border-image-slice: 1;
  background-image: linear-gradient(to right, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin: 0 8px 0 0;
}

#myHome > .top > .rsWrapper > .profile > .topProfile > div .gradeBox > div:first-child > img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

/* #myHome > .top > .rsWrapper > .profile > .topProfile > div .gradeBox > div:nth-child(2) img {
  width: 15px;
  margin: 0 0 0 12px;
} */

/* #myHome > .top > .rsWrapper > .profile > .topProfile > div h1 {
  color: #ffffff;
  font-size: 22px;
  margin: 0 0 4px;
} */

#myHome > .top > .rsWrapper > .profile > .majorModel {
  padding: 8px 0 16px 0;
}

#myHome > .top > .rsWrapper > .profile > .topProfile > div h2 {
  font-size: 11px;
  color: #ffffff;
}
#myHome > .top > .rsWrapper > .profile .bottom {
  position: relative;
  justify-content: start;
  gap: 10px;
}

#myHome > .top > .rsWrapper > .profile .bottom > div {
  width: auto;
}

#myHome > .top > .rsWrapper > .profile .bottom div > img {
  width: 18px;
}

#myHome > .top > .rsWrapper > .profile .bottom > div > p {
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  margin: 0 7px;
}

#myHome > .top > .rsWrapper > .profile .bottom .absoluteBox {
  position: absolute;
  top: 30px;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #c718e2;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  width: 110px;
  z-index: 10;
}

#myHome > .top > .rsWrapper > .profile .bottom .absoluteBox div {
  height: 34px;
  /* margin: 0 0 4px; */
  border-radius: 4px;
  background: #f7f7f8;
  padding: 0 12px;
}

#myHome > .top > .rsWrapper > .profile .bottom .absoluteBox div:nth-child(3) {
  margin: 0;
}

#myHome > .top > .rsWrapper > .profile .bottom .absoluteBox div img {
  width: 18px;
  margin: 0 5px 0 0;
}

#myHome > .top > .rsWrapper > .profile .bottom .absoluteBox div p {
  font-size: 13px;
  font-weight: 600;
  color: #0e0e1f;
}

#myHome > .top > .rsWrapper > .profile .bottom > div > h3 {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  margin: 0 8px 0 0;
}

#myHome > .top > .rsWrapper > .profile .bottom > div > h2 {
  font-size: 14px;
  color: #87858b;
  margin: 0 0 0 8px;
}

#myHome > .top > .rsWrapper > .profile .bottom > div > h2 span {
  border-bottom: 1px solid #87858b;
}

#myHome > .top > .rsWrapper > .title {
  width: calc(100% - 320px);
  padding: 0 0 0 40px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

#myHome > .top > .rsWrapper > .title h1 {
  font-size: 42px;
  color: #ffffff;
  font-weight: 500;
  /* margin: 0 0 8px; */
  line-height: 1.4;
  letter-spacing: -0.2px;
}

#myHome > .top > .rsWrapper > .title h2 {
  font-size: 20px;
  color: #ffffff;
  margin: 0 0 20px;
  font-weight: 300;
  letter-spacing: -0.2px;
}

#myHome .filter {
  background: linear-gradient(to top, #f3f3f3, #eee), linear-gradient(to bottom, #f3f3f3, #f3f3f3);
  padding: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

#myHome .filter .rsWrapper .listBox .title {
  width: 152px;
  gap: 16px;
  margin: 0;
}

#myHome .filter .rsWrapper .listBox .title h2 {
  font-size: 16px;
}

#myHome .filter .rsWrapper .listBox .title img {
  width: 18px;
}

#myHome .filter .rsWrapper .listBox .title p {
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
  margin: 0 0 0 20px;
}

#myHome .searchBox .rsWrapper .text {
  width: auto;
}

#myHome .searchBox .rsWrapper .text img {
  width: 32px;
  margin: 0 0 14px;
}

#myHome .searchBox .rsWrapper .text p {
  background-image: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  font-family: Pretendard;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

#myHome .searchBox {
  height: 135px;
  margin: 0 0 24px;
}

#myHome .searchBox .rsWrapper .search {
  width: 471px;
  height: 38px;
  padding: 8px 16px;
  border-radius: 50px;
  position: relative;
  background: #ffffff;
  margin: 0 120px;
}

#myHome .searchBox .rsWrapper .search:after {
  z-index: -1;
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  width: 480px;
  height: 46px;
  background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  border-radius: 50px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

#myHome .searchBox .rsWrapper .search img {
  width: 20px;
}

#myHome .searchBox .rsWrapper .search input {
  width: calc(100% - 20px);
  padding: 0 20px;
  height: 100%;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 16px;
  color: #000000;
}

#myHome .searchBox .rsWrapper .search input::placeholder {
  color: #a3a1a7;
  font-size: 15px;
}

#myHome .rsWrapper .promptBox {
  position: relative;
  margin: 0 0 30px;
}

#myHome .rsWrapper .promptBox .line {
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  margin: -0.5px 0 0;
  width: 100%;
}

#myHome .rsWrapper .promptBox > div {
  padding: 0 24px;
  background: #ffffff;
  z-index: 2;
  width: auto;
}

#myHome .rsWrapper .promptBox > div img {
  width: 20px;
}

#myHome .rsWrapper .promptBox > div img.new {
  width: 32px;
}

#myHome .rsWrapper .promptBox > div p {
  font-size: 20px;
  margin: 0 12px;
  color: #3e3d53;
  font-weight: 700;
}

#myHome .rsWrapper .promptBox > div #greyBtn2 {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  font-size: 12px;
}

#myHome .rsWrapper .slide {
  margin: 0 0 52px;
}

#myHome .rsWrapper > .box {
  flex-wrap: wrap;
}

#myHome .rsWrapper > .box .ant-card {
  width: calc(100% / 6 - 9px);
  margin: 0 10px 20px 0;
}

#myHome .rsWrapper > .box .ant-card:nth-child(6n) {
  margin: 0 0 20px;
}

#myHome .rsWrapper > .box .list > div {
  /* padding: 4px; */
  position: relative;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}

#myHome .rsWrapper > .box .list > div > img {
  width: 100%;
  height: 127px;
}

#myHome .rsWrapper > .box .list > div .absoluteBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}

#myHome .rsWrapper > .box .list > div .absoluteBox .user {
  padding: 8px;
}

#myHome .rsWrapper > .box .list > div .absoluteBox .user div {
  width: auto;
}

#myHome .rsWrapper > .box .list > div .absoluteBox .user div img {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
}

#myHome .rsWrapper > .box .list > div .absoluteBox .user div p {
  font-size: 13px;
  color: #ffffff;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

#myHome .rsWrapper > .box .list > div .absoluteBox .title > p {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin: 0 0 4px;
  padding: 0 10px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

#myHome .rsWrapper > .box .list .greyBox {
  background: #e2e2e2;
  padding: 8px 12px;
  border-radius: 0 0 16px 16px;
}

#myHome .rsWrapper > .box .list .greyBox > img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}

#myHome .rsWrapper > .box .list .greyBox > div {
  width: calc(100% - 32px - 8px);
}

#myHome .rsWrapper > .box .list .greyBox div > p {
  font-size: 14px;
  font-weight: 600;
  color: #0e0e1f;
}

#myHome .rsWrapper > .box .list .greyBox div div .imgBox {
  width: auto;
}

#myHome .rsWrapper > .box .list .greyBox div div .imgBox img:first-child {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
}

#myHome .rsWrapper > .box .list .greyBox div div .imgBox img {
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
}

#myHome .rsWrapper > .box .list .greyBox div div p {
  font-size: 13px;
  font-weight: 600;
}

#modal .top {
  margin: 0 0 35px;
}

#modal .top h1 {
  font-size: 24px;
  font-weight: 700;
  color: #0e0e1f;
}

#modal .top p {
  font-size: 24px;
}

#modal .filter {
  padding: 0 0 12px;
  border-bottom: 2px solid #464548;
}

#modal .filter div {
  width: auto;
}

#modal .filter div #pinkBtn2 {
  width: auto;
  min-width: 80px;
  padding: 8px 12px;
  height: 33px;
  margin: 0 4px 0 0;
  border-radius: 24px;
}

#modal .filter div input {
  width: 140px;
  height: 40px;
  border: 1px solid #e2dfe4;
  background: #f7f7f8;
  padding: 0 12px;
  border-radius: 4px;
}

#modal .filter div p {
  font-size: 13px;
  margin: 0 12px;
}

#modal .filter div #pinkBtn {
  width: 40px;
  height: 40px;
  margin: 0 0 0 12px;
  border-radius: 4px;
  font-size: 14px;
}

#modal .header {
  height: 48px;
  background: #f3f2fa;
}

#modal .list {
  overflow: auto;
  height: 550px;
  justify-content: flex-start;
}

#modal .list .scroll {
  justify-content: flex-start;
  flex-wrap: nowrap;
}

#modal .list .scroll .listBox {
  height: 64px;
  border-bottom: solid 1px #e2dfe4;
}

#modal .header div {
  font-size: 14px;
  color: #323146;
  font-weight: 600;
}

#modal .list .scroll .listBox div {
  font-size: 13px;
  color: #000000;
}

#modal .header div:nth-child(1),
#modal .list .scroll .listBox div:nth-child(1) {
  width: 80px;
}

#modal .header div:nth-child(2),
#modal .list .scroll .listBox div:nth-child(2) {
  width: 160px;
}

#modal .header div:nth-child(3),
#modal .list .scroll .listBox div:nth-child(3) {
  width: 200px;
}

#modal .header div:nth-child(4),
#modal .list .scroll .listBox div:nth-child(4) {
  width: calc(100% - 80px - 160px - 200px);
}

:where(.css-dev-only-do-not-override-apn68).ant-radio-wrapper .ant-radio-inner {
  width: 20px;
  height: 20px;
}

.ant-radio-wrapper .ant-radio-inner::after {
  width: 20px;
  height: 20px;
  margin-block-start: -10px;
  margin-inline-start: -10px;
}

#modal .filter div input {
  position: relative;
  /* background-image: url(../img/date.svg); */
  background-repeat: no-repeat;
  background-position: right 10px center;
}

#modal .filter div input[type='date']::-webkit-calendar-picker-indicator,
#modal .filter div input[type='date']::-webkit-inner-spin-button {
  opacity: 0;
  appearance: none;
}

@media (max-width: 1280px) {
  #myHome .rsWrapper > .box .ant-card {
    width: calc(100% / 5 - 9px);
  }

  #myHome .rsWrapper > .box .ant-card:nth-child(6n) {
    margin: 0 9px 20px 0;
  }

  #myHome .rsWrapper > .box .ant-card:nth-child(5n) {
    margin: 0 0 20px;
  }
}

@media (max-width: 900px) {
  #myPage > div > .title .overflowBox div h1 {
    font-size: 24px;
  }

  #myHome .searchBox .rsWrapper .search {
    margin: 0 30px;
  }

  #myHome .rsWrapper > .box .ant-card {
    width: calc(100% / 2 - 9px);
  }

  #myHome .rsWrapper > .box .ant-card:nth-child(6n),
  #myHome .rsWrapper > .box .ant-card:nth-child(5n) {
    margin: 0 9px 20px 0;
  }

  #myHome .rsWrapper > .box .ant-card:nth-child(2n) {
    margin: 0 0 20px;
  }
}

@media (max-width: 700px) {
  #myPage > div > .title {
    justify-content: flex-start;
  }

  #myPage > div > .title .overflowBox {
    justify-content: flex-start;
  }

  #myHome > .top {
    height: auto;
    padding: 20px 7px;
  }

  #myHome > .top > .rsWrapper,
  #myHome > .top > .rsWrapper > .profile > .topProfile {
    flex-wrap: wrap;
    flex-direction: column;
  }

  #myHome > .top > .rsWrapper > .profile {
    width: 100%;
  }
  #myHome > .top > .rsWrapper > .profile .mobile > div {
    gap: 8px;
  }
  #myHome > .top > .rsWrapper > .profile .mobile img {
    width: 18px;
  }

  #myHome > .top > .rsWrapper > .title {
    width: 100%;
    padding: 0;
    align-items: center;
  }

  #myHome > .top > .rsWrapper > .title h1 {
    font-size: 24px;
  }

  #myHome > .top > .rsWrapper > .title h2 {
    font-size: 13px;
    margin: 0 0 20px;
  }

  #myHome > .top > .rsWrapper > .profile > .topProfile .thumb {
    width: 100%;
    margin: 0 0 20px;
  }

  #myHome > .top > .rsWrapper > .profile > .topProfile .thumb img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
  }

  #myHome > .top > .rsWrapper > .profile > .topProfile h1 {
    font-size: 18px;
    color: #ffffff;
    margin: 0 0 10px;
  }

  #myHome > .top > .rsWrapper > .profile > .topProfile > div {
    width: auto;
    padding: 0;
  }

  /* #myHome > .top > .rsWrapper > .profile > .topProfile > div .gradeBox p {
    font-size: 11px;
    color: #ffffff;
    margin: 0 0 0 4px;
  } */

  #myHome > .top > .rsWrapper > .profile > .topProfile > div .gradeBox div .grade {
    width: 34px;
    height: 18px;
    font-size: 10px;
  }

  #myHome > .top > .rsWrapper > .profile > .topProfile > div .gradeBox > div:first-child > img {
    width: 15px;
    height: 15px;
  }
  /* 팔로잉 리스트 - design.css 기반 */
  #myHome > .top > .rsWrapper > .profile .followBox > li {
    flex-direction: column;
    flex: 1;
  }

  #myHome > .top > .rsWrapper > .profile .followBox > li > p {
    order: 2;
    font-size: 12px;
  }
  #myHome > .top > .rsWrapper > .profile .followBox > li > span {
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
  }

  #myHome > .top > .rsWrapper > .profile .followBox .mobile {
    position: relative;
  }

  #myHome > .top > .rsWrapper > .profile .followBox .mobile p > img {
    width: 15px;
    margin: 0 0 0 5px;
  }

  #myHome > .top > .rsWrapper > .profile .followBox .mobile .absoluteBox {
    position: absolute;
    top: 50px;
    left: 0;
    background-color: #ffffff;
    border: 1px solid #c718e2;
    border-radius: 4px;
    padding: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    width: 110px;
    z-index: 10;
  }

  #myHome > .top > .rsWrapper > .profile .followBox .mobile .absoluteBox div {
    height: 34px;
    margin: 0 0 4px;
    border-radius: 4px;
    background: #f7f7f8;
    padding: 0 12px;
  }

  #myHome > .top > .rsWrapper > .profile .followBox .mobile .absoluteBox div:nth-child(3) {
    margin: 0;
  }

  #myHome > .top > .rsWrapper > .profile .followBox .mobile .absoluteBox div img {
    width: 18px;
    margin: 0 5px 0 0;
  }

  #myHome > .top > .rsWrapper > .profile .followBox .mobile .absoluteBox div p {
    font-size: 13px;
    font-weight: 600;
    color: #0e0e1f;
  }

  #myHome .filter {
    background: #ffffff;
  }

  #myHome .filter .rsWrapper .listBox {
    flex-direction: column;
    align-items: flex-start;
  }

  #myHome .filter .rsWrapper .listBox .title {
    margin: 0 0 16px;
  }

  #myHome .filter .rsWrapper .listBox .title img {
    margin: 0 20px 0 0;
  }

  #myHome .filter .rsWrapper .listBox .ant-radio-wrapper span.ant-radio + * {
    padding: 0 0 0 8px;
  }

  #myHome .searchBox .rsWrapper:has(.text) {
    flex-direction: row;
  }

  #myHome .searchBox .rsWrapper .text {
    width: 50%;
  }

  #myHome .searchBox {
    height: auto;
    margin: 0 0 40px;
  }

  #myHome .searchBox .rsWrapper {
    flex-wrap: wrap;
  }

  #myHome .searchBox .rsWrapper .search {
    width: calc(100% - 8px);
    margin: 20px 0 36px;
  }

  #myHome .searchBox .rsWrapper .search:after {
    width: calc(100% + 8px);
  }

  #myHome .searchBox .rsWrapper .search input {
    padding: 0 0 0 17px;
  }

  #myHome .rsWrapper > .box .ant-card {
    width: calc(100%);
    margin: 0 0 15px;
  }

  #myHome .rsWrapper > .box .ant-card:nth-child(6n),
  #myHome .rsWrapper > .box .ant-card:nth-child(5n),
  #myHome .rsWrapper > .box .ant-card:nth-child(2n) {
    margin: 0 0 15px;
  }

  #modal .filter {
    flex-wrap: wrap;
    margin: 0 0 30px;
    padding: 0 0 30px;
  }

  #modal .filter div {
    width: 100%;
    justify-content: flex-start;
    margin: 0 0 8px;
  }

  #modal .filter div input {
    width: 130px;
  }

  #modal .filter div p {
    margin: 0 10px;
  }

  #modal .top {
    margin: 18px 0 35px;
  }

  #modal .filter div #pinkBtn {
    width: 42px;
    height: 40px;
    margin: 0 0 0 10px;
  }

  #modal .header {
    height: 40px;
    border-radius: 10px;
  }

  #modal .header div {
    font-size: 13px;
  }

  #modal .list .scroll .listBox div {
    font-size: 14px;
  }

  #modal .list .scroll .listBox div:nth-child(3) {
    font-size: 12px;
  }

  #modal .header div:nth-child(1),
  #modal .list .scroll .listBox div:nth-child(1) {
    width: 50px;
  }

  #modal .header div:nth-child(2),
  #modal .list .scroll .listBox div:nth-child(2) {
    width: calc((100% - 50px - 120px) / 2);
  }

  #modal .header div:nth-child(3),
  #modal .list .scroll .listBox div:nth-child(3) {
    width: 120px;
  }

  #modal .header div:nth-child(4),
  #modal .list .scroll .listBox div:nth-child(4) {
    width: calc((100% - 50px - 120px) / 2);
  }

  #modal .list {
    height: auto;
  }

  #myHome .filter .rsWrapper .listBox .title h2 {
    font-size: 16px;
    font-weight: 700;
    color: #0e0e1f;
  }
}
