@charset "UTF-8";
#aiWrapper {
  margin: 58px 0 0;
}
#aiWrapper > h1 {
  font-size: 48px;
  font-weight: 700;
  background: linear-gradient(to right, #13d5df, #f138fc);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  margin: 0 0 40px;
}
#aiWrapper > h1 span {
  font-size: 40px;
}
#aiWrapper .titleBox {
  margin: 0 0 10px;
}
#aiWrapper .titleBox::after {
  content: '';
  width: 48px;
}
#aiWrapper .titleBox .title {
  width: auto;
  margin-top: 0;
}
#aiWrapper .titleBox .title img {
  width: 24px;
  margin: 0 12px 0 0;
}
#aiWrapper .titleBox .title p {
  font-size: 24px;
  color: #323146;
  font-weight: 700;
}
#aiWrapper .titleBox > div:nth-child(2) {
  width: auto;
}
#aiWrapper .titleBox .tabBox {
  width: auto;
  padding: 8px;
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-radius: 50px;
}
#aiWrapper .titleBox .tabBox .tab {
  padding: 8px 24px;
  width: auto;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  border-radius: 50px;
  margin: 0 2px;
}
#aiWrapper .titleBox .tabBox .tab#active,
#aiWrapper .titleBox .tabBox .tab:hover {
  background: #ffffff;
  color: #464548;
}
#aiWrapper .slide {
  flex-wrap: wrap;
  margin: 0 0 20px;
  padding: 0 40px;
  position: relative;
}
#aiWrapper .slide .arrowLeft,
#aiWrapper .slide .arrowRight {
  position: absolute;
  width: 36px;
  margin: 0 4px 0 0;
  height: 36px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  font-size: 12px;
  color: #000000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 100;
}
#aiWrapper .slide .arrowLeft {
  top: 50%;
  left: 0;
  margin: -18px 0 0;
}
#aiWrapper .slide .arrowRight {
  top: 50%;
  right: 0;
  margin: -18px 0 0;
}
#aiWrapper .slide .arrowLeft:hover,
#aiWrapper .slide .arrowRight:hover {
  background: linear-gradient(to right, #b041f3, #ec15ff);
  color: #ffffff;
}
#aiWrapper .slide .imageWrapper {
  width: 156px;
  height: 156px;
  border-radius: 100%;
  margin: 0 -26px;
  overflow: hidden;
  position: relative;
}
#aiWrapper .slide .imageWrapper img {
  width: 100%;
  height: 100%;
}
#aiWrapper .slide .imageWrapper .info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #13d5df, #f138fc);
  opacity: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#aiWrapper .slide .imageWrapper .activeIcon {
  opacity: 0;
}
#aiWrapper .slide .imageWrapper#active .activeIcon {
  opacity: 1;
}
#aiWrapper .slide .imageWrapper .activeIcon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 20;
  transition: all 0.2s ease-in-out;
}
#aiWrapper .slide .imageWrapper .activeIcon .round {
  width: 92px;
  height: 92px;
  background: #1e1e1e;
  border-radius: 100%;
}
#aiWrapper .slide .imageWrapper .activeIcon img {
  width: 33px;
  height: auto;
}
#aiWrapper .slide .imageWrapper:not(#active):hover .info {
  opacity: 0.6;
}
#aiWrapper .slide .imageWrapper .info img {
  width: 20px;
  height: 20px;
  margin: 0 0 10px;
  border-radius: 100%;
}
#aiWrapper .slide .imageWrapper .info h1 {
  font-size: 14px;
  color: #ffffff;
  margin: 0 0 8px;
  text-align: center;
}
#aiWrapper .slide .imageWrapper .info p {
  font-size: 12px;
  color: #ffffff;
}
#aiWrapper .slide .imageWrapper#active {
  z-index: 10;
  width: 240px;
  height: 240px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
}
#aiWrapper .slide .imageWrapper:nth-child(7) {
  z-index: 9;
}
#aiWrapper .slide .imageWrapper:nth-child(8) {
  z-index: 8;
}
#aiWrapper .slide .imageWrapper:nth-child(9) {
  z-index: 7;
}
#aiWrapper .slide .imageWrapper:nth-child(10) {
  z-index: 6;
}
#aiWrapper .slide .imageWrapper:nth-child(11) {
  z-index: 5;
}
#aiWrapper .slide .imageWrapper:nth-child(12) {
  z-index: 4;
}
#aiWrapper .slide .imageWrapper:nth-child(13) {
  z-index: 3;
}

.mainIcon {
  width: 150px;
  margin: -100px 0 -20px;
  z-index: 10;
}
#musicInfo {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  height: 400px;
  width: 100%;
  margin: -60px 0 0;
  margin: -46px 0 0;
}
#musicInfo .rsWrapper {
  align-items: flex-end;
}
#musicInfo .rsWrapper .thumbBox {
  width: 400px;
  height: 100%;
  position: relative;
  box-shadow: 7px 0 2px 0 rgba(0, 0, 0, 0.2);
}
#musicInfo .rsWrapper .thumbBox .absolBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}
#musicInfo .rsWrapper .thumbBox .absolBox > div {
  padding: 0 12px;
}
#musicInfo .rsWrapper .thumbBox .absolBox > div > div {
  width: auto;
}
#musicInfo .rsWrapper .thumbBox .absolBox > div > div > img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}
#musicInfo .rsWrapper .thumbBox .absolBox > div div p {
  font-size: 13px;
  color: #ffffff;
}
#musicInfo .rsWrapper .thumbBox .absolBox > div > img {
  width: 36px;
  width: 48px;
}
#musicInfo .rsWrapper .thumbBox .absolBox .name {
  padding: 0 12px 15px;
  color: #ffffff;
  font-size: 14px;
  min-height: 48px;
}
#musicInfo .rsWrapper .thumbBox .thumb {
  width: 100%;
  height: 100%;
}
#musicInfo .rsWrapper .info {
  width: calc(100% - 400px - 40px);
  padding: 0 0 34px;
  height: auto;
}
#musicInfo .rsWrapper .info > h1 {
  font-size: 32px;
  color: #ffffff;
  font-weight: 500;
  margin: 0 0 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
}
#musicInfo .rsWrapper .info > div {
  height: 200px;
}
#musicInfo .rsWrapper .info div .userBox {
  width: calc(50% - 52px);
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 400px;
}
#musicInfo .rsWrapper .info div .userBox .userInfo {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 16px;
}
#musicInfo .rsWrapper .info div .userBox .userInfo figure {
  width: 80px;
  height: 80px;
  background: #3e3d53;
  border-radius: 16px;
  overflow: hidden;
}
#musicInfo .rsWrapper .info div .userBox .userInfo figure img {
  width: 80px;
  height: 80px;
  margin: 0;
  cursor: pointer;
  background: #fff;
}
#musicInfo .rsWrapper .info div .userBox .userInfo > img {
  width: 80px;
  height: 80px;
  margin: 0 15px 0 0;
  cursor: pointer;
  background: #fff;
  border-radius: 16px;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox {
  width: calc(100% - 80px - 15px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox > div {
  margin: 0 0 12px;
  margin: 0;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox .userArea {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox .userArea .badge {
  width: auto;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox .userArea p {
  width: 100%;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox div .badge {
  min-width: 50px;
  width: auto;
  height: 30px;
  /* background-image: linear-gradient(to right, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%); */
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  margin: 0 10px 0 0;
  padding: 2px;
}

#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox div > p {
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox div .follow {
  width: auto;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox div .follow img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 10px 0 0;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox div .follow p {
  font-size: 16px;
  color: #ffffff;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox div .star {
  width: auto;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox div .star :where(.css-dev-only-do-not-override-1x0dypw).ant-rate .ant-rate-star:not(:last-child) {
  margin-inline-end: 2px;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox div .star img {
  width: 24px;
  margin: 0 0 0 4px;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox .appraisal {
  display: flex;
  flex-direction: row;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox .appraisal img {
  width: 16px;
  margin: 0 10px 0 0;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox .ant-dropdown-hidden p {
  color: #000;
}
#musicInfo .rsWrapper .info div .userBox .btnBox {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}
#musicInfo .rsWrapper .info div .userBox .btnBox #whiteBorderBtn {
  width: 130px;
  height: 40px;
  border-radius: 8px;
}
/* #musicInfo .rsWrapper .info div .userBox .btnBox #whiteBorderBtn.active {
  border: transparent;
  background: rgba(0, 0, 0, 0.1);
} */
#musicInfo .rsWrapper .info div .userBox .btnBox #whiteBorderBtn p {
  font-size: 14px;
}
#musicInfo .rsWrapper .info div .userBox .btnBox #whiteBorderBtn img {
  width: 16px;
  margin: 0 10px 0 0;
}
#musicInfo .rsWrapper .info div .contentsBox {
  height: 200px;
  padding: 17px 28px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  width: 50%;
  width: calc(100% - 452px);
  justify-content: space-between;
}
#musicInfo .rsWrapper .info div .contentsBox > div:first-child {
  margin: 0 0 20px;
  cursor: pointer;
  width: auto;
}
#musicInfo .rsWrapper .info div .contentsBox > div h1 {
  font-size: 16px;
  color: #ffffff;
  margin: 0 23px 0 0;
  font-weight: 700;
}
#musicInfo .rsWrapper .info div .contentsBox > div h1 span {
  font-weight: 500;
}
#musicInfo .rsWrapper .info div .contentsBox > div p {
  font-size: 12px;
  color: #ffffff;
}
#musicInfo .rsWrapper .info div .contentsBox .musicBox {
  width: 100%;
  display: flex;
  gap: 17.7px;
  overflow: auto;
}

#musicInfo .rsWrapper .info div .contentsBox .musicBox .music {
  width: 100px;
  margin: 0;
  position: relative;
  cursor: pointer;
}
#musicInfo .rsWrapper .info div .contentsBox .musicBox .music > img {
  overflow: hidden;
  width: 100%;
  height: 100px;
  margin: 0 0 8px;
  border-radius: 16px;
  border: 0;
  transition: 0.1s;
}
#musicInfo .rsWrapper .info div .contentsBox .musicBox .music > img:hover {
  border: 4px solid #ec15ff;
  box-shadow: 0 0 3px #ec15ff;
}
#musicInfo .rsWrapper .info div .contentsBox .musicBox .music .user {
  z-index: -1;
}
#musicInfo .rsWrapper .info div .contentsBox .musicBox .music p {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}
#musicInfo .rsWrapper .info div .contentsBox .musicBox .music > div {
  position: absolute;
  top: 0;
  left: 0;
  /*padding: 4px;  46 가장자리에 마우스 호버해도 반응 필요*/
  z-index: 2;
  height: 100%;
  align-items: flex-start;
}
#musicInfo .rsWrapper .info div .contentsBox .musicBox .music > div img {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin: 0 5px 0 0;
}
#musicInfo .rsWrapper .info div .contentsBox .musicBox .music > div p {
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  width: auto;
}
#musicInfo .mobileContentsBox,
#musicInfo .mobileTop {
  display: none;
}
#musicWrapper .rsWrapper > div .content .searchBox div {
  gap: 8px;
}
#musicWrapper .rsWrapper > div .content .searchBox div #pinkBtn {
  width: auto;
}
#musicWrapper .rsWrapper > div .content .searchBox div #pinkBtn p:first-child {
  margin: 0;
}
#musicWrapper .rsWrapper > div .content .searchBox div button {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
}
#musicWrapper .rsWrapper > div .content :where(.css-dev-only-do-not-override-1x0dypw).ant-rate {
  font-size: 20px;
  gap: 4px;
}
#musicWrapper .rsWrapper > div .content :where(.css-dev-only-do-not-override-1x0dypw).ant-rate li {
  margin: 0;
}
#musicWrapper .rsWrapper > div > .titleBox {
  margin-bottom: 48px;
}

/* 최신곡 */
#newWrapper {
  padding: 24px 40px 40px;
  background-image: linear-gradient(to top, rgba(236, 21, 255, 0.1), rgba(236, 21, 255, 0) 85%), linear-gradient(to bottom, rgba(19, 213, 223, 0.2), rgba(19, 213, 223, 0.7), rgba(19, 213, 223, 0.2));
}
#newWrapper .rsWrapper {
  position: relative;
}
#newWrapper .rsWrapper .bgImg {
  position: absolute;
  left: 0;
  width: 100%;
  top: 32%;
}
#newWrapper .rsWrapper > div:first-child {
  margin: 0;
}
#newWrapper .rsWrapper div .titleBox {
  width: auto;
}
#newWrapper .rsWrapper div .titleBox img {
  width: 21px;
  height: 21px;
  margin: 0 13px 0 0;
}
#newWrapper .rsWrapper div .titleBox h1 {
  font-size: 24px;
  color: #323146;
  font-weight: 700;
}
#aiWrapper .slider {
  padding: 0;
}
#newWrapper .rsWrapper .slider {
  padding: 0 40px;
}

/* 장르 */
#gerneWrapper {
  padding: 40px 0;
}
#gerneWrapper .rsWrapper .titleBox {
  margin: 0 0 48px;
}
#gerneWrapper .rsWrapper .titleBox .title {
  width: auto;
  margin-top: 0;
}
#gerneWrapper .rsWrapper .titleBox .title img {
  width: 24px;
  margin: 0 12px 0 0;
}
#gerneWrapper .rsWrapper .titleBox .title p {
  font-size: 24px;
  color: #323146;
  font-weight: 700;
}
#gerneWrapper .rsWrapper .titleBox > div:nth-child(2) {
  width: auto;
}
#gerneWrapper .rsWrapper .titleBox .tabBox {
  width: auto;
  padding: 8px;
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-radius: 50px;
}
#gerneWrapper .rsWrapper .titleBox .tabBox .tab {
  padding: 8px 24px;
  width: auto;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  border-radius: 50px;
  margin: 0 2px;
}
#gerneWrapper .rsWrapper .titleBox .tabBox .tab#active,
#gerneWrapper .rsWrapper .titleBox .tabBox .tab:hover {
  background: #ffffff;
  color: #464548;
}
#gerneWrapper .rsWrapper .listBox {
  flex-wrap: wrap;
}
#gerneWrapper .rsWrapper .listBox .gerneBox {
  width: calc(14.2857142857% - 25px);
  margin: 0 29px 40px 0;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
#gerneWrapper .rsWrapper .listBox .gerneBox:nth-child(7n) {
  margin: 0 0 40px;
}
#gerneWrapper .rsWrapper .listBox .gerneBox:before {
  content: '';
  display: block;
  padding-bottom: 100%;
}
#gerneWrapper .rsWrapper .listBox .gerneBox > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
#gerneWrapper .rsWrapper .listBox .gerneBox:hover .absolBox {
  background: rgba(0, 0, 0, 0.4);
}
#gerneWrapper .rsWrapper .listBox .gerneBox:hover .absolBox div .play {
  opacity: 1;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox .title {
  /* margin-top: 0; */
  /* margin-bottom: 0; */
  padding: 8px;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox .flexColStart .flexRowStart {
  z-index: 10;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox > div {
  height: 100%;
  position: relative;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox > div .play {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox div .play img {
  width: 72px;
  height: 72px;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox div .user img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox div .user p {
  font-size: 13px;
  color: #ffffff;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox div .title h1 {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin: 0 0 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox div .title div img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox div .title div p {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
#gerneWrapper .rsWrapper .listBox .gerneBox .abosolBox div .play .musicIcon {
  display: none;
}
#gerneWrapper .rsWrapper .listBox .gerneBox#active .play {
  opacity: 1;
}
#gerneWrapper .rsWrapper .listBox .gerneBox#active .play img {
  display: none;
}
#gerneWrapper .rsWrapper .listBox .gerneBox#active .play .musicIcon {
  display: flex;
  width: 72px;
  height: 72px;
  background: #1e1e1e;
  border-radius: 100%;
}
#gerneWrapper .rsWrapper .listBox .gerneBox#active .play .musicIcon img {
  width: 34px;
  height: auto;
  display: initial;
}
#gerneWrapper .rsWrapper .listBox .gerneBox#active .absolBox {
  background: rgba(0, 0, 0, 0.4);
}
#gerneWrapper .rsWrapper .listBox .gerneBox .absolBox > div .play .musicIcon {
  display: none;
}

/* 인기엔지니어 */
#engineWrapper {
  padding: 40px 0;
}
#engineWrapper .rsWrapper .titleBox {
  margin: 0 0 48px;
}
#engineWrapper .rsWrapper .titleBox .title {
  width: auto;
}
#engineWrapper .rsWrapper .titleBox .title img {
  width: 24px;
  margin: 0 12px 0 0;
}
#engineWrapper .rsWrapper .titleBox .title p {
  font-size: 24px;
  color: #323146;
  font-weight: 700;
}
#engineWrapper .rsWrapper .listBox {
  flex-wrap: wrap;
}
#engineWrapper .rsWrapper .listBox .gerneBox {
  width: calc(14.2857142857% - 25px);
  margin: 0 29px 40px 0;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
#engineWrapper .rsWrapper .listBox .gerneBox:nth-child(7n) {
  margin: 0 0 40px;
}
#engineWrapper .rsWrapper .listBox .gerneBox:before {
  content: '';
  display: block;
  padding-bottom: 100%;
}
#engineWrapper .rsWrapper .listBox .gerneBox > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.staffBox#playerBg:hover .absoluteBox,
#engineWrapper .rsWrapper .listBox .gerneBox:hover .absolBox {
  background: rgba(0, 0, 0, 0.4);
}

.staffBox#playerBg:hover .absoluteBox .play img {
  opacity: 1;
}

#engineWrapper .rsWrapper .listBox .gerneBox:hover .absolBox div .play {
  opacity: 1;
}
#engineWrapper .rsWrapper .listBox .gerneBox .absolBox {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#engineWrapper .rsWrapper .listBox .gerneBox .absolBox .flexColStart .flexRowStart {
  z-index: 10;
}
#engineWrapper .rsWrapper .listBox .gerneBox .absolBox > div {
  height: 100%;
  position: relative;
}

.staffBox#playerBg .absoluteBox .play img {
  height: 100%;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

#engineWrapper .rsWrapper .listBox .gerneBox .absolBox > div .play {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.staffBox#playerBg .absoluteBox .play img {
  width: 72px;
  height: 72px;
}

#engineWrapper .rsWrapper .listBox .gerneBox .absolBox div .play img {
  width: 72px;
  height: 72px;
}
#engineWrapper .rsWrapper .listBox .gerneBox .absolBox div .user img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}
#engineWrapper .rsWrapper .listBox .gerneBox .absolBox div .user p {
  font-size: 13px;
  color: #ffffff;
}
#engineWrapper .rsWrapper .listBox .gerneBox .absolBox div .title h1 {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin: 0 0 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#engineWrapper .rsWrapper .listBox .gerneBox .absolBox div .title div img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}
#engineWrapper .rsWrapper .listBox .gerneBox .absolBox div .title div p {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
#engineWrapper .rsWrapper .listBox .gerneBox .abosolBox div .play .musicIcon {
  display: none;
}
#engineWrapper .rsWrapper .listBox .gerneBox#active .play {
  opacity: 1;
}
#engineWrapper .rsWrapper .listBox .gerneBox#active .play img {
  display: none;
}
#engineWrapper .rsWrapper .listBox .gerneBox#active .play .musicIcon {
  display: flex;
  width: 72px;
  height: 72px;
  background: #1e1e1e;
  border-radius: 100%;
}
#engineWrapper .rsWrapper .listBox .gerneBox#active .play .musicIcon img {
  width: 34px;
  height: auto;
  display: initial;
}
#engineWrapper .rsWrapper .listBox .gerneBox#active .absolBox {
  background: rgba(0, 0, 0, 0.4);
}
#engineWrapper .rsWrapper .listBox .gerneBox .absolBox > div .play .musicIcon {
  display: none;
}

/* 배너 */
#subBannerWrapper {
  background: #1c1c2c;
  padding: 40px 0;
}
#subBannerWrapper .rsWrapper .subBanner {
  width: 49%;
  padding: 2px;
  background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  border-radius: 20px;
  overflow: hidden;
  height: 276px;
  position: relative;
  /*cursor: pointer;*/
}

.withCursor {
  cursor: pointer;
}

/* #subBannerWrapper .rsWrapper .subBanner div {
  height: 100%;
} */
#subBannerWrapper .rsWrapper .subBanner img {
  width: 100%;
  min-height: 274px;
  border-radius: 20px;
}
#subBannerWrapper .rsWrapper .subBanner .absoluteBox {
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background: rgba(0, 0, 0, 0.2);
}
#subBannerWrapper .rsWrapper .subBanner .absoluteBox h1 {
  font-size: 36px;
  font-weight: 300;
  margin: 0 0 10px;
  color: #ffffff;
}
#subBannerWrapper .rsWrapper .subBanner .absoluteBox h1 span {
  font-weight: 700;
}
#subBannerWrapper .rsWrapper .subBanner .absoluteBox p {
  font-size: 11px;
  font-weight: 600;
  color: #ffffff;
}

/* 인기 */
#popularWrapper {
  padding: 48px 0 0;
}
#popularWrapper .rsWrapper {
  padding: 0 8px;
}
#popularWrapper > h1 {
  font-size: 48px;
  font-weight: 700;
  background: linear-gradient(to right, #13d5df, #f138fc);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  margin: 0 0 40px;
}

#popularWrapper .rsWrapper .titleBox {
  margin: 0 0 20px;
}
#popularWrapper .rsWrapper .titleBox .title {
  width: auto;
  margin-top: 0;
}
#popularWrapper .rsWrapper .titleBox .title img {
  width: 24px;
  margin: 0 12px 0 0;
}
#popularWrapper .rsWrapper .titleBox .title p {
  font-size: 24px;
  color: #323146;
  font-weight: 700;
}
#popularWrapper .contentsSlide {
  position: relative;
  margin: 0 0 24px;
  height: auto;
  padding: 16px 0;
}
#popularWrapper .contentsSlide .whiteBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
#popularWrapper .contentsSlide .whiteBox .white {
  width: calc((100% - 1350px) / 2);
  z-index: 10;
  height: 100%;
  background: linear-gradient(to left, rgb(255, 255, 255) 70%, rgba(255, 255, 255, 0));
}
#popularWrapper .contentsSlide .whiteBox .white:first-child {
  background: linear-gradient(to right, rgb(255, 255, 255) 70%, rgba(255, 255, 255, 0));
}
#popularWrapper .rankBox {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}
#popularWrapper .rankBox .rsWrapper .thumbnail {
  width: 50%;
  height: 415px;
  position: relative;
}
#popularWrapper .rankBox .rsWrapper .thumbnail > img {
  width: 100%;
  height: 100%;
}
#popularWrapper .rankBox .rsWrapper .thumbnail .absoluteBox {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 15px 8px;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
}
#popularWrapper .rankBox .rsWrapper .thumbnail .absoluteBox div:first-child > img {
  width: 40px;
  height: auto;
}
#popularWrapper .rankBox .rsWrapper .thumbnail .absoluteBox div:first-child div {
  width: auto;
}
#musicInfo .promptRankIcon::before,
.rankBox .promptRankIcon::before {
  width: 64px;
  background-size: auto;
  height: 62px;
}
#musicInfo .promptRankIcon span,
.rankBox .promptRankIcon span {
  font-size: 21px;
}
#popularWrapper .rankBox .rsWrapper .thumbnail .absoluteBox div:first-child div img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}
#popularWrapper .rankBox .rsWrapper .thumbnail .absoluteBox div:first-child div p {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
}
#popularWrapper .rankBox .rsWrapper .thumbnail .absoluteBox h1 {
  font-size: 35px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}
#popularWrapper .rankBox .rsWrapper .thumbnail .absoluteBox .priceBox p {
  font-size: 24px;
  color: #ffffff;
}
#popularWrapper .rankBox .rsWrapper .infoBox {
  width: 50%;
  padding: 30px 0 30px 40px;
  height: 415px;
  gap: 32px;
  justify-content: start !important;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox > div:first-child {
  margin: 0 0 20px;
  align-items: start;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .profileImg {
  width: 80px;
  height: 80px;
  border-radius: 16px;
  margin: 0 15px 0 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .infoProfile {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .infoProfile h1 {
  font-size: 24px;
  margin: 0 0 12px;
  color: #ffffff;
  cursor: pointer;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .infoProfile .follow {
  padding: 8px 0;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .infoProfile .follow img:first-child {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .infoProfile .follow p {
  font-size: 16px;
  color: #ffffff;
  margin: 0 10px;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .infoProfile .follow img {
  width: 24px;
  height: auto;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .btnBox #whiteBorderBtn {
  width: 130px;
  height: 40px;
  border-radius: 10px;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .btnBox #whiteBorderBtn img {
  width: 16px;
  height: auto;
  margin: 0 10px 0 0;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .classRank {
  gap: 0 4px;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox .classRank img:last-child {
  width: 18px;
}
.classRank .ant-dropdown {
  min-width: 100px !important;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox {
  height: 185px;
  justify-content: flex-start;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .anticon {
  margin-left: 28px;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox p {
  font-size: 16px;
  color: #ffffff;
  margin: 0 0 20px;
  cursor: pointer;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents {
  width: calc(25% - 7.5px);
  margin: 0 10px 0 0;
  height: 100px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
  cursor: pointer;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents:nth-child(4) {
  margin: 0;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents > img {
  width: 100%;
  height: 100%;
}

#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents:hover {
  border: 4px solid #ec15ff;
  box-shadow: 0 0 3px #ec15ff;
}

#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 8px;
  height: 100%;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox div img {
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox div h1 {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .price {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
}

/* 스태프픽+최신컨텐츠 */
#listWrapper .rsWrapper {
  align-items: flex-start;
  padding: 40px 0 0;
  gap: 80px;
}
#listWrapper .rsWrapper .staff,
#listWrapper .rsWrapper .newContents {
  width: 100%;
}
#listWrapper .rsWrapper .staff .titleBox,
#listWrapper .rsWrapper .newContents .titleBox {
  margin: 0 0 20px;
}
#listWrapper .rsWrapper .staff .titleBox .title,
#listWrapper .rsWrapper .newContents .titleBox .title {
  width: auto;
  margin-top: 0;
}
#listWrapper .rsWrapper .staff .titleBox .title img,
#listWrapper .rsWrapper .newContents .titleBox .title img {
  width: 24px;
  margin: 0 12px 0 0;
}
#listWrapper .rsWrapper .staff .titleBox .title p,
#listWrapper .rsWrapper .newContents .titleBox .title p {
  font-size: 24px;
  color: #323146;
  font-weight: 700;
}
#listWrapper .rsWrapper .staff .staffList,
#listWrapper .rsWrapper .newContents .staffList {
  flex-wrap: wrap;
}
#listWrapper .rsWrapper .staff .staffList .staffBox,
#listWrapper .rsWrapper .newContents .staffList .staffBox {
  width: calc(33.3333333333% - 14px);
  margin: 0 20px 40px 0;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  border: 4px solid transparent;
  transition: 0.2s;
}
#listWrapper .rsWrapper .newContents .staffList .staffBox:hover {
  border: 4px solid transparent;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#b041f3, #ec15ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0 2px 13px #b041f3;
}
#listWrapper .rsWrapper .staff .staffList .staffBox:nth-child(3n),
#listWrapper .rsWrapper .newContents .staffList .staffBox:nth-child(3n) {
  margin: 0 0 40px;
}
#listWrapper .rsWrapper .staff .staffList .staffBox > img,
#listWrapper .rsWrapper .newContents .staffList .staffBox > img {
  width: 100%;
  height: 185px;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child {
  padding: 8px;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div:first-child > img,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div:first-child > img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div:first-child p,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div:first-child p {
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div > h1,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div > h1 {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  padding: 0 8px;
  margin: 0 0 4px;
}
#listWrapper .rsWrapper .staff .staffList .title,
#listWrapper .rsWrapper .newContents .staffList .title {
  padding: 8px;
  background: #e2e2e2;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox {
  /* padding: 12px 8px; */
  justify-content: space-between;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox p,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox p {
  color: var(--t-dark, #0e0e1f);
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox > img,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox > img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox > div {
  width: calc(100% - 32px - 8px);
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox div > h1,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox div > h1 {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 2px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  padding: 0;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox div > p,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox div > p {
  font-size: 13px;
  font-weight: 600;
  text-wrap-mode: nowrap;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox div .imgBox,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox div .imgBox {
  width: auto;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox div .imgBox > img:first-child,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox div .imgBox > img:first-child {
  width: 20px;
  height: 20px;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox div .imgBox > img,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox div .imgBox > img {
  width: 16px;
  height: auto;
  margin: 0 4px 0 0;
}

/* 배너2 */
#subBanner2 {
  padding: 64px 0;
  background: #070712;
}
#subBanner2 .rsWrapper > div {
  padding: 2px;
  background: linear-gradient(to right, #13d5df, #f138fc);
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}
#subBanner2 .rsWrapper div img {
  width: 100%;
  height: 300px;
  border-radius: 16px;
  object-fit: cover;
  object-position: top;
}
#subBanner2 .rsWrapper div .absoluteBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
#subBanner2 .rsWrapper div .absoluteBox h1 {
  font-size: 36px;
  font-weight: 300;
  color: #ffffff;
  margin: 0 0 11px;
}
#subBanner2 .rsWrapper div .absoluteBox h1 span {
  font-weight: 700;
}
#subBanner2 .rsWrapper div .absoluteBox p {
  font-size: 11px;
  color: #ffffff;
}

/* 빌더 */
#builderWrapper .titleBox {
  width: 100%;
  background: linear-gradient(to top, rgba(236, 21, 255, 0), rgba(236, 21, 255, 0.1));
  display: flex;
  justify-content: center;
}
#builderWrapper > .titleBox h1 {
  font-size: 48px;
  font-weight: 700;
  background: linear-gradient(to right, #13d5df, #f138fc);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  margin: 48px 0 40px;
  width: fit-content;
}
#builderWrapper > .titleBox h1 br {
  display: none;
}
#builderWrapper .bgImg {
  height: 576px;
  position: relative;
  overflow: hidden;
}
#builderWrapper .bgImg .imgBox {
  /* position: absolute;
  top: 0;
  left: 0;
  height: 130%; */
  width: 100%;
  height: 0;
  padding-bottom: calc(760 / 1360 * 100%);
  background-size: cover;
  background-image: url('/src/assets/images/common/bgImg.png');
  background-repeat: no-repeat;
  background-position: center;
}
#builderWrapper .bgImg .imgBox img {
  width: 100%;
  height: 100%;
}
#builderWrapper .bgImg .builder {
  position: absolute;
  top: 0;
  left: 19.8%;
  width: 10%;
  height: 33%;
}
#builderWrapper .bgImg .imagination {
  position: absolute;
  bottom: 0;
  left: 29.8%;
  width: 10%;
  height: 33%;
}
#builderWrapper .bgImg .contents {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 10%;
  height: 33%;
}
#builderWrapper .bgImg .builderBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
#builderWrapper .bgImg .builderBox > div {
  width: 100%;
  height: 33.3333333333%;
}
#builderWrapper .bgImg .builderBox div .box img {
  width: 48px;
  height: auto;
  margin: 0 0 13px;
}
#builderWrapper .bgImg .builderBox div .box p {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.3;
}
#builderWrapper .bgImg .builderBox .box {
  width: 10%;
  z-index: 10;
  height: 100%;
  align-self: unset;
  flex-grow: unset;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: unset;
}
#builderWrapper .bgImg .builderBox .box:hover {
  border-width: 2px !important;
  background-color: rgba(236, 21, 255, 0.2) !important;
  /* transition: all 0.5s cubic-bezier(0.91, 0, 0, 1.09); */
  object-fit: cover;
  transform: scale(1.02);
  transition: transform 0.5s;
}
#builderWrapper .bgImg .builder img,
#builderWrapper .bgImg .imagination img,
#builderWrapper .bgImg .contents img {
  width: 48px;
  height: auto;
  margin: 0 0 13px;
}
#builderWrapper .bgImg .builder p,
#builderWrapper .bgImg .imagination p,
#builderWrapper .bgImg .contents p {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}
#builderWrapper .bgImg .login p {
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  margin: 0 0 32px;
  font-weight: 600;
}
#builderWrapper .bgImg .login #loginBtn {
  width: 80%;
  height: 48px;
  border-radius: 30px;
}
#builderWrapper .bgImg .imgBox img.mobile {
  display: none;
}

/* 소식 */

#infoWrapper .rsWrapper {
  gap: 40px;
  padding-bottom: 40px;
}
#infoWrapper > h1 {
  font-size: 48px;
  font-weight: 700;
  background: linear-gradient(to right, #13d5df, #f138fc);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  margin: 48px 0 40px;
}
#infoWrapper > h1 br {
  display: none;
}
#infoWrapper .rsWrapper .titleBox {
  margin: 0 0 20px;
  width: 100%;
  height: 51px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#infoWrapper .rsWrapper .titleBox .title {
  width: 100%;
  justify-content: start;
}
#infoWrapper .rsWrapper .titleBox .title img {
  width: 24px;
  margin: 0 12px 0 0;
}
#infoWrapper .rsWrapper .titleBox .title p {
  font-size: 24px;
  color: #323146;
  font-weight: 700;
}
#infoWrapper .listBox .list .absoluteBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
#infoWrapper .listBox .list .absoluteBox img {
  width: 58px;
  margin: 0 0 16px;
}
#infoWrapper .listBox .list .absoluteBox p {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
}

#infoWrapper .rsWrapper .box {
  width: 100%;
}
#infoWrapper .listBox .title {
  width: 100%;
  justify-content: start;
  height: 52px;
}
#infoWrapper .listBox .imgThum .title {
  width: 100%;
  height: 100%;
  justify-content: center;
}
#infoWrapper .listBox {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1%;
  row-gap: 1vw;
  width: 100%;
}
/* antd List 컴포넌트 컨트롤 */
#infoWrapper .listBox .ant-list {
  width: 100%;
}
#infoWrapper .listBox .list {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border-radius: 16px;
  width: 23.999999%;
  height: 198px;
  overflow: hidden;
  cursor: pointer;
}
#infoWrapper .listBox .list .image-wrapper {
  width: 100%;
  height: 100%;
}
#infoWrapper .listBox .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#infoWrapper .listBox .searchBox form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#infoWrapper .listBox .searchBox form span {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
#infoWrapper .listBox .searchBox form span div {
  width: auto;
}
#infoWrapper .listBox .list .card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}
#infoWrapper .listBox .list .card p {
  line-height: 1;
  z-index: 2;
}
#infoWrapper .listBox .list:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  background: linear-gradient(90deg, rgba(176, 65, 243, 0.8) 0%, rgba(236, 21, 255, 0.8) 100%);
  z-index: 1;
  transition: all 0.2s ease;
}
#infoWrapper .listBox .list:hover .image-wrapper {
  background: linear-gradient(90deg, rgba(176, 65, 243, 0.8) 0%, rgba(236, 21, 255, 0.8) 100%);
  opacity: 0.3;
}
#infoWrapper .listBox .list:nth-child(4n) {
  margin-right: 0;
}

#infoWrapper .aiListBox {
  flex-wrap: wrap;
}
#infoWrapper .aiListBox .aiList {
  width: calc(50% - 20px);
  border-radius: 16px;
  overflow: hidden;
  margin: 0 0 40px;
}
#infoWrapper .aiListBox .aiList > img {
  width: 260px;
  height: 193px;
}
#infoWrapper .aiListBox .aiList .infoBox {
  width: calc(100% - 260px);
  height: 193px;
  padding: 0 32px;
  background: rgba(62, 61, 83, 0.1);
}
#infoWrapper .aiListBox .aiList .infoBox h1 {
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 10px;
}
#infoWrapper .aiListBox .aiList .infoBox p {
  font-size: 16px;
  color: #0e0e1f;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
  margin: 0 0 12px;
  max-height: 30px;
}
#infoWrapper .aiListBox .aiList .infoBox p:last-child {
  font-size: 14px;
  margin: 0;
}

.boardsSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.boardsSection .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1520px;
  display: flex;
  padding-bottom: 8px;
  margin: auto;
  padding: 24px 0;
  border-bottom: 2px solid #000;
  margin: 0 16px;
  width: calc(100% - 32px);
}
.boardsSection .title .titleBox {
  color: var(--t-dark, #0e0e1f);
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  justify-content: start;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  text-wrap-mode: nowrap;
}
.boardsSection .title .titleBox::before {
  background: url(/src/assets/images/common/i-community.svg) no-repeat left center;
  content: '';
  width: 24px;
  height: 24px;
}
.boardsSection .title .searchBox {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: center;
  gap: 8px;
}
.boardsSection .title .searchBox div {
  margin-bottom: 0;
}
.boardsSection .title .searchBox button[type='submit'].ant-btn-primary {
  padding: 8px;
  background: #e94cf7 url(/src/assets/images/common/search-solid.svg) no-repeat center;
  font-size: 0px;
  min-width: 40px;
  height: 40px;
}
.boardsSection .searchBox form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100% !important;
  gap: 8px;
}
.boardsSection .searchBox form .ant-form-item {
  margin: 0;
}
.boardsSection .searchBox form .btn {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
}
.boardsSection .searchBox form .btn .searchCount {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.boardsSection .searchBox form .btn .searchCount span {
  margin-left: 16px;
}
.boardsSection .searchBox form .select {
  width: 120px;
}
.boardsSection .searchBox form .inputText {
  width: 400px;
}
.boardsSection .searchBox form .inputText .ant-row div:first-child {
  flex: 0 0 400px;
}
.boardsSection .searchBox form button[type='submit'].ant-btn-primary {
  padding: 8px;
  background: #e94cf7 url(/src/assets/images/common/search-solid.svg) no-repeat center;
  font-size: 0px;
  min-width: 40px;
  height: 40px;
}
.boardsSection .boardWrapper .ant-layout .searchBox {
  padding: 16px 0;
  position: relative;
}
.boardsSection .boardWrapper .ant-layout .cardArea {
  height: calc(-300px + 100vh);
  overflow-y: auto;
}
.boardsSection .boardWrapper .ant-layout .searchBox .result {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 40px;
}
.boardsSection .boardWrapper .ant-layout .searchBox .result .searchCount {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  gap: 20px;
}
.boardsSection .boardWrapper .ant-layout .searchBox .result .searchCount span {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.boardsSection .boardWrapper .ant-layout .searchBox .btn {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.boardsSection .boardWrapper .ant-layout .searchBox .btn .search {
  display: none;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item {
  margin-block-end: 0;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 16px);
  padding-bottom: 40px;
  overflow: hidden;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-cover {
  position: relative;
  width: 100%;
  height: 182px;
  margin-top: 0;
  margin-inline: 0;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-cover > * {
  border-radius: 16px 16px 0 0;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-cover .imgThum {
  color: rgb(153, 153, 153);
  font-size: 14px;
  width: 100%;
  height: 182px;
  object-fit: cover;
}

.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-cover .imgThum .title {
  height: 100%;
}

.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-cover p {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  border: 0;
  background: rgba(0, 0, 0, 0.3);
  margin: 0;
  padding: 10px 15px;
  width: 100%;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-body {
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 0 0 16px 16px;
  border-radius: 0px 0px 10px 10px;
  background: rgba(0, 0, 0, 0.1);
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-body::before,
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-body::after {
  display: none;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-body .ThumInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-body .ThumInfo p {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: #464548;
  font-size: 12px;
  font-weight: 500;
  gap: 8px;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-body .ThumInfo p::before {
  width: 18px;
  height: 18px;
  content: '';
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-body .ThumInfo p.goodCount {
  background: url(/src/assets/images/common/i-good.svg) no-repeat left center/18px;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-body .ThumInfo p.viewCount {
  background: url(/src/assets/images/common/i-eye.svg) no-repeat left center/18px;
}
.boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-body .ThumInfo p.repleCount {
  background: url(/src/assets/images/common/i-reple.svg) no-repeat left center/18px;
}

@media (max-width: 1600px) {
  #popularWrapper .contentsSlide .whiteBox .white {
    width: calc((100% - 1100px) / 2);
  }
  #builderWrapper .bgImg .builderBox div .box.login p {
    font-size: 22px;
  }
}
@media (max-width: 1500px) {
  #aiWrapper .slide .imageWrapper {
    margin: 0 -36px;
  }
  #builderWrapper .bgImg .login p {
    font-size: 22px;
  }
  #builderWrapper .bgImg .builderBox div .box p {
    font-size: 20px;
  }
  #infoWrapper > h1 {
    font-size: 40px;
  }
  .rankBox .anticon-right,
  .contentsBox .anticon-right {
    margin-left: 28px;
  }
}
@media (max-width: 1280px) {
  #aiWrapper .slide .imageWrapper {
    width: 130px;
    height: 130px;
  }
  #aiWrapper .slide .imageWrapper:nth-child(1),
  #aiWrapper .slide .imageWrapper:nth-child(2),
  #aiWrapper .slide .imageWrapper:nth-child(13),
  #aiWrapper .slide .imageWrapper:nth-child(12) {
    display: none;
  }
  /* 장르 */
  #gerneWrapper .rsWrapper .listBox .gerneBox,
  #engineWrapper .rsWrapper .listBox .gerneBox {
    width: calc(20% - 25px);
    margin: 0 29px 40px 0;
  }
  #gerneWrapper .rsWrapper .listBox .gerneBox:nth-child(7n),
  #engineWrapper .rsWrapper .listBox .gerneBox:nth-child(7n) {
    margin: 0 29px 40px 0;
  }
  #gerneWrapper .rsWrapper .listBox .gerneBox:nth-child(5n),
  #engineWrapper .rsWrapper .listBox .gerneBox:nth-child(5n) {
    margin: 0 0 40px;
  }
  /* 인기엔지니어 */
  #popularWrapper > h1 {
    font-size: 40px;
  }
  #listWrapper .rsWrapper .staff .staffList .staffBox,
  #listWrapper .rsWrapper .newContents .staffList .staffBox {
    width: calc(50% - 10px);
    margin: 0 20px 20px 0;
  }
  #listWrapper .rsWrapper .staff .staffList .staffBox:nth-child(3n),
  #listWrapper .rsWrapper .newContents .staffList .staffBox:nth-child(3n) {
    margin: 0 20px 20px 0;
  }
  #listWrapper .rsWrapper .staff .staffList .staffBox:nth-child(2n),
  #listWrapper .rsWrapper .newContents .staffList .staffBox:nth-child(2n) {
    margin: 0 0 20px;
  }
  #builderWrapper > .titleBox h1 {
    font-size: 40px;
  }
  #builderWrapper .bgImg {
    height: 450px;
  }
  #builderWrapper .bgImg .builderBox .box {
    width: 14.2857142857%;
  }
  #builderWrapper .bgImg .login p {
    font-size: 16px;
  }
  #builderWrapper .bgImg .builderBox div .box.login p {
    margin: 0 0 10px;
  }
  #builderWrapper .bgImg .builderBox div .box p {
    font-size: 20px;
  }
  #builderWrapper .bgImg .builderBox div .box img {
    width: 35px;
  }
  /* 소식 */
  #infoWrapper .aiListBox .aiList > img {
    width: 200px;
  }
  #infoWrapper .aiListBox .aiList .infoBox {
    width: calc(100% - 200px);
    padding: 0 20px;
  }
  #popularWrapper .contentsSlide .whiteBox .white {
    width: calc((100% - 1000px) / 2);
  }
}
@media (max-width: 1100px) {
  #musicInfo .rsWrapper .thumbBox {
    width: 28%;
  }
  #musicInfo .rsWrapper .info {
    width: 70%;
    padding-bottom: 16px;
  }
  #musicInfo .rsWrapper .info div .userBox {
    width: auto;
  }
  #musicInfo .rsWrapper .info div .contentsBox {
    width: auto;
  }
  #popularWrapper .rankBox {
    display: none;
  }
  #infoWrapper .titleBox h1 {
    font-size: 30px;
  }
  #popularWrapper .contentsSlide .whiteBox .white {
    width: calc((100% - 800px) / 2);
  }
}
@media (max-width: 900px) {
  /* 장르 */
  #gerneWrapper .rsWrapper .listBox .gerneBox {
    width: calc(33.3333333333% - 25px);
    margin: 0 29px 40px 0;
  }
  #gerneWrapper .rsWrapper .listBox .gerneBox:nth-child(7n),
  #gerneWrapper .rsWrapper .listBox .gerneBox:nth-child(5n) {
    margin: 0 29px 40px 0;
  }
  #gerneWrapper .rsWrapper .listBox .gerneBox:nth-child(3n) {
    margin: 0 0 40px;
  }
  /* 인기엔지니어 */
  #engineWrapper .rsWrapper .listBox .gerneBox {
    width: calc(33.3333333333% - 25px);
    margin: 0 29px 40px 0;
  }
  #engineWrapper .rsWrapper .listBox .gerneBox:nth-child(7n),
  #engineWrapper .rsWrapper .listBox .gerneBox:nth-child(5n) {
    margin: 0 29px 40px 0;
  }
  #engineWrapper .rsWrapper .listBox .gerneBox:nth-child(3n) {
    margin: 0 0 40px;
  }
  .boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-cover {
    height: 90px;
  }
  .boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-cover p {
    font-size: 18px;
  }
  /* 배너 */
  #subBannerWrapper {
    padding: 0;
  }
  #subBannerWrapper .rsWrapper {
    flex-direction: column;
    padding: 0;
  }
  #subBannerWrapper .rsWrapper .subBanner {
    width: 100%;
    border-radius: 0;
  }
  #subBannerWrapper .rsWrapper .subBanner:first-child {
    padding: 3px 3px 0;
  }
  #subBannerWrapper .rsWrapper .subBanner img {
    border-radius: 0;
  }
  #popularWrapper > h1 {
    font-size: 30px;
  }
  /* 빌더 */
  #builderWrapper .bgImg {
    height: 441px;
  }
  #builderWrapper .bgImg .imgBox img:first-child {
    /* display: none; */
  }
  #builderWrapper .bgImg .imgBox {
    /* position: absolute;
    top: 0;
    left: 0;
    height: 130%; */
    width: 100%;
    height: 0;
    padding-bottom: calc(760 / 1360 * 150%);
    background-size: cover;
    background-image: url('/src/assets/images/common/bgImg.png');
    background-repeat: no-repeat;
    background-position: center;
  }
  #builderWrapper .bgImg .builder {
    right: 0;
    left: initial;
    width: 33%;
  }
  #builderWrapper .bgImg .builderBox .box {
    width: 20%;
  }
  #builderWrapper .bgImg .builderBox div .box img {
    width: 50px;
  }
  .boardsSection #infoWrapper .rsWrapper {
    padding: 0 16px 40px;
  }
  #builderWrapper .bgImg .builderBox div .box p {
    font-size: 20px;
  }
  #builderWrapper > h1 {
    font-size: 34px;
  }
  /* #builderWrapper .bgImg .imgBox img.mobile {
      display: initial;
      width: 100%;
  } */
  /* 소식 */
  #infoWrapper .rsWrapper {
    padding: 0 0 40px;
    gap: 40px;
  }
  #boardWrapper #infoWrapper .listBox {
    flex-wrap: wrap;
  }
  #infoWrapper .listBox .list {
    width: 49%;
  }
  #infoWrapper .aiListBox .aiList {
    width: 100%;
  }
  #infoWrapper .listBox .searchBox form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  #infoWrapper .listBox .searchBox form span {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  #infoWrapper .listBox .searchBox form span div {
    width: auto;
  }
  #infoWrapper .rsWrapper .titleBox {
    height: 32px;
    margin-bottom: 8px;
  }
  #infoWrapper .listBox .list .card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
  }
  #infoWrapper .listBox .list .card p {
    line-height: 1;
    z-index: 2;
  }
  #infoWrapper .listBox .list:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    background: linear-gradient(90deg, rgba(176, 65, 243, 0.8) 0%, rgba(236, 21, 255, 0.8) 100%);
    z-index: 1;
    transition: all 0.2s ease;
  }
  #infoWrapper .listBox .list:hover .image-wrapper {
    background: linear-gradient(90deg, rgba(176, 65, 243, 0.8) 0%, rgba(236, 21, 255, 0.8) 100%);
    opacity: 0.3;
  }
  #infoWrapper .listBox .list:nth-child(4n) {
    margin-right: 0;
  }
}
@media (max-width: 700px) {
  .boardsSection .ant-layout {
    margin: 0;
  }
  .communitySection #infoWrapper .rsWrapper .titleBox {
    height: auto;
    /* margin-bottom: 8px; */
    padding-top: 20px;
  }
  .communitySection #infoWrapper .listBox .list .absoluteBox p {
    font-size: 16px;
    font-weight: 600;
  }
  .communitySection #infoWrapper .listBox {
    justify-content: space-between;
  }
  .communitySection #infoWrapper .listBox .list {
    width: 48.5%;
    height: 40vw;
  }
  .Section #infoWrapper .listBox {
    column-gap: 3vw;
  }
  .Section #infoWrapper .listBox .list {
    width: 48%;
    height: 41vw;
  }
  .boardsSection #infoWrapper .listBox .ant-spin-container .ant-row {
    gap: 2vw;
  }
  .boardsSection #infoWrapper .listBox .ant-spin-container .ant-row > div {
    width: 48% !important;
    max-width: 48% !important;
  }
  .boardsSection #infoWrapper .listBox .ant-spin-container .ant-row .cardThum {
    width: 100%;
    padding-bottom: 0;
  }
  .boardsSection .title {
    margin: 0 16px;
    padding: 20px 0;
    width: calc(100% - 32px);
  }
  .boardsSection .title .titleBox {
    font-size: 18px;
  }
  .boardsSection .title .titleBox::before {
    width: 20px;
    height: 20px;
    background-size: 20px;
    content: '';
  }

  .boardsSection .title .searchBox.none::before {
    width: 24px;
    height: 24px;
    background-size: 18px;
    content: '';
    background: url(/src/assets/images/common/search-solid.svg) no-repeat center;
    fill: #525162;
  }
  .boardsSection .boardWrapper .searchBox .result .searchCount {
    gap: 12px !important;
    font-size: 14px !important;
  }
  .boardsSection .boardWrapper .searchBox .btn {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 12px;
  }
  .boardsSection .boardWrapper .searchBox .btn-search {
    width: 32px;
    height: 32px;
    content: '';
    background: url(/src/assets/images/common/i-search.svg) no-repeat center/18px;
    fill: #525162;
    border-radius: 4px;
    display: flex !important;
  }
  .boardsSection .boardWrapper .searchBox .btn-search::hover {
    box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
    border-color: transparent;
    outline: rgba(255, 94, 182, 0.7);
  }
  .boardsSection .boardWrapper .searchBox .btn .search {
    display: flex;
    position: absolute;
    left: -14px;
    right: -14px;
    background: #fff;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    padding: 8px;
    top: 8px;
    z-index: 2;
  }
  .boardsSection .boardWrapper .searchBox form .inputText {
    width: calc(100% - 120px - 80px - 40px);
  }
  .boardsSection .boardWrapper .searchBox form .inputText .ant-row div:first-child {
    width: 100%;
  }
  .boardsSection .boardWrapper .ant-layout .cardArea {
    height: calc(-200px + 100vh);
    overflow-y: auto;
  }
  .boardsSection .boardWrapper .aui-grid-header-renderer {
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
  }
  .boardsSection .boardWrapper .aui-grid-body-panel .aui-grid-table tr td .aui-grid-renderer-base .group {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 2px;
    font-weight: 500;
    font-size: 14px;
  }
  .boardsSection .boardWrapper .aui-grid-body-panel .aui-grid-table tr td .comment {
    padding: 0;
    background: transparent;
    height: auto;
    gap: 4px;
  }
  .boardsSection .boardWrapper .aui-grid-body-panel .aui-grid-table tr td .comment span,
  .boardsSection .boardWrapper .aui-grid-body-panel .aui-grid-table tr td .comment svg {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #525162;
    line-height: 1;
    font-weight: 500;
    font-size: 11px;
    fill: #525162;
    height: 16px;
  }
  .boardsSection .boardWrapper .ant-layout .ant-layout-content .ant-list-item .ant-card-cover p {
    font-size: 16px;
  }
  .boardsSection #board-detail {
    min-height: auto;
    width: 100%;
    padding: 0 16px;
  }
  .boardsSection #board-detail .board-detail-wrapper {
    width: 100%;
    padding: 0;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-container {
    gap: 5px;
    padding-top: 30px;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-container .info-container {
    gap: 16px;
    font-size: 12px;
    line-height: 1;
    height: auto;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-container .info-container .date-info,
  .boardsSection #board-detail .board-detail-wrapper .title-container .info-container svg {
    font-size: 12px;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-container .info-container .ant-btn {
    height: auto;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 23px;
    margin-top: 5px;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 23px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container {
    padding: 16px 0px;
    gap: 10px;
    margin-block-end: 20px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-icon .ant-avatar {
    width: 38px;
    height: 38px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info {
    gap: 4px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .info-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 8px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .info-container .dropdown-item {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    padding: 0;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .info-container .star-rating {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: auto;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .info-container .star-rating .anticon-star {
    font-size: 13px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .userArea {
    gap: 4px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .userArea p {
    font-size: 14px;
  }
  .boardsSection #board-detail .board-detail-wrapper .file-attachment {
    padding: 16px 0;
  }
  .boardsSection #board-detail .board-detail-wrapper .file-list {
    gap: 8px;
    font-size: 12px;
  }
  .boardsSection #board-detail .board-detail-wrapper .back-button-container {
    padding: 30px 0;
  }

  #aiWrapper {
    margin: 12px 0 0;
  }
  #aiWrapper > h1 {
    font-size: 16px;
    margin: 0 0 12px;
  }
  #aiWrapper > h1 span {
    font-size: 16px;
  }
  #aiWrapper .titleBox {
    margin: 0 0 8px;
  }
  #aiWrapper .titleBox::after {
    display: none;
  }
  #infoWrapper .rsWrapper .titleBox .title p,
  #listWrapper .rsWrapper .newContents .titleBox .title p,
  #popularWrapper .rsWrapper .titleBox .title p,
  #newWrapper .rsWrapper div .titleBox h1,
  #aiWrapper .titleBox .title p {
    font-size: 20px;
  }
  #aiWrapper .titleBox .tabBox {
    padding: 4px;
    margin: 0 10px 0 0;
  }
  #aiWrapper .titleBox .tabBox .tab {
    font-size: 14px;
    padding: 4px 8px;
  }
  #aiWrapper .slide {
    padding: 0;
  }
  #aiWrapper .slide .imageWrapper {
    width: 70px;
    height: 70px;
    margin: 0 -8px 0 0;
  }
  #aiWrapper .slide .imageWrapper#active {
    width: 110px;
    height: 110px;
    margin: 0;
  }
  #aiWrapper .slide .imageWrapper .activeIcon .round {
    width: 46px;
    height: 46px;
  }
  #aiWrapper .slide .imageWrapper .activeIcon img {
    width: 18px;
  }
  #aiWrapper .slide .imageWrapper:nth-child(3),
  #aiWrapper .slide .imageWrapper:nth-child(4),
  #aiWrapper .slide .imageWrapper:nth-child(5),
  #aiWrapper .slide .imageWrapper:nth-child(10),
  #aiWrapper .slide .imageWrapper:nth-child(11) {
    display: none;
  }
  .mainIcon {
    width: 83px;
    margin: -60px 0 0;
  }
  #musicInfo {
    margin: -40px 0 0;
    justify-content: space-between;
    height: auto;
  }
  #musicInfo .rsWrapper .thumbBox {
    display: none;
  }
  #musicInfo .rsWrapper .info {
    width: 100%;
  }
  #musicInfo .rsWrapper .info > h1 {
    font-size: 24px;
  }
  #musicInfo .rsWrapper .info div .userBox {
    width: 100%;
  }
  #musicInfo .rsWrapper .info div .contentsBox {
    display: none;
  }
  #musicInfo .rsWrapper .info div .userBox .userInfo {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 16px;
    margin: 0 0 20px;
  }
  #musicInfo .rsWrapper .info > div {
    flex-direction: column;
    height: auto;
  }
  #musicInfo .mobileContentsBox {
    padding: 12px 16px;
    background: rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  #musicInfo .mobileContentsBox .musicBox {
    gap: 48px;
  }
  #musicInfo .mobileContentsBox .music {
    width: calc(20% - 8px);
    position: relative;
  }
  #musicInfo .mobileContentsBox > div:first-child {
    margin: 0 0 20px;
  }
  #musicInfo .mobileContentsBox > div h1 {
    font-size: 16px;
    color: #ffffff;
    margin: 0 23px 0 0;
    font-weight: 700;
  }
  #musicInfo .mobileContentsBox > div h1 span {
    font-weight: 400;
  }
  #musicInfo .mobileContentsBox > div p {
    font-size: 12px;
    color: #ffffff;
  }
  #musicInfo .mobileContentsBox .musicBox .music {
    width: calc(33.3333333333% - 33px);
    position: relative;
  }
  #musicInfo .mobileContentsBox .music > img {
    width: 100%;
    height: 80px;
    border-radius: 16px;
    overflow: hidden;
    margin: 0 0 5px;
  }
  #musicInfo .mobileContentsBox .music p {
    color: #ffffff;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
  }
  #musicInfo .mobileContentsBox .music > div {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px;
    z-index: 2;
    height: 100%;
    align-items: flex-start;
  }
  #musicInfo .mobileContentsBox .music > div img {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    margin: 0 5px 0 0;
  }
  #musicInfo .mobileContentsBox .music > div p {
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
  }
  #musicInfo .mobileTop {
    display: flex;
    padding: 0 16px;
  }
  #musicInfo .mobileTop div {
    width: auto;
  }
  #musicInfo .mobileTop div img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin: 0 8px 0 0;
  }
  #musicInfo .mobileTop div p {
    font-size: 13px;
    color: #ffffff;
  }
  #musicInfo .mobileTop img {
    width: 34px;
  }
  /* 최신곡 */
  #newWrapper {
    padding: 24px 0 0;
    overflow: hidden;
  }
  #newWrapper .rsWrapper .slider {
    padding: 0;
  }
  /* 장르 */
  #gerneWrapper {
    padding: 22px 0 0;
  }
  #gerneWrapper .rsWrapper .listBox .gerneBox {
    width: calc(50% - 11.5px);
    margin: 0 23px 20px 0;
  }
  #gerneWrapper .rsWrapper .listBox .gerneBox:nth-child(7n),
  #gerneWrapper .rsWrapper .listBox .gerneBox:nth-child(5n),
  #gerneWrapper .rsWrapper .listBox .gerneBox:nth-child(3n) {
    margin: 0 23px 20px 0;
  }
  #gerneWrapper .rsWrapper .listBox .gerneBox:nth-child(2n) {
    margin: 0 0 20px;
  }
  #gerneWrapper .rsWrapper .titleBox {
    margin: 10px 0 30px 0;
  }
  #gerneWrapper .rsWrapper .titleBox .title p {
    font-size: 20px;
  }
  #gerneWrapper .rsWrapper .titleBox .tabBox {
    padding: 4px;
    margin: 0 10px 0 0;
  }
  #gerneWrapper .rsWrapper .titleBox .tabBox .tab {
    display: none;
    font-size: 14px;
    padding: 4px 8px;
  }
  #gerneWrapper .rsWrapper .titleBox .tabBox .tab:nth-of-type(1),
  #gerneWrapper .rsWrapper .titleBox .tabBox .tab:nth-of-type(2),
  #gerneWrapper .rsWrapper .titleBox .tabBox .tab:nth-of-type(3),
  #gerneWrapper .rsWrapper .titleBox .tabBox .tab:nth-of-type(4) {
    display: flex;
  }
  /* 인기엔지니어 */
  #engineWrapper {
    padding: 0;
  }
  #engineWrapper .rsWrapper .listBox .gerneBox {
    width: calc(50% - 11.5px);
    margin: 0 23px 20px 0;
  }
  #engineWrapper .rsWrapper .listBox .gerneBox:nth-child(7n),
  #engineWrapper .rsWrapper .listBox .gerneBox:nth-child(5n),
  #engineWrapper .rsWrapper .listBox .gerneBox:nth-child(3n) {
    margin: 0 23px 20px 0;
  }
  #engineWrapper .rsWrapper .listBox .gerneBox:nth-child(2n) {
    margin: 0 0 20px;
  }
  #engineWrapper .rsWrapper .titleBox {
    margin: 20px 0 30px 0;
  }
  #engineWrapper .rsWrapper .titleBox .title p {
    font-size: 20px;
  }
  #engineWrapper .rsWrapper .titleBox .tabBox {
    padding: 4px;
    margin: 0 10px 0 0;
  }
  #engineWrapper .rsWrapper .titleBox .tabBox .tab {
    font-size: 14px;
    padding: 4px 8px;
  }
  /* 배너 */
  #subBannerWrapper .rsWrapper .subBanner {
    height: 142px;
  }
  #subBannerWrapper .rsWrapper .subBanner .absoluteBox h1 {
    font-size: 28px;
  }
  #subBannerWrapper .rsWrapper .subBanner .absoluteBox p {
    font-size: 16px;
  }
  /* 인기 */
  #popularWrapper {
    padding: 12px 0 0;
    /* margin: 0 0 30px; */
  }
  #popularWrapper > h1 {
    font-size: 16px;
    margin: 0 0 18px;
    line-height: 1.4;
  }
  /* 스태프 + 최신컨텐츠 */
  #listWrapper .rsWrapper {
    flex-direction: column;
    padding: 0 16px;
    gap: 0px;
  }
  #listWrapper .rsWrapper .staff,
  #listWrapper .rsWrapper .newContents {
    width: 100%;
  }
  #popularWrapperr .rsWrapper .titleBox .title p,
  #listWrapper .rsWrapper .titleBox .title p {
    font-size: 20px;
  }
  #listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div > h1,
  #listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div > h1 {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  #listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox > img,
  #listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox > img {
    width: 24px;
    height: 24px;
  }
  #listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox > div {
    width: calc(100% - 24px - 8px);
  }

  #listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox div > h1 {
    font-size: 12px;
    width: 80px;
  }
  #listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox div .imgBox > img:first-child {
    display: none;
  }
  #listWrapper .rsWrapper .newContents {
    margin: 16px 0 0;
  }
  #listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox div .imgBox > img:first-child {
    display: none;
  }
  /* 배너2 */
  #subBanner2 {
    background: none;
    padding: 0;
  }
  #subBanner2 .rsWrapper {
    padding: 0;
  }
  #subBanner2 .rsWrapper > div {
    background: none;
    padding: 0;
    border-radius: 0;
  }
  #subBanner2 .rsWrapper div img {
    border-radius: 0;
    height: 160px;
  }
  #subBanner2 .rsWrapper div .absoluteBox h1 {
    font-size: 24px;
  }
  #subBanner2 .rsWrapper div .absoluteBox h1 span {
    font-size: 30px;
  }
  #subBanner2 .rsWrapper div .absoluteBox p {
    font-size: 16px;
  }
  /* 빌더 */
  #builderWrapper .bgImg .imgBox {
    /* position: absolute;
    top: 0;
    left: 0;
    height: 130%; */
    width: 100%;
    height: 0;
    padding-bottom: calc(760 / 1360 * 200%);
    background-size: cover;
    background-image: url('/src/assets/images/common/bgImg.png');
    background-repeat: no-repeat;
    background-position: center;
  }
  #builderWrapper .bgImg {
    height: 375px;
  }
  #builderWrapper .bgImg .builderBox .box {
    width: 33.3333333333%;
  }
  #builderWrapper .bgImg .builderBox div .box p {
    font-size: 16px;
  }
  #builderWrapper .bgImg .builderBox div .box img {
    width: 34px;
  }
  #builderWrapper .bgImg .builderBox div .box.login p {
    font-size: 18px;
  }
  #builderWrapper .bgImg .builderBox div .box #loginBtn {
    height: 30px;
    font-size: 13px;
  }
  #builderWrapper > .titleBox h1 {
    font-size: 24px;
    padding: 8px 0 12px;
    line-height: 1.2;
    margin: 0;
  }
  #builderWrapper > .titleBox h1 span {
    font-size: 14px;
  }
  #builderWrapper > .titleBox h1 br {
    display: initial;
  }
  /* 소식 */
  .communitySection #infoWrapper .rsWrapper {
    padding: 0 0 16px;
    gap: 40px;
  }
  .communitySection #infoWrapper .rsWrapper .titleBox .title {
    gap: 8px;
  }
  .communitySection #infoWrapper .rsWrapper .titleBox .title p {
    font-size: 18px;
  }
  .communitySection #infoWrapper .rsWrapper .titleBox .title img {
    margin: 0;
    width: 20px;
  }
  #infoWrapper .rsWrapper {
    padding: 0 16px 16px;
    gap: 40px;
  }
  #infoWrapper > h1 {
    font-size: 20px;
    padding: 20px 0;
    /* height: 44px; */
    margin: 0;
  }
  #infoWrapper h1 span {
    font-size: 20px;
  }
  #infoWrapper h1 br {
    display: initial;
  }
  #infoWrapper .listBox .list .absoluteBox img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
  #infoWrapper .listBox .list .absoluteBox p {
    font-size: 18px;
  }
  #infoWrapper .aiListBox .aiList {
    margin: 0 0 16px;
  }
  #infoWrapper .aiListBox .aiList > img {
    width: 100px;
    height: 111px;
  }
  #infoWrapper .aiListBox .aiList .infoBox {
    width: calc(100% - 100px);
    height: 111px;
    padding: 8px 16px;
  }
  #infoWrapper .aiListBox .aiList .infoBox h1 {
    font-size: 18px;
  }
  #infoWrapper .aiListBox .aiList .infoBox p {
    font-size: 13px;
    max-height: 26px;
  }
  #infoWrapper .aiListBox .aiList .infoBox p:last-child {
    font-size: 11px;
  }

  #infoWrapper .listBox .searchBox form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  #infoWrapper .listBox .searchBox form span {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  #infoWrapper .listBox .searchBox form span div {
    width: auto;
  }
  #listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox div .imgBox {
    display: none;
  }
  #listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox div .greyBox > div {
    /* width: calc(100% - 32px - 8px); */
    width: auto;
  }
  #newWrapper .rsWrapper .bgImg {
    position: absolute;
    left: 0;
    top: 39%;
    height: 40%;
    width: auto;
  }
  #popularSlider .list > div .absoluteBox .title .greyBox,
  #listWrapper .rsWrapper .staff .staffList .title,
  #listWrapper .rsWrapper .newContents .staffList .title {
    padding: 4px 8px;
  }
}

/* 메인에 쓰이는 더보기버튼 */
.userPage #whiteBtn {
  width: 80px;
  height: 36px;
  font-size: 12px;
}
@media (max-width: 700px) {
  .userPage #whiteBtn {
    width: 36px;
    background: url(/src/assets/images/common/icon_plus.svg) no-repeat center center;
    background-size: 13px;
    font-size: 0;
  }
  .userPage #whiteBtn:hover {
    background: url(/src/assets/images/common/icon_plus.svg) no-repeat center center rgba(241, 245, 248, 0.5);
  }
}

/* 메인 프롬프트 정보 변화 */
@media (max-width: 700px) {
  .Section .greyBox {
    align-items: start;
  }
  /* 배치상태 */
  .Section .greyBox > div,
  #popularSlider .list > div .absoluteBox .title .greyBox > div {
    flex-direction: column;
    align-items: start;
  }
  /* 유저네임 */
  .Section .greyBox > div > div,
  #popularSlider .list > div .absoluteBox .title .greyBox > p {
    height: 24px;
    line-height: 24px;
  }
  /* 가격 */
  .Section .greyBox > div > p,
  #popularSlider .list > div .absoluteBox .title .greyBox > div > div {
    align-self: end;
  }
  /* 다른 css 영향받는 레이아웃 속성 제어 */
  #popularSlider .list > div .absoluteBox .title .greyBox div > p,
  #listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox div .greyBox div .imgBox {
    justify-content: start;
    width: 100% !important;
  }
}
