#findPw {
  width: 580px;
  padding: 20px 70px;
}

#findPw .top {
  margin: 0 0 20px;
  background: #ffffff;
  padding: 0;
}

#findPw .top div {
  width: 29px;
  height: 29px;
  border-radius: 100%;
  border: 1px solid #cccccc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#findPw .top div:hover {
  background: #cccccc;
}

#findPw .logo {
  width: 310px;
  padding: 15px 0 40px;
}

#findPw .logo img {
  width: 100%;
}

#findPw > h1 {
  font-size: 24px;
  color: #0e0e1f;
  font-weight: bold;
  margin: 0 0 60px;
}

#findPw .input {
  width: 100%;
  height: 55px;
  /* background: #eeeeee; */
  border-radius: 5px;
  box-shadow: none;
  padding: 16px 20px;
  font-size: 16px;
  margin-top: 6px;
}

#findPw .input::placeholder {
  color: #a3a1a7;
}

#findPw .input:hover {
  border: 1px solid #ec15ff;
}

#findPw .input:focus {
  background: #ffffff;
  border: 1px solid #ec15ff;
}

#findPw > form > p {
  font-size: 13px;
  color: #87858b;
  margin: 0 0 20px;
}

#findPw #purpleBtn {
  width: 100%;
  height: 58px;
  border-radius: 5px;
  font-size: 18px;
  margin: 0 0 26px;
}

@media (max-width: 700px) {
  #findPw {
    width: 100%;
    padding: 20px;
  }

  #findPw .input {
    font-size: 14px;
  }

  #findPw .input::placeholder {
    font-size: 14px;
  }
}
