#popularSlider {
  display: block;
  width: 100%;
}

#popularSlider .list {
  padding: 6px 4px;
}

#popularSlider .list:hover > div {
  background: linear-gradient(to right, #13d5df, #f138fc);
  cursor: pointer;
  transition: all 2 s ease-in-out;
  object-fit: cover;
}

#popularSlider .slick-current .list > div {
  background: linear-gradient(to right, #13d5df, #f138fc);
  box-shadow: 0 0 6px 4px rgba(241, 56, 252, 0.2);
}

#popularSlider .list > div {
  padding: 4px;
  border-radius: 16px;
  position: relative;
}

#popularSlider .list > div > img {
  width: 100%;
  height: 260px;
  border-radius: 16px;
}

#popularSlider .list > div .absoluteBox {
  position: absolute;
  top: 4px;
  height: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 16px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}

#popularSlider .list > div .absoluteBox .user {
  padding: 0 20px 0 8px;
}

#popularSlider .list > div .absoluteBox .user div {
  width: auto;
}

#popularSlider .list > div .absoluteBox .user div img {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
}

#popularSlider .list > div .absoluteBox .user div p {
  font-size: 13px;
  color: #ffffff;
}

#popularSlider .list > div .absoluteBox .user > img {
  width: 38px;
  height: auto;
  margin-top: 13px;
  display: inline-block;
}

#popularSlider .list > div .absoluteBox .title > p {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin: 0 0 4px;
  padding: 0 28px;
  line-height: 140%;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}

#popularSlider .list > div .absoluteBox .title .greyBox {
  background: #e2e2e2;
  padding: 8px 8px;
  border-radius: 0 0 16px 16px;
  gap: 4px;
}

#popularSlider .list > div .absoluteBox .title .greyBox > img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}

#popularSlider .list > div .absoluteBox .title .greyBox > div {
  width: calc(100% - 32px - 8px);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#popularSlider .list > div .absoluteBox .title .greyBox p {
  /* height: 24px; */
}

#popularSlider .list > div .absoluteBox .title .greyBox > div .flexRowBetween {
  width: auto;
  height: 24px;
}

#popularSlider .list > div .absoluteBox .title .greyBox div > p {
  font-size: 14px;
  font-weight: 600;
  color: #0e0e1f;
  line-height: 1.4;
  margin: 0;
}

#popularSlider .list > div .absoluteBox .title .greyBox div div .imgBox {
  width: auto;
  display: none;
}

#popularSlider .list > div .absoluteBox .title .greyBox div div .imgBox img:first-child {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
}

#popularSlider .list > div .absoluteBox .title .greyBox div div .imgBox img {
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
}

#popularSlider .list > div .absoluteBox .title .greyBox div div p {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
}

#popularSlider .arrowLeft,
#popularSlider .arrowRight {
  position: absolute;
  width: 36px;
  margin: 0 4px 0 0;
  height: 36px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  font-size: 12px;
  color: #000000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 10;
}

#popularSlider .arrowLeft:hover,
#popularSlider .arrowRight:hover {
  background: linear-gradient(to right, #b041f3, #ec15ff);
  color: #ffffff;
}

#popularSlider .arrowLeft {
  top: 50%;
  left: 0;
  margin: -18px 0 0;
}

#popularSlider .arrowRight {
  top: 50%;
  right: 0;
  margin: -18px 0 0;
}

#popularSlider .slick-dots {
  display: none !important;
}

@media (max-width: 700px) {
  #popularSlider .list > div > img {
    height: 136px;
  }
  #popularSlider .list {
    padding: 0px 8px;
  }
  #popularSlider .list > div .absoluteBox .title > p {
    display: none;
  }

  #popularSlider .list > div .absoluteBox .title .greyBox > img {
    width: 24px;
    height: 24px;
    margin: 0 4px 0 0;
  }

  /* #popularSlider .list > div .absoluteBox .title .greyBox > div {
    width: calc(100% - 24px - 4px);
    justify-content: end;
  } */
  #popularSlider .list > div .absoluteBox .title .greyBox > div > img {
    display: none;
  }
  #popularSlider .list > div .absoluteBox .title .greyBox div div .imgBox img:first-child {
    display: none;
  }

  #popularSlider .list > div .absoluteBox .user > img {
    display: none;
  }

  #popularSlider .list > div .absoluteBox .user {
    padding: 8px;
  }

/*   #popularSlider .list > div .absoluteBox .title .greyBox div > p {
    font-size: 12px;
  } */

  #popularSlider .arrowLeft,
  #popularSlider .arrowRight {
    display: none;
  }

  #popularSlider .slick-dots {
    display: initial !important;
    bottom: -30px;
  }

  #popularSlider .slick-dots li.slick-active button:before,
  #popularSlider .slick-dots li button:before {
    color: #000000;
  }

  #popularSlider .slick-dots li {
    margin: 0;
  }
}

/* new 250106 */

#populerSlider .slick-current .list {
  padding: 4px;
  background: linear-gradient(to right, #13d5df, #f138fc);
}

#populerSlider .list {
  padding: 0 4px;
  border-radius: 16px;
}

#populerSlider .list > div {
  border-radius: 16px 16px 0 0;
  position: relative;
}

#populerSlider .list > div > img {
  width: 100%;
  height: 220px;
  border-radius: 16px 16px 0 0;
}

#populerSlider .list > div .absoluteBox {
  position: absolute;
  top: 4px;
  height: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 16px 16px 0 0;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}

#populerSlider .list > div .absoluteBox .user {
  padding: 0 20px 0 8px;
}

#populerSlider .list > div .absoluteBox .user div {
  width: auto;
}

#populerSlider .list > div .absoluteBox .user div img {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
}

#populerSlider .list > div .absoluteBox .user div p {
  font-size: 13px;
  color: #ffffff;
}

#populerSlider .list > div .absoluteBox .user > img {
  width: 38px;
  height: auto;
}

#populerSlider .list > div .absoluteBox .title > p {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin: 0 0 4px;
  padding: 0 28px;
}

#populerSlider .list .greyBox {
  background: #e2e2e2;
  padding: 8px 12px;
  border-radius: 0 0 16px 16px;
}

#populerSlider .list .greyBox > img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}

#populerSlider .list .greyBox > div {
  width: calc(100% - 32px - 8px);
}

#populerSlider .list .greyBox div > p {
  font-size: 14px;
  font-weight: 600;
  color: #0e0e1f;
}

#populerSlider .list .greyBox div div .imgBox {
  width: auto;
}

#populerSlider .list .greyBox div div .imgBox img:first-child {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
}

#populerSlider .list .greyBox div div .imgBox img {
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
}

#populerSlider .list .greyBox div div p {
  font-size: 13px;
  font-weight: 600;
}

#populerSlider .arrowLeft,
#populerSlider .arrowRight {
  position: absolute;
  width: 36px;
  margin: 0 4px 0 0;
  height: 36px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  font-size: 12px;
  color: #000000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 1000;
}

#populerSlider .arrowLeft:hover,
#populerSlider .arrowRight:hover {
  background: linear-gradient(to right, #b041f3, #ec15ff);
  color: #ffffff;
}

#populerSlider .arrowLeft {
  top: 50%;
  left: 0;
  margin: -18px 0 0;
}

#populerSlider .arrowRight {
  top: 50%;
  right: 0;
  margin: -18px 0 0;
}

#populerSlider .slick-dots {
  display: none !important;
}

@media (max-width: 700px) {
  #populerSlider .list > div > img {
    height: 136px;
  }

  #populerSlider .list > div .absoluteBox .title > p {
    display: none;
  }

  #populerSlider .list .greyBox {
    padding: 4px 8px;
  }

  #populerSlider .list .greyBox > img {
    width: 24px;
    height: 24px;
    margin: 0 4px 0 0;
  }

  #populerSlider .list .greyBox > div {
    width: calc(100% - 24px - 4px);
  }

  #populerSlider .list .greyBox div div .imgBox img:first-child {
    display: none;
  }

  #populerSlider .list > div .absoluteBox .user > img {
    display: none;
  }

  #populerSlider .list > div .absoluteBox .user {
    padding: 8px;
  }

  #populerSlider .list .greyBox div > p {
    font-size: 12px;
  }

  #populerSlider .arrowLeft,
  #populerSlider .arrowRight {
    display: none;
  }

  #populerSlider .slick-dots {
    display: initial !important;
    bottom: -30px;
  }

  #populerSlider .slick-dots li.slick-active button:before,
  #populerSlider .slick-dots li button:before {
    color: #000000;
  }

  #populerSlider .slick-dots li {
    margin: 0;
  }
}
