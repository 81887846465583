.param_title {
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
}

.promptSaveGuide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.promptSaveGuide h1 {
  background: linear-gradient(90deg, #ca79fc 0%, #ff5eb6 100%);
  font-weight: 700;
  color: transparent;
  text-align: center;
  -webkit-background-clip: text;
  margin: 0 0 10px;
  line-height: 1.2;
}

.termsTable {
  width: 70%;
}

.termsTable > thead {
  border: 1px solid;
}

.termsTable > thead > tr {
  border: 1px solid;
  font-weight: bold;
}

.termsTable > thead > tr > td {
  border: 1px solid;
  text-align: center;
  height: 30px;
}

.termsTable > tbody {
  border: 1px solid;
}

.termsTable > tbody > tr {
  border: 1px solid;
}

.termsTable > tbody > tr > td {
  border: 1px solid;
  height: 30px;
  padding: 0 10px;
}

.divCenter {
  display: grid;
  place-items: center;
}

#detail .promptThumList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1600px;
  padding: 24px 0 64px;
  gap: 28px;
}

.whiteBackground {
  background: #ffffff !important;
}

.grayBackground {
  background: rgba(0, 0, 0, 0.04) !important;
}

.selectedButton {
  background: #525162 !important;
  color: #fff !important;
  border: 1px solid transparent !important;
}