#myProfile .profileBox .iconExpand {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 62.5%;
    width: 20px !important;
    height: 20px !important;
    transform: translateY(-50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile .profileBox .iconChange {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 42.5%;
    width: 20px !important;
    height: 20px !important;
    transform: translateY(-50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile .profileBox .iconTrash {
    cursor: pointer;
    position: absolute;
    top: 48.5%;
    right: 22.5%;
    width: 20px !important;
    height: 20px !important;
    transform: translateY(-50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile #profileImage {
    border-radius: 8px;
    transition: filter 0.3s ease-in-out;
}

#myProfile .profilePreview {
    width: 140px;
    height: 140px;
    object-fit: cover;
}

#myProfile .plusBannerBox .iconExpand {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 43%;
    width: 20px !important;
    height: 20px !important;
    transform: translate(-50%, -43%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile .plusBannerBox .iconChange {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px !important;
    height: 20px !important;
    transform: translate(-50%, -50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile .plusBannerBox .iconTrash {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 57%;
    width: 20px !important;
    height: 20px !important;
    transform: translate(-50%, -57%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}
