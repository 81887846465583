section:nth-child(1) > div > div > div.ant-comment.css-dev-only-do-not-override-14i19y2 > div {
  margin-left: 0px !important;
  margin-bottom: -40px !important;
}
/*
section > div > div > div.ant-list.ant-list-split.box.css-dev-only-do-not-override-14i19y2 > div > div > ul > div > div > div.ant-comment-nested > div > div > div *,
section > div > div > div.ant-list.ant-list-split.box.css-dev-only-do-not-override-14i19y2 > div > div > ul > div > div > div.ant-comment-nested div {
    *background-color: transparent !important;
}
*/

div.ant-list.ant-list-split.box.css-dev-only-do-not-override-14i19y2 > div > div > ul > div:nth-child(n) > div > div.ant-comment-inner {
  margin-left: 0px !important;
}

.ant-avatar {
  margin-left: 20px;
}

.comment-name {
  width: auto;
  height: 19px;
  flex-grow: 0;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: left;
  margin-right: 10px;
  color: #0e0e1f;
}
.comment-date {
  flex-grow: 1;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6d6c75;
  margin-left: 10px;
}
.ant-comment-inner {
  /* margin-left: 20px; */
}
.reply {
  width: auto;
  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  border-radius: 24px;
  border: solid 1px #3e3d53;
  cursor: pointer;
}
.comment-input {
  height: 48px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px #e2dfe4;
  background-color: #f7f7f8;
}

.more-select {
  position: absolute;
  top: 55%;
  right: 32px;
  min-width: 80px;
  height: 73px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #c718e2;
  background-color: #fff;
  z-index: 10;
}
.more-select > ul {
  width: 100%;
}
.more-select > ul > li {
  cursor: pointer;
  height: 32px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
}
.more-select > ul > li:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
}
.more-select > ul > li > span {
  width: 23px;
  height: 16px;
  flex-grow: 0;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

#root
  > section
  > section:nth-child(n)
  > div
  > div
  > div.ant-list.ant-list-split.box.css-dev-only-do-not-override-14i19y2
  > div
  > div
  > ul
  > div:nth-child(n)
  > div
  > div.ant-comment-nested
  > div
  > div:nth-child(n)
  > div
  > div.ant-comment-inner {
  margin-top: 5px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  gap: 12px;
  border-bottom-left-radius: 75px !important;
  background-color: rgba(255, 255, 255, 0.7);
}

#root > section > section:nth-child(n) > div > div > div.ant-list.ant-list-split.box.css-dev-only-do-not-override-14i19y2 > div > div > ul > div:nth-child(n) > div > div.ant-comment-nested > div {
  margin-top: 5px;
  margin-left: -25px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  gap: 12px;
  border-bottom-left-radius: 75px !important;
  background-color: white;
}

.comment-input {
  width: 90%;
  height: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px #e2dfe4;
  background-color: #f7f7f8;
}
.comment-btn {
  width: 73px;
  height: 48px;
  margin-left: 12px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 12px;
  padding: 0;
  background-color: #a3a1a7;
  color: #fff;
}
