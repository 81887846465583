#mainSlider {
  width: 100%;
  height: auto;
}

#mainSlider .bannerBox {
  width: 100%;
  position: relative;
  /*cursor: pointer;*/
}
#mainSlider .bannerBoxWithCursor {
  width: 100%;
  height: 400px;
  position: relative;
  cursor: pointer;
}
#mainSlider .bannerBox > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#mainSlider .bannerBox div h1 {
  font-size: 48px;
  color: #ffffff;
  margin: 0 0 15px;
  font-weight: 700;
}

#mainSlider .bannerBox div p {
  font-size: 20px;
  color: #ffffff;
  margin: 0 0 50px;
}

#mainSlider .bannerBox div #whiteBorderBtn {
  width: 180px;
  height: 40px;
}

#mainSlider .bannerBox div #whiteBorderBtn p {
  font-size: 14px;
  margin: 0 10px 0 0;
}

#mainSlider .bannerBox div #whiteBorderBtn img {
  width: 24px;
  height: auto;
}

#mainSlider .bannerBox img {
  width: 100%;
}

.slick-dots {
  bottom: 20px;
}

.slick-dots li.slick-active button:before {
  color: #ffffff;
  opacity: 1;
}

.slick-dots li button:before {
  color: #ffffff;
}

@media (max-width: 700px) {
  #mainSlider .bannerBox div h1 {
    font-size: 24px;
    margin: 0 0 10px;
  }

  #mainSlider .bannerBox div p {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    line-height: 1.6;
    margin: 0 0 10px;
  }

  #mainSlider .bannerBox div #whiteBorderBtn {
    width: 130px;
    height: 32px;
  }

  #mainSlider .bannerBox div #whiteBorderBtn p {
    font-size: 14px;
    margin: 0 10px 0 0;
  }

  #mainSlider .bannerBox div #whiteBorderBtn img {
    width: 15px;
  }
}
